import React, { useEffect, useState} from 'react'
import { useHistory} from 'react-router-dom'
import api from '../../services/api'

import BuscaToken from '../Components/Functions/BuscaToken.js'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'


const EditCourier = () => {
  const [Courier, setCourier] = useState()

  const config = BuscaToken()

  const history = useHistory()

  useEffect(() => {
    const params = window.location.href.split('Courier/Edit/')
    api.get(`/courier/${params[1]}`, BuscaToken())
    .then( r => {
      if(r.data.message){
        alert(JSON.stringify(r.data.message))
        return history.push('/')
      }
      setCourier(r.data)
    })
  }, [])

  useEffect(() => {
    if(Courier){
    document.getElementById('nomeCourier').value = Courier.name ? Courier.name : ''
    document.getElementById('nascimentoCourier').value = Courier.nascimento ? Courier.nascimento : ''
    document.getElementById('telefoneCourier').value = Courier.telefone ? Courier.telefone : ''
    document.getElementById('cpfCourier').value = Courier.documento ? Courier.documento : ''
    document.getElementById('rgCourier').value = Courier.rg ? Courier.rg : ''
    document.getElementById('placaVeiculoCourier').value = Courier.placa ? Courier.placa : ''
    document.getElementById('renavamVeiculoCourier').value = Courier.renavam ? Courier.renavam : ''
    document.getElementById('marcaVeiculoCourier').value = Courier.marca ? Courier.marca : ''
    document.getElementById('modeloVeiculoCourier').value = Courier.modelo ? Courier.modelo : ''
    document.getElementById('corVeiculoCourier').value = Courier.cor ? Courier.cor : ''
    document.getElementById('cepAdressCourier').value = Courier.endereco ? Courier.endereco.cep : '' 
    document.getElementById('ruaAdressCourier').value = Courier.endereco ? Courier.endereco.rua : ''
    document.getElementById('numeroAdressCourier').value = Courier.endereco ? Courier.endereco.numero : ''
    document.getElementById('bairroAdressCourier').value = Courier.endereco ? Courier.endereco.bairro : ''
    document.getElementById('cidadeAdressCourier').value = Courier.endereco ? Courier.endereco.cidade : ''
    }
  }, [Courier])

  function VerificaCpf (event) {
    if(event.target.value.length !== 11)
      return false
    const validacao = validarCPF(event.target.value)
    
    if(!validacao){
      alert('Cpf invalido!')
      return document.querySelector('#cpfCourier').value = ''
    }

    return true
  }


    function validarCPF(cpf) {	
      cpf = cpf.replace(/[^\d]+/g,'');	
      if(cpf === '') return false;	
      // Elimina CPFs invalidos conhecidos	
      if (cpf.length !== 11 || 
        cpf === "00000000000" || 
        cpf === "11111111111" || 
        cpf === "22222222222" || 
        cpf === "33333333333" || 
        cpf === "44444444444" || 
        cpf === "55555555555" || 
        cpf === "66666666666" || 
        cpf === "77777777777" || 
        cpf === "88888888888" || 
        cpf === "99999999999")
          return false;		
      // Valida 1o digito	
      let add = 0;	
      for (let i=0; i < 9; i ++)		
        add += parseInt(cpf.charAt(i)) * (10 - i);	
        let rev = 11 - (add % 11);	
        if (rev === 10 || rev === 11)		
          rev = 0;	
        if (rev !== parseInt(cpf.charAt(9)))		
          return false;		
      // Valida 2o digito	
      add = 0;	
      for (let i = 0; i < 10; i ++)		
        add += parseInt(cpf.charAt(i)) * (11 - i);	
      rev = 11 - (add % 11);	
      if (rev === 10 || rev === 11)	
        rev = 0;	
      if (rev !== parseInt(cpf.charAt(10)))
        return false;		
      return true;   
    }


  async function submitCourier () {
    const name = document.getElementById('nomeCourier').value
    const nascimento = document.getElementById('nascimentoCourier').value
    const telefone = document.getElementById('telefoneCourier').value
    const documento = document.getElementById('cpfCourier').value
    const rg = document.getElementById('rgCourier').value
    const placa = document.getElementById('placaVeiculoCourier').value
    const renavam = document.getElementById('renavamVeiculoCourier').value
    const marca = document.getElementById('marcaVeiculoCourier').value
    const modelo = document.getElementById('modeloVeiculoCourier').value
    const cor = document.getElementById('corVeiculoCourier').value
    const cep = document.getElementById('cepAdressCourier').value
    const rua = document.getElementById('ruaAdressCourier').value
    const numero = document.getElementById('numeroAdressCourier').value
    const bairro = document.getElementById('bairroAdressCourier').value
    const cidade = document.getElementById('cidadeAdressCourier').value
    const meta = document.getElementById('metaCourier').value

    if(documento.length !== 11)
      return alert('Cpf invalido')

    if(([name, nascimento, telefone, documento, rg, placa, renavam, marca, modelo, cor, cep, rua, numero, bairro, cidade, meta]
      .filter( item => item.length === 0)).length > 0)
      return alert('Campos Invalidos!')
    
    


    const data = {
      id: Courier.id,
      name,
      nascimento,
      placa,
      renavam,
      marca,
      modelo,
      cor,
      telefone,
      documento,
      rg,
      endereco: {
        cep,
        rua,
        numero,
        bairro,
        cidade
      },
      meta
    }

    const responseNewCourier = await api.post('/courier/update', data, config)
    alert(JSON.stringify(responseNewCourier.data.message))
    return history.push('/Courier')
  }


  async function BuscaCep (event) {
    fetch(`https://viacep.com.br/ws/${event.target.value}/json/`).then( response => response.json())
    .then( response => {
      if(!response.erro){
        document.querySelector('#ruaAdressCourier').value = response.logradouro
        document.querySelector('#bairroAdressCourier').value = response.bairro
        document.querySelector('#cidadeAdressCourier').value = response.localidade
      }
      else{
        document.querySelector('#ruaAdressCourier').value = ''
        document.querySelector('#bairroAdressCourier').value = ''
        document.querySelector('#cidadeAdressCourier').value = ''
      }
    })
  }

  return (
    <>
      <Menu />
      <div className="container contentCenter container-CreateCourier grid">
        <h2>Create Courier</h2>
        <form action="submit" className="grid">
          <h3>Dados Fiscais</h3>
          <section>
            <label htmlFor="nomeCourier">Nome do Entregador:</label>
            <input type="text" name="nomeCourier" id="nomeCourier"/>
          </section>
          <section>
            <label htmlFor="cpfCourier">CPF:</label>
            <input type="text" maxlength="11" name="cpfCourier" id="cpfCourier" onChange={(event) => VerificaCpf(event) }/>
          </section>
          <section>
            <label htmlFor="rgCourier">RG:</label>
            <input type="text" maxlength="20" name="rgCourier" id="rgCourier"/>
          </section>
          <section>
            <label htmlFor="nascimentoCourier">Data de Nascimento:</label>
            <input type="text" maxlength="8" name="nascimentoCourier" id="nascimentoCourier" placeholder="DDMMAAAA"/>
          </section>
          <section>
            <label htmlFor="telefoneCourier">Telefone de Contato:</label>
            <input type="text" maxlength="11"  name="telefoneCourier" id="telefoneCourier"/>
          </section>
          <h3>Dados do Veiculo</h3>
          <section>
            <label htmlFor="placaVeiculoCourier">Placa:</label>
            <input type="text" name="placaVeiculoCourier" id="placaVeiculoCourier"/>
          </section>
          <section>
            <label htmlFor="renavamVeiculoCourier">Renavam:</label>
            <input type="text" name="renavamVeiculoCourier" id="renavamVeiculoCourier"/>
          </section>    
          <section>
            <label htmlFor="marcaVeiculoCourier">Marca:</label>
            <input type="text" name="marcaVeiculoCourier" id="marcaVeiculoCourier"/>
          </section>  
          <section>
            <label htmlFor="modeloVeiculoCourier">Modelo:</label>
            <input type="text" name="modeloVeiculoCourier" id="modeloVeiculoCourier"/>
          </section>  
          <section>
            <label htmlFor="corVeiculoCourier">Cor:</label>
            <input type="text" name="corVeiculoCourier" id="corVeiculoCourier"/>
          </section>
          <h3>Endereco</h3>
          <section>
            <label htmlFor="cepAdressCourier">Cep:</label>
            <input type="text" name="cepAdressCourier" id="cepAdressCourier" onChange={(event) => event.target.value.length === 8 && BuscaCep(event)}/>
          </section>
          <section>
            <label htmlFor="ruaAdressCourier">Rua:</label>
            <input type="text" name="ruaAdressCourier" id="ruaAdressCourier"/>
          </section>
          <section>
            <label htmlFor="numeroAdressCourier">Numero:</label>
            <input type="text" name="numeroAdressCourier" id="numeroAdressCourier"/>
          </section>
          <section>
            <label htmlFor="bairroAdressCourier">Bairro:</label>
            <input type="text" name="bairroAdressCourier" id="bairroAdressCourier"/>
          </section>
          <section>
            <label htmlFor="cidadeAdressCourier">Cidade:</label>
            <input type="text" name="cidadeAdressCourier" id="cidadeAdressCourier"/>
          </section>
          <h3>Configuracao Operacional</h3>
          <section>
            <label htmlFor="metaCourier">Meta de entregas?</label>  
            <select name="metaCourier" id="metaCourier" className="BorderBox">
              <option value="">Selecionar</option>
              <option value="true">Sim</option>
              <option value="false">Nao</option>
            </select>
          </section>
        </form>  
        <button className="btn-M" onClick={submitCourier}>Atualizar Courier</button>
      </div>
        <Footer />
    </>
  )
}

export default EditCourier