import React, { useState, useEffect } from 'react'

import api from '../../services/api'


import BuscaToken from '../Components/Functions/BuscaToken.js'

const ProductionForCourierAll = ({ Data }) => {
  const [Relatorio, setRelatorio] = useState()
  const [RelacaoCouriers, setRelacaoCouriers] = useState()
  const [Permissao, setPermissao] = useState()
  const [Periodo, setPeriodo] = useState()


  const config = BuscaToken()

  useEffect(()=> {
    api.post('/production/courier', Data, config).then((r) => {
      setRelatorio(r.data)
    })
    api.get('/users/CheckPermission', config).then( r => setPermissao(r.data.Permissao))
  }, [])

  useEffect(() => {
    if(Relatorio){ 
      setPeriodo(Relatorio.Periodo)
      const CouriersOrdenados = Relatorio.relatorio.sort(function compare( a , b ) {
        const aTotal = a.Ps + a.Gs
        const bTotal = b.Ps + b.Gs
        if (aTotal > bTotal) return -1
        if (aTotal < bTotal) return 1
        if(aTotal === bTotal){
          if(a.Gs > b.Gs) return -1
          if(a.Gs < b.Gs) return 1
          return 0
        }
        return 0
      })
      setRelacaoCouriers(CouriersOrdenados)
    }
  }, [Relatorio])



    return (
      <>
        <h2 className="h2-ProductionAll">{Periodo && Periodo}</h2>
        <table className="tableProduction" border=".5px">
          <thead>
            <tr>
              <th>Courier</th>
              <th>Total P</th>
              <th>Total G</th>
              <th>Total Geral</th>
              <th>Atingiu meta?</th>
              {Permissao === 'A' && <th>Saldo Bruto</th>}
              {Permissao === 'A' && <th>Contas</th>}
              {Permissao === 'A' && <th>Saldo Liquido</th>}
            </tr>
          </thead>
          <tbody>
          {RelacaoCouriers && (
            RelacaoCouriers.map((courier) => {
              if(courier.Ps + courier.Gs > 0)
              return (
                <tr key={courier.Courier}>
                  <td>{courier.Courier}</td>
                  <td>{courier.Ps}</td>
                  <td>{courier.Gs}</td>
                  <td>{courier.Gs + courier.Ps}</td>
                  <td>{courier.meta === 'true' ? ((courier.Gs + courier.Ps) >= 500 ?
                   'Meta Alcançada' : `Falta ${ 500 - (courier.Gs + courier.Ps)} entregas.`) : "Courier não possui meta"}</td>
                   {Permissao === 'A' && <td><p className="tdReais">{courier.Saldo.replace('.',',')}</p></td>}
                   {Permissao === 'A' && <td><p className="tdReais">{courier.Contas.replace('.',',')}</p></td>}
                   {Permissao === 'A' && <td><p className="tdReais">{(courier.Saldo - courier.Contas).toFixed(2).replace('.',',')}</p></td>}
                </tr>
                
              
              )  
            })
          )}
          </tbody>
        </table>
      </>
    )
}

export default ProductionForCourierAll