import React, { useState } from 'react'

import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'

import duplicity from '../Medias/duplicity.wav'

import NotFound from './NotFound.mp3'
import Sucess from './Sucess.wav'



const FindPackage = () => {
  const [RemessasToFind, setRemessasToFind] = useState()
  const [RemessasFounds, setRemessasFounds] = useState()
  const [RemessasNotFounds, setRemessasNotFounds] = useState()
  const [TxtValues, setTxtValues] = useState()
  const [QtdBipada, setQtdBipada] = useState(0)
  const [BG, setBG] = useState('cinza')
  const [SoundNotFound, setSoundNotFound] = useState()
  const [SoundSucess, setSoundSucess] = useState()
  const [SoundDuplicity, setSoundDuplicity] = useState()

  if(!SoundNotFound){
    setSoundNotFound(new Audio(NotFound))
  }
  if(!SoundSucess){
    setSoundSucess(new Audio(Sucess))
  } 
  if(!SoundDuplicity){
    setSoundDuplicity(new Audio(duplicity))
  }

  function IniciarFind (event) {  
    event.preventDefault()  
    let remessas = TxtValues.split('\n').filter(item => item.length > 1).map(item => item.trim())
        remessas = remessas.filter((item, index) => remessas.indexOf(item) === index)
        setRemessasToFind(remessas)
        //setRemessasFounds(remessas)
        setRemessasNotFounds(remessas)

  }

  function TxtAreaToFind (event) {
    if(event.key === "Enter"){
      const remessas = event.target.value.split('\n').filter(item => item.length > 1).map(item => item.trim().toUpperCase())
      remessas.map(async (item, index) => {
        if(remessas.indexOf(item) !== index){
          await SoundDuplicity.play()
        }
      })
      let newValues = remessas.filter((item, index) => remessas.indexOf(item) === index)
          setQtdBipada(newValues.length)
          newValues = newValues.toString().replace(/,/g, '\n')
      setTxtValues(newValues)
    }
  }


  async function FindRemessa (event) {
    if(event.key === 'Enter'){

      
      if(RemessasToFind.indexOf(event.target.value.trim().toUpperCase()) !== -1){
        setBG('BGgreen')
        let remessasFounds = RemessasFounds ? RemessasFounds : []
            remessasFounds.push(event.target.value.trim().toUpperCase())
        let remessasNotFounds = RemessasNotFounds ? RemessasNotFounds : []
            remessasNotFounds = remessasNotFounds.filter(item => item !== event.target.value.trim().toUpperCase())
            setRemessasFounds((remessasFounds.filter((item, index) => remessasFounds.indexOf(item) === index)))
            setRemessasNotFounds(remessasNotFounds)
        await SoundSucess.play()      
    }
    else {
      await SoundNotFound.play()
      setBG('BGred')
    }
      document.querySelector('#InputFindPackage').value = ''
    }
    
  }

  return(
    <>
      <Menu />
      <div className="grid container">
            <h2>Find Package</h2>
            <div className={`DivFindPackage grid ${BG}`}>
              {!RemessasToFind && 
                <div className="grid addRemessasToFind">
                  <p>Entre com as remessas a ser pesquisadas</p>
                  <span>Total: {QtdBipada}</span>
                  <textarea  id="grid TxtToFind" value={TxtValues} onChange={(event) => setTxtValues(event.target.value)} onKeyPress={(event) => TxtAreaToFind(event)}></textarea>
                  <button className="btn-addRms" onClick={(event) => IniciarFind(event)}> Iniciar </button>
                </div>
              }
              {RemessasFounds && 
                <div className="grid PackagesFounds">
                  <p>{RemessasFounds.length} Remessas encontradas</p>
                  <ul className="grid">
                    {RemessasFounds.map( remessa => 
                      <li key={remessa}>{remessa}</li>
                      )}
                  </ul>
                </div>}
              
              {RemessasToFind &&
                <div className="grid InputFindPackage">
                  <p>Bipe a remessa para busca</p>
                  <input type="text" id="InputFindPackage" onKeyPress={(event) => FindRemessa(event)}/>
                </div>
              }

              {RemessasNotFounds && 
                <div className="grid PackagesNotFounds">
                  <p>{RemessasNotFounds.length} Remessas nao encontradas</p>
                  <ul className="grid">
                    {RemessasNotFounds.map( remessa => 
                      <li key={remessa}>{remessa}</li>
                      )}
                  </ul>
                </div>}
              </div>
      </div>
      <Footer />
    </>
  )
}


export default FindPackage


