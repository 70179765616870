import React from 'react';


import logo from '../Components/Menu/img/topLogo.png'

 

const GerandoPdf = ({Data}) => {
  const remessasspaces = Data.remessas.map(remessa => ` ${remessa} `)
  const remessas = remessasspaces.toString()

  return (
    <div className="Comprovante grid"> 
      <section className="headerComprovante grid">
        <img className="LogoComprovante BorderBox" src={logo} alt="Expressas Dourados"/>
        <h2 className="grid">Relatório de Avarias</h2>
      </section>      
      <section className="bodyComprovante grid">
        <h2 className="colorBlack">Cliente: {Data.cliente}</h2>
        <h2 className="colorBlack">Quantidade: {Data.remessas.length}</h2>
        <section className="remessasComprovante grid">{remessas}</section>
        <p>Data: {Data.data.dia}/{Data.data.mes}/{Data.data.ano} <br/> Horas: {Data.data.hora} : {Data.data.minutos}</p>
        <br />
        <p>Assinatura: _____________________________</p>
        <br/>
        <p>RG: ____________</p>
        <p>Atesto avarias nas remessas relacionadas neste documento.</p>
        <br/>
      </section>
      <section className="controleComprovante grid">
        <p>N° de Controle: {Data.relatorio}</p>
        <p>Autorizado por: {Data.autor}</p>
      </section>
    </div>
  )
}

export default GerandoPdf