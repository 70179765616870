function RmtoArray (item) {
  const Item = item.toString()
  const Array_r = Item.split('')
  if(!Array_r.includes(',') && Array_r.length > 0){
    return [Item]
  }
  else if(!Array_r.includes(',') && Array_r.length === 0)
    return []
  const r = Item.split(',')
  const R = r.filter(rm => rm.length > 0)
  return R
}

export default RmtoArray