import React, { useState, useEffect } from 'react'
import api from '../../services/api'

import { useHistory } from 'react-router-dom'

import GerandoPdf from './GerandoPdf'

import BuscaToken from '../Components/Functions/BuscaToken.js'

const Via2 = () => {
  const [Romaneio, setRomaneio] = useState()
  const [MostraPdf, setMostraPdf] = useState()
  const [Pdf, setPdf] = useState()

  const history = useHistory()
  const config = BuscaToken()

  useEffect(() => {
    const params = window.location.href.split('Via2/')
    api.get(`/devolucao/Via2/${params[1]}`, config).then( r => {

      if(r.data.romaneio){
      setRomaneio({
        data: JSON.parse(r.data.created_at_json),
        remessas: JSON.parse(r.data.remessas),
        client: r.data.client,
        autor: r.data.autor,
        type: r.data.type,
        idDevolucao : r.data.romaneio
      })
      setPdf(1)
      setMostraPdf(1)
      return 
      }
      else {
        alert('Romaneio de devolucao nao existente!')
        history.push('/')
      }
    })
  }, [])

  useEffect(() => {
    if(MostraPdf === 1){
      window.print()
      history.push('/')
    } 
  }, [MostraPdf])

  return (
    <div>
    <section id="pdf" className={MostraPdf === 1 ? 'ativo' : 'inativo'}>
        {Pdf && <GerandoPdf Data={Romaneio}  />}
    </section>   
    </div>
  )
}


export default Via2