import React from 'react'
import api from '../../services/api'

import BuscaToken from '../Components/Functions/BuscaToken.js'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'


const CreateUser = () => {
  const config = BuscaToken()


  async function submitUser () {
    const name = document.getElementById('nomeUser').value
    const login = document.getElementById('loginUser').value
    const permissao = document.getElementById('permissaoUser').value
    const documento = document.getElementById('documentoUser').value
    if(name.length < 4 || permissao.length < 1 || login.length < 4 || documento.length < 4)
      return alert('Campos Invalidos!')
    
      const data = {
        name,
        login,
        permissao,
        documento
      }
      
      const responseNewUser = await api.post('/users/create', data, config)
      alert(JSON.stringify(responseNewUser.data.message))
      return window.location.reload()
      
  }



  return (
    <>
      <Menu />
      <div className="container contentCenter grid">
        <h2>Create User</h2>
        <form action="submit">
          <label htmlFor="nomeUser">Nome do User:</label>
          <input type="text" name="nomeUser" id="nomeUser"/>
          <label htmlFor="loginUser">Login:</label>
          <input type="text" name="loginUser" id="loginUser"/>
          <label htmlFor="documentoUser">Documento:</label>
          <input type="text" name="documentoUser" id="documentoUser"/>
          <select name="permissaoUser" id="permissaoUser" style={{marginTop: "15px"}}>
            <option value="">Permissao</option>
            <option value="M">Manager</option>
            <option value="S">Support</option>
          </select>
        </form>
        <button className="btncreateCourier" onClick={submitUser}>Criar User</button>

      </div>
        <Footer />
    </>
  )
}

export default CreateUser