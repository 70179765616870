import React, { useEffect, useState} from 'react'
import { useHistory} from 'react-router-dom'

import api from '../../services/api'

import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import BuscaToken from '../Components/Functions/BuscaToken.js'
import Loading from '../Components/Loading'

const ContasEmissao = () => {
  const [Users, setUsers] = useState([])
  const [Autor, setAutor] = useState()
  const [User, setUser] = useState()
  const [Ano, setAno] = useState([])
  const [SelectQ, setSelectQ] = useState()
  const [SelectedYear, setSelectedYear] = useState()
  const [SelectedMonth, setSelectedMonth] = useState()
  const [SelectedDia, setSelectedDia] = useState()
  const [DataServer, setDataServer] = useState()
  const [TipoConta, setTipoConta] = useState()
  const [ValorConta, setValorConta] = useState()
  const [DescricaoConta, setDescricaoConta] = useState()
  const [LoadingTime, setLoadingTime] = useState()

  const config = BuscaToken()

  const history = useHistory()

  const Months = [{mes: 'Janeiro', value: "01"},{mes: 'Fevereiro', value: "02"},{mes: 'Marco', value: "03"},{mes: 'Abril', value: "04"},{mes: 'Maio', value: "05"},{mes: 'Junho', value: "06"},{mes: 'Julho', value: "07"},{mes: 'Agosto', value: "08"},{mes: 'Setembro', value: "09"},{mes: 'Outubro', value: "10"},{mes: 'Novembro', value: "11"},{mes: 'Dezembro', value: "12"}]

  const Quinzenas = [{Quinzena: '1°', value: '01'},{Quinzena: '2°', value: '16'}]
  
  useEffect(() => {
    api.get('/users/index', config).then(r => {
      setUsers(r.data)
    })

    api.get('/users/show', config)
    .then( r => {
      if(!r.data.message){
        setAutor(r.data)
      }
    })

      api.get('/HoraServer/timestamp', config).then( r => {
        const data = r.data.split('')
        const DataAtual = {
        ano: `${data[0]}${data[1]}${data[2]}${data[3]}`,
        mes: `${data[4]}${data[5]}`,
        dia: `${data[6]}${data[7]}`
      }
        setDataServer(DataAtual)
        const anoAtual = Number(DataAtual.ano)
        setAno([anoAtual, anoAtual + 1])
      })
  }, [])


  function setandoUser (event){
    if(event.target.value.length > 0)
      setUser(event.target.value)
    return false  
  }


  const Qatual = () => {
    setSelectQ()
    setSelectedYear(Number(DataServer.ano))
    setSelectedMonth(DataServer.mes)
    setSelectedDia(DataServer.dia)
  }

  const LiberaSelect = () => {
    setSelectQ(1)
  }

  const SelectYear = (event) => {
    setSelectedYear(event.target.value)
  }
  const SelectMonth = (event) => {
    setSelectedMonth(event.target.value)
  }
  const SelectQuinzena = (event) => {
    setSelectedDia(Quinzenas[event.target.value].value)
    
  }

  function tipoConta (event) {
    if(event.target.value.length > 0)
      setTipoConta(event.target.value)
    else
      setTipoConta()  
  }

  function valorConta (event) {
    if(event.target.value.length > 0){  
      const newValue = ((event.target.value.replace(/\D/g,"")).replace(/(\d{1})(\d{1,2})$/,"$1.$2"))
      return setValorConta(newValue)
    }
    setValorConta(event.target.value)
  }

  function descricaoConta (event) {
    if(event.target.value.length > 0){
      setDescricaoConta(event.target.value)
    }  
    else
      setDescricaoConta()  
  }

  async function Finish() {
    if(!LoadingTime){
      if(TipoConta.length > 0 && ValorConta.length > 0 && SelectedDia.length > 0 ){
        setLoadingTime(1)
        const data = {
          tipo: TipoConta,
          valor: ValorConta,
          descricao: DescricaoConta,
          autor: Autor.name,
          user: User,
          data: {
            year: SelectedYear,
            month: SelectedMonth,
            dia: SelectedDia
          }
        }
        const finished = await api.post('/contas/emissao', data, config)
        setLoadingTime()
        alert(finished.data.message)
        window.location.reload()
      }
      else
        alert('Campos Invalidos.')
      }
  }

  async function CheckAuthorization() {
    setLoadingTime(1)
    const data = { 
      user: User,
      valorConta: ValorConta
    }

    const authorization = await api.post('/contas/checkSaldoToConta', data, config)
    setLoadingTime()
    alert(JSON.stringify(authorization.data.message))
  }


  return (
    <>
      <Menu />
      <div className="container container-EmissaoConta grid">
          <h2>Contas</h2>
          <span className="span-M">Emissao - Conta</span>
            <select name="user" id="user" onChange={setandoUser} className="bg-Coral">
              <option value="">Selecionar Usuario</option>
              {Users && Users.map((user) => 
                <option value={user.id} key={user.id}>{user.name}</option>
              )}
            </select>
            {User && 
            <div>
              <form className="grid">
                <label htmlFor="Tipo">Tipo de Conta</label>
                <select name="Tipo" id="Tipo" onChange={tipoConta}>
                  <option value="">Selecionar</option>
                  <option value="Vale">Vale</option>
                  <option value="Contas">Contas</option>
                  <option value="Alimentacao">Alimentacao</option>
                  <option value="Manutencao">Manutencao</option>
                </select>
                <label htmlFor="Valor">Valor</label>
                <input type="text" id="Valor" placeholder="Obrigatorio" onChange={valorConta} value={ValorConta}/>
                <label htmlFor="Descricao">Breve Descricao</label>
              <input type="text" maxLength="20"  id="Descricao" placeholder="Nao Obrigatorio" onChange={descricaoConta}/>
              </form>
              {ValorConta && 
                <section className="Agendamento-Conta BorderBox grid">
                  <span className="span-M" style={{color: 'crimson'}}>Agendamento</span>
                  <section>
                    <input className="inputProduction" type="radio"  name="periodo" value="QuinzenaAtual" onClick={Qatual}/>
                    <label htmlFor="periodo"> Quinzena Atual</label><br/>
                    <input className="inputProduction" type="radio"  name="periodo" value="SelecionarQuinzena" onClick={LiberaSelect}/> 
                    <label htmlFor="periodo"> Selecionar Quinzena</label><br/>
                  </section>
                  {SelectQ && <section>
                    <p>Selecione o Ano:</p>
                    <select id="Ano" onChange={SelectYear}>
                      <option value="">Ano</option>
                      {Ano.map((ano) => {
                        return <option key={ano} value={ano}>{ano}</option>
                      })}
                    </select>
                    {SelectedYear && <>
                      <p>Selecione o Mes:</p>
                      <select  id="Mes" onChange={SelectMonth} >
                        <option value="">Mes</option>
                        {Months.map((mes) => {
                          return <option key={mes.mes} value={mes.value}>{mes.mes}</option>
                        })}
                      </select>
                    </>}
                    {SelectedMonth && 
                    <>
                      <p>Selecione a Quinzena:</p>
                      <select  id="Quinzena" onChange={SelectQuinzena} >
                      <option value="">Selecionar Quinzena</option>
                      <option value="0">1° Quinzena</option>
                      <option value="1">2° Quinzena</option>
                      </select>
                    </>}
                      
                </section>}
                {SelectedDia && <button className="btn-M" style={{backgroundColor: 'yellow'}} onClick={CheckAuthorization}> Checar Autorizacao </button>}
                {SelectedDia && <button className="btn-G" onClick={Finish}> Finalizar</button>}
              </section>}
            </div>}
      </div>
      {LoadingTime && <Loading />}
      <Footer />
    </>
  )
}

export default ContasEmissao