import React, { useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom'


import api from '../../services/api'
import BuscaToken from '../Components/Functions/BuscaToken.js'
import Loading from '../Components/Loading'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import duplicity from '../Medias/duplicity.wav'
import CheckStatusRms from '../Components/Functions/CheckStatusRms.js'


const Liberacao = () => {
  const [Txt, setTxt] = useState()
  const [QtdBipada, setQtdBipada] = useState()
  const [LoadingTime, setLoadingTime] = useState()
  const [User, setUser] = useState()
  const [SoundDuplicity, setSoundDuplicity] = useState()

  const config = BuscaToken()
  const history = useHistory()

  if(!SoundDuplicity){
    setSoundDuplicity(new Audio(duplicity))
  }

  useEffect(() => {
    api.get('/users/show', config)
    .then( r => {
      if(!r.data.message){
        setUser(r.data)
      }
    })
  }, [])

  async function qtdBipada (event) {
    if(event.key === 'Enter'){
      let txt = Txt.split('\n')
      txt = txt.filter( item => item.length > 0)
      setQtdBipada(txt.length)
      txt.map(async (item,index) => {
        if(txt.indexOf(item) !== index) {
          const NewTxt = txt.filter((item,i) => {
            if(i !== index)
              return item
          })
          await SoundDuplicity.play()
          const stringNewTxt = NewTxt.toString()
          let NewTxtSerialized = stringNewTxt.replace(/,/g, '\n')
              NewTxtSerialized += '\n'
          setTxt(NewTxtSerialized)
          setQtdBipada(NewTxt.length)  
        } 
      })
    }
  }

  async function Finish () {
    let remessas = Txt.split('\n')
    setLoadingTime(1)
    remessas = await CheckStatusRms(remessas.filter( item => item.length > 2))
    setLoadingTime()
    if(remessas.autorizadas.length > 0 || remessas.barradas.length > 0){
      const UnauthSerialized = remessas.barradas.filter( item => item.status !== 'Pendencia').map( item => item.rm).concat(remessas.autorizadas)
      if(UnauthSerialized.length > 0){
        let UnauthsRmsToDisplay = `Erro ao liberar as remessas abaixo. \n`
        UnauthSerialized.forEach(item => UnauthsRmsToDisplay += `${item} \n` )
        alert(UnauthsRmsToDisplay)
      }
    }
    remessas = remessas.barradas.filter( remessa => remessa.status === 'Pendencia').map( item => item.rm)
    if(remessas.length > 0){
      const data = {
        rms: remessas,
        autor: User.name
      }
      setLoadingTime(1)
      const serverResponse = await api.post('/pendencia/liberacao', data, config)
      setLoadingTime()
      alert(JSON.stringify(serverResponse.data.message))
      history.push('/')
      return 
    }
  }


  return(
    <>
      <Menu />
      <div className="container contentCenter grid">
        <h2>Liberar Remessas</h2>
        {QtdBipada && <span className="span-M ativo">Quantidade bipada: {QtdBipada}</span>}
        <textarea id="Txt" placeholder="Entre com as remessas" style={{width: '100%'}} value={Txt} onChange={(event) => setTxt(event.target.value)} onKeyPress={qtdBipada}></textarea>
        {Txt && Txt.length > 2 && <button className="btn-G" onClick={Finish}> Liberar </button>}
      </div>
      
      <Footer />
      {LoadingTime && <Loading />}
    </>
  )
}

export default Liberacao