import React, { useState, useEffect } from 'react'
import api from '../../services/api'
import { Link } from 'react-router-dom'

import BuscaToken from '../Components/Functions/BuscaToken.js'
import MetricasCourier from '../Components/Production/MetricasCourier.jsx'
import Modal from '../Components/Modal'

const ProductionForCourier =   ({ Data }) => {
  
  const [Relatorio, setRelatorio] = useState([])
  const [Alertado, setAlertado] = useState()
  const [User, setUser] = useState()
  const [NomeCourier, setNomeCourier] = useState()
  const [Periodo, setPeriodo] = useState()
  const [MessageModal, setMessageModal] = useState()

  const config = BuscaToken()


  const monName = ["janeiro", "fevereiro", "março", "abril", "maio", "junho","julho", "agosto", "setembro", "outubro", "novembro", "dezembro"]


    useEffect(() => {

      api.get(`/courier/${Number(Data.courier_id)}`, config).then(r => {
        setNomeCourier(r.data.name)
      })

      api.get(`/trips/ShowTripPendingForCourier/${Number(Data.courier_id)}`, config).then(r => {
        if(r.data.message)
          return false
          setMessageModal(r.data)
      })

      api.get('/users/show', config)
        .then( r => {
          if(!r.data.message)
            setUser(r.data)
        })

     api.post('/production/courier', Data, config).then((r) => {    
        setRelatorio(r.data.detalhado)
        setPeriodo(r.data.Periodo)
      })
     
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
  return (
    <>
      <h2>Courier: {NomeCourier}</h2>
      <h3>{Periodo && Periodo}</h3>
      {User && User.permissao !== 'C' && <MetricasCourier user={Data}/>}
      <table border=".5px" className="tableProduction">
        <thead>
            <tr>
              <th>N° Viagem</th>
              <th>Criada em</th>
              <th>Finalizada em</th>
              {User && User.permissao !== 'C' && <th>Total Saida</th>}
              {User && User.permissao !== 'C' && <th>Total Pequenas</th>}
              {User && User.permissao !== 'C' && <th>Total Grandes</th>}
              <th>Total Entregues</th>
            </tr>
          </thead>
      <tbody>
        {Relatorio && Relatorio.map((trip) => {
          return (
              <tr key={trip.id}>
                {Data.permissao !== "C" && <td><Link to={`/trip/${trip.id}`}> {trip.id} </Link></td>}
                {Data.permissao === "C" && <td> {trip.id}</td>}
                <td>{trip.created_at_json.dia}/{monName.indexOf(trip.created_at_json.mes)+1}/{trip.created_at_json.ano} {trip.created_at_json.hora}:{trip.created_at_json.minutos}</td>
                <td>{trip.finished_at_json.dia}/{monName.indexOf(trip.finished_at_json.mes)+1}/{trip.finished_at_json.ano} {trip.finished_at_json.hora}:{trip.finished_at_json.minutos}</td>
                {User && User.permissao !== 'C' && <td>{trip.Total_saida}</td>}
                {User && User.permissao !== 'C' && <td>{trip.Ps}</td>}
                {User && User.permissao !== 'C' && <td>{trip.Gs}</td>}
                <td>{trip.Total}</td>
              </tr>
          )
        })}
        </tbody>
      </table>
      {User && User.permissao === 'C' && MessageModal && <Modal message={MessageModal}/>}
    </>
  )


}

export default ProductionForCourier