import React, { useState } from 'react'

const Modal = ({message, fecharModal, ConfirmarBaixa}) => {

  return (
    <div className={`modal mostrar`}>
      <div className="modal-interno"> 
        <section className="section-modal BorderBox">
          <h3> Dados do Recebedor </h3> 
          {message && 
            <div className="divTextoModalSearch">
              {message && 
              <>
                <p>Remessa: <span>{message.rm}</span></p>
                {message.destinatario &&  <p>Destinatario: <span>{message.destinatario}</span></p>}
                <p>Recebedor: <span>{message.nome}</span></p>
                <p>Documento: <span>{message.documento}</span></p>
                <p>Parentesco: <span>{message.parentesco}</span></p>
                <p>Usuário da baixa: <span>{message.user}</span></p>
                 <p>Data e hora: <span>{message.finished_at.dia}/{message.finished_at.mes}
                /{message.finished_at.ano}  {message.finished_at.hora}:{message.finished_at.minutos}</span></p>

                {message.signature ?
                 <img src={message.signature} alt="signature" id="signature" /> : 
                 <p>Comprovante nao anexado</p>}

                {message.baixaCliente ? <p> Baixa confirmada em <span>{message.baixaCliente.finished_at.dia}/{message.baixaCliente.finished_at.mes}
                /{message.baixaCliente.finished_at.ano}  {message.baixaCliente.finished_at.hora}:{message.baixaCliente.finished_at.minutos}</span> por <span>{message.baixaCliente.user}</span></p> : <button onClick={ConfirmarBaixa}>Confirmar Baixa</button>}
                
              </>}
            </div>
          } 
        </section>
        <button onClick={fecharModal} className="btn-M">Fechar</button>
      </div>
    </div>
  )
}

export default Modal