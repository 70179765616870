import React, { useEffect, useState } from 'react'
import api from '../../services/api'
import { Link } from 'react-router-dom'

import { LinearProgress } from '@material-ui/core'

import BuscaToken from '../Components/Functions/BuscaToken.js'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import RmtoArray from '../Components/Functions/RmtoArray.js'


const Trips = () => {
  const [Trips, setTrips] = useState([])
  const [Couriers, setCouriers] = useState([])
  const [update, setUpdate] = useState(0)

  const config = BuscaToken()

  useEffect(() => {
    api.get('/trips/pending/Pending', config).then(
    response => {
      setTrips(response.data)
    })

    api.get('/couriers', config).then(r => setCouriers(r.data))
  }, [update])


return  (
  <>
    <Menu />
    <div className="container container-home grid">
      <h2>Trips em Aberto</h2>
      <div className="ContainerTrips grid">
        {
          Trips.map((Trip) => {
            
            let courier = null

            Couriers.map((item) => {
              if((Number(Trip.courier)) === item.id)
                return courier = item.name
            })


            return (
              
                <div key={Trip.id} className={`${Trip.Prazo ? 'tripAtrasada' : ''} boxTrip grid`}>
                  <Link key={Trip.id} to={`/trip/${Trip.id}`}>
                  <p>Trip: {Trip.id}</p>
                  <p>Courier: {courier}</p>
                  <p>{
                  `${Trip.created_at_json.dia} de ${Trip.created_at_json.mes} / ${Trip.created_at_json.ano} `}</p>
                  <p>{`Hora: ${Trip.created_at_json.hora}:${Trip.created_at_json.minutos}`}</p>
                  <p>Total remessas: {RmtoArray(Trip.remessas_saida).length}</p>
                  <br/>
                  <p>Progresso: {Trip.progress}%</p>
                  <LinearProgress 
                    variant="determinate" 
                    value={Trip.progress}
                    color="secondary"
                  />
                  <br/>
                  {Trip.Clientes.map( item =>
                    <p key={item.Cliente}>{item.Cliente}: {item.Quantidade} {item.Quantidade == 1 ? 'remessa' : 'remessas'}</p>
                    )}
                  </Link>
                </div>
              
            )
          })
        }
      </div>  
    </div>
    <Footer />
  </>
)

}

export default Trips