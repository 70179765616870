import React, { useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import api from '../../services/api'


import BuscaToken from '../Components/Functions/BuscaToken.js'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'

const IndexCourier = () => {
  const [Couriers, setCouriers] = useState()
  const [SelectedCourier, setSelectedCourier] = useState()
  const [User, setUser] = useState()


  useEffect(() => {
    api.get('/users/show', BuscaToken())
        .then( r => {
          if(!r.data.message){
            setUser(r.data)
          }
        })

    api.get('/couriers', BuscaToken()).then(response => {
      const c = response.data
      
      const couriers = c.sort(function compare (a,b) {
        if(a.name < b.name) return -1
        if(a.name > b.name) return 1
        return 0
      })
    setCouriers(couriers)
    })
  }, [])

  async function DesativarCourier () {
    const confirmacao = prompt('Digite CONFIRMACAO para proceder.')
    if(confirmacao !== 'CONFIRMACAO'){
      alert('Confirmacao incorreta, operacao abortada')
      return 
    }
    const ResponseServer = await api.post('/courier/delete', {Courier: SelectedCourier}, BuscaToken())
    alert(ResponseServer.data.message)
    window.location.reload()
    return
  }


  return(
    <>
    <Menu />
    {Couriers &&
      <div className="container contentCenter grid">
        <h2>Couriers</h2>
          <div className="grid div-IndexCourier BorderBox">
          <select className="bg-Coral" name="courier" id="courier" onChange={(event) => event.target.value.length > 0 ? setSelectedCourier(event.target.value)
          : setSelectedCourier()}>
            <option value="">Selecionar Courier</option>
            {Couriers.map( courier => 
              <option value={courier.id} key={courier.id}>{courier.name}</option>
              )}
          </select>
          {SelectedCourier &&
            <>
            <button><Link to={`/Courier/Show/${SelectedCourier}`}> Visualizar </Link></button>
            {['A', 'M'].indexOf(User.permissao) !== -1 && <button><Link to={`/Courier/Edit/${SelectedCourier}`}> Editar </Link></button>}
            {User.permissao === 'A' && 
            <>
            <button><Link to={`/Courier/Financeiro/${SelectedCourier}`}> Dados Financeiros </Link></button>
            <button onClick={() => DesativarCourier()}>Desativar Courier</button>
            </>}
            </>
          }
        </div>
      </div>
    }
    <Footer />
    </>
  )
}

export default IndexCourier