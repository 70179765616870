import { Route, BrowserRouter, Switch} from 'react-router-dom'
import React from 'react'

import LogIn from '../../LogIn'
import Home from '../../Home'
import LastMile from '../../LastMile'
import TRIP from '../../Trip'
import TripsIntegracao from '../../TripsIntegracao'
import Production from '../../Production'
import EditTrip from '../../EditTrip'
import SearchRemessa from '../../SearchRemessa'
import SearchRemessasLote from '../../SearchRemessa/SearchLote.jsx'

import CreateCourier from '../../CreateCourier'
import ShowCourier from '../../Courier/ShowCourier.jsx' 
import EditCourier from '../../Courier/EditCourier.jsx'
import FinanceiroCourier from '../../Courier/FinanceiroCourier.jsx'
import IndexCourier from '../../Courier'

import CreateClient from '../../CreateClient'
import CreateUser from '../../CreateUser'
import ChangePass from '../../ChangePass'
import PainelAdmin from '../../PainelAdmin'

import RecebimentoCarga from '../../RecebimentoCarga'
import Comprov from '../../RecebimentoCarga/Comprov.jsx'

import Devolucao from '../../Devolucao'
import Via2 from '../../Devolucao/Via2'
import EntradaRequisicao from '../../Requisicao/entrada.jsx'
import RequisicaoIndex from '../../Requisicao'
import RequisicaoEmissao from '../../Requisicao/emissao.jsx'
import RequisicaoEmAberto from '../../Requisicao/EmAberto.jsx'
import MinhasContas from '../../MinhasContas'
import Contas from '../../Contas'
import ContasEmissao from '../../Contas/emissao'
import ContasPerUser from '../../Contas/ContasPerUser.jsx'
import ChangePassAll from '../../PainelAdmin/ChangePassAll.jsx'
import ProducaoClientes from '../../PainelAdmin/ProducaoClientes'
import EditUsers from '../../PainelAdmin/EditUsers'
import EditTrips from '../../PainelAdmin/EditTrips/index.jsx'
import UpdateClientes from '../../PainelAdmin/UpdateClientes'
import AddTaxasCourier from '../../PainelAdmin/EditCouriers/AddTaxasCourier.jsx'

import PainelRemessas from '../../PainelRemessas'

import DeleteRemessa from '../../DeleteRemessa'

import Pendencia from '../../Pendencia'
import Pendenciar from '../../Pendencia/pendenciar'
import Liberacao from '../../Pendencia/liberacao' 

import ClientesLogins from '../../ClientesLogins'
import CreateClientesLogins from '../../ClientesLogins/create'

import InsercaoDadosRecebedor from '../../InsercaoRecebedor/Insercao.jsx'

import AddAlterTracking from '../../InserirAlterTracking'

import Avarias from '../../Avarias'
import AvariasVia2 from '../../Avarias/Via2.jsx'

import ControleEntradas from '../../ControleEntradas'
import showRomaneioEntrada from '../../ControleEntradas/show.jsx'

import FindPackage from '../../Public/FindPackage.jsx'
 
import IndexBaixaCliente from '../../BaixaCliente'

import IndexOverflows from '../../CargoOverflows'

import SearchByName from '../../SearchRemessa/SearchByName.jsx'

import Syncs from '../../Syncs'
import ShowSync from '../../Syncs/show.jsx'

import PrazosCheck from '../../PainelRemessas/PrazosCheck'


const RoutesAdmin = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={Home} path='/home' exact/>
        <Route component={Home} path='/' exact/>
        <Route component={LastMile} path='/lastmile' exact/>
        <Route component={TRIP} path='/trip/' />
        <Route component={Production} path='/Producao' exact />
        <Route component={EditTrip} path='/EditTrip' />
        <Route component={SearchRemessa} path='/search/remessa/' />
        <Route component={SearchRemessasLote} path='/search/lote' />
        <Route component={DeleteRemessa} path='/DeleteRemessa' />
        <Route component={TripsIntegracao} path='/tripsIntegracao' exact />
        <Route component={CreateCourier} path='/CreateCourier' exact/>
        <Route component={ShowCourier} path='/Courier/Show' />
        <Route component={IndexCourier} path='/Courier' exact/>
        <Route component={EditCourier} path='/Courier/Edit/'/>
        <Route component={FinanceiroCourier} path='/Courier/Financeiro/'/>
        <Route component={CreateClient} path='/CreateClient' exact/>
        <Route component={CreateUser} path='/CreateUser' exact/>
        <Route component={LogIn} path='/LogIn' exact/>
        <Route component={ChangePass} path='/ChangePass' exact/>
        <Route component={PainelAdmin} path='/PainelAdmin' exact/>
        <Route component={ChangePassAll} path='/PainelAdmin/ChangePassAll' exact/>
        <Route component={ProducaoClientes} path='/PainelAdmin/ProducaoClientes' exact/>
        <Route component={EditUsers} path='/PainelAdmin/EditUsers' exact/>
        <Route component={AddTaxasCourier} path="/PainelAdmin/EditCouriers/taxas" exact/>
        <Route component={EditTrips} path='/PainelAdmin/EditTrips' exact/>
        <Route component={UpdateClientes} path='/PainelAdmin/UpdateClientes' exact/>
        <Route component={RecebimentoCarga} path='/RecebimentoCarga' exact/>
        <Route component={Comprov} path='/RecebimentoCarga/Comprov' exact/>
        <Route component={Devolucao} path='/Devolucao' exact/>
        <Route component={Via2} path='/Devolucao/Via2' />
        <Route component={EntradaRequisicao} path='/Requisicao/entrada' exact />
        <Route component={RequisicaoIndex} path='/Requisicao' exact/>
        <Route component={RequisicaoEmissao} path='/Requisicao/emissao' exact/>
        <Route component={RequisicaoEmAberto} path='/Requisicao/EmAberto' exact/>
        <Route component={MinhasContas} path='/MinhasContas' exact/>
        <Route component={Contas} path='/Contas' exact/>
        <Route component={ContasEmissao} path='/Contas/emissao' exact/>
        <Route component={ContasPerUser} path='/Contas/ContasPerUser' exact/>
        <Route component={PainelRemessas} path="/PainelRemessas" exact/>
        <Route component={Pendencia} path="/Pendencia" exact/>        
        <Route component={Liberacao} path="/Pendencia/LiberarRemessas" exact/>        
        <Route component={Pendenciar} path="/Pendencia/PendenciarRemessas" exact/>        
        <Route component={ClientesLogins} path="/ClientesLogins" exact/>        
        <Route component={CreateClientesLogins} path="/ClientesLogins/create" exact/>    

        <Route component={SearchByName} path="/SearchByName" exact/>
       
        <Route component={InsercaoDadosRecebedor} path="/InsercaoRecebedor/Insercao" exact/>
        <Route component={AddAlterTracking} path="/addAlterTracking" exact/>
        <Route component={Avarias} path="/Avarias" exact/> 
        <Route component={AvariasVia2} path="/Avarias/Via2" />  
        <Route component={ControleEntradas} path="/ControleEntradas" exact/>  
        <Route component={showRomaneioEntrada} path="/ControleEntradas/romaneio/"/>  
        <Route component={FindPackage} path='/FindPackage' exact/>
        <Route component={IndexBaixaCliente} path='/BaixaCliente' exact/>
        <Route component={IndexOverflows} path='/CargoOverFlows' exact/>

        <Route component={Syncs} path='/syncs' exact/>
        <Route component={ShowSync} path='/syncs/show/'/>

        <Route component={PrazosCheck} path='/ChecandoPrazos' exact/>

        </Switch>
    </BrowserRouter>
  )
}

export default RoutesAdmin

