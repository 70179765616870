import React, { useState, useEffect } from 'react'
import api from '../../../services/api'

import Menu from '../../Components/Menu/Menu'
import Footer from '../../Components/Footer'
import BuscaClientes from '../../Components/BuscaClientes.jsx'
import BuscaToken from '../../Components/Functions/BuscaToken.js'

const UpdateClientes = () => {
  const [Cliente, setCliente] = useState()
  const [useApp, setuseApp] = useState()
  const [Clients, setClients] = useState([])
  const config = BuscaToken()

  useEffect(() => {
    api.get('/clients', config).then(response => {
      setClients(response.data.clients)
    })
  }, [])

  function SetandoCliente (event) {
    if(event.target.value.length > 0)
      setCliente(event.target.value)
    else
      setCliente()
  }


  async function Finalizar (event) {
    event.preventDefault()
    const TxP = document.querySelector('#TaxaP').value
    const TxG = document.querySelector('#TaxaG').value
    const recebedor = document.querySelector('#recebedor').value
    const Manifesto = document.querySelector('#Manifesto').value
    const lista = document.querySelector('#Lista').value
    const baixa = document.querySelector('#baixa').value
    const status = document.querySelector('#status').value
    const app = document.querySelector('#app') ? document.querySelector('#app').value : null
    if(app !== null && app.length === 0)
      return alert('Favor entrar com nome do App.')
    if(TxP.length > 0 && TxG.length > 0 && recebedor.length > 0 && useApp && Manifesto.length > 0){
      const data = {
        TxP,
        TxG,
        cliente: Cliente,
        useApp,
        app,
        recebedor,
        Manifesto,
        lista,
        baixa,
        status
      }

      const updated = await api.post('/client/updateClientes', data, config)

      alert(JSON.stringify(updated.data.message))

    }
    else
      alert('Campos invalidos.')
  }


  return(
    <>
      <Menu />
      <div className="container contentCenter grid">
        <h2>Alterar Cliente</h2>
        <form className="formUpdateCliente grid">
        <select name="Clients" id="Clients" onChange={SetandoCliente} className="bg-Coral">       
          <option value='false' >Cliente</option>
          {Clients.map(Clients => (
            <option key={Clients.id} value={Clients.name} >{Clients.name}</option>
          ))}
        </select>
          {Cliente && <> 
            <label htmlFor="useapp"></label>
            <select name="useapp" id="useapp" onChange={(event) => {event.target.value.length > 0  && setuseApp(event.target.value)}}>
              <option value="">Cliente usa app?</option>
              <option value={1}>Sim</option>
              <option value={0}>Nao</option>
            </select>
            {(useApp === "1") && <div>
            <label htmlFor="App">App</label>
            <input type="text" id="app"/>
            </div>
            }
            <select name="recebedor" id="recebedor">
              <option value="">Recebedor requerido?</option>
              <option value="1">Sim</option>
              <option value="0">Nao</option>
            </select>
            <select name="Manifesto" id="Manifesto">
              <option value="">Manifesto?</option>
              <option value="1">Sim</option>
              <option value="0">Nao</option>
            </select>
            <select name="Lista" id="Lista">
              <option value="">Lista requerida?</option>
              <option value="1">Sim</option>
              <option value="0">Nao</option>
            </select>
            <label htmlFor="TaxaP">Taxa Pequenas</label>
            <input type="text" id="TaxaP"/>
            <label htmlFor="TaxaG">Taxa Grandes</label>
            <input type="text" id="TaxaG"/>
          </>}
          <br />
          <select name="status" id="status">
            <option value="">Status</option>
            <option value={1}>Ativo</option>
            <option value={0}>Inativo</option>
          </select>
          <select name="baixa" id="baixa">
            <option value="">Baixa na base?</option>
            <option value={1}>Sim</option>
            <option value={0}>Não</option>
          </select>
          <button className="btn-G ativo" onClick={Finalizar}> Finalizar </button>
        </form>
      </div>
      <Footer />
    </>
  )
}

export default UpdateClientes