import React, { useState, useEffect } from 'react'

import api from '../../services/api'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import BuscaToken from '../Components/Functions/BuscaToken.js'


const AddAlterTracking = () => {
  const [Remessa, setRemessa] = useState()
  const [Clientes, setClientes] = useState()
  const [User, setUser] = useState()
  useEffect(() => {
    api.get('/indexPendenciaRecebedor/clientes', BuscaToken())
    .then(r => {
      setClientes(r.data)
    })

    api.get('/users/show', BuscaToken())
        .then( r => {
          if(!r.data.message){
            setUser(r.data.name)
          }
        })
  }, [])

  useEffect(() => {
    if(document.querySelector('#remessa'))
      document.querySelector('#remessa').focus()
  }, [User])

  useEffect(() => {
    if(document.querySelector('#alterTracking'))
      document.querySelector('#alterTracking').focus()
  }, [Remessa])


  const BuscaRm = async (event) => {
    const ResponseServer = await api.get(`/search/rm/${event.target.value}`, BuscaToken())
    if(ResponseServer.data.message){
      document.querySelector('#remessa').value = ''
      document.querySelector('#remessa').focus()
      return alert(ResponseServer.data.message)
    } 
    else
      setRemessa(ResponseServer.data) 
    
  }

  const Finalizar = async (event) => {
    event.preventDefault()
    const alterTracking = document.querySelector('#alterTracking').value
    if(alterTracking.length > 0){
      const data = {
        remessa: Remessa.id,
        alterTracking
      }

      const ServerResponse = await api.post('/addAlterTracking', data, BuscaToken())

      alert(ServerResponse.data.message)
      window.location.reload()
    }
    else
      alert('Dados invalidos!')
  }


  return (
    <>
    <Menu />
    <div className="container contentCenter grid">
    <h2>Painel de inserção Tracking alternativo.</h2>
    {!Remessa && 
    <div style={{textAlign: "center", marginTop: "10px"}}>
      <label style={{display: "inline"}} htmlFor="remessa">Remessa : </label>
      <input type="text" id="remessa" style={{backgroundColor: "coral"}} onKeyPress={(event) => event.key === "Enter" && BuscaRm(event)}/>
    </div>}

    {Remessa && 
    <form onKeyPress={(event) => event.key === "Enter" && Finalizar(event)}>
      <span className="ativo">Remessa: {Remessa.rm}</span>
      <span className="ativo">Cliente: {Remessa.client}</span>
      <label htmlFor="alterTracking">Tracking Alternativo:</label>
      <input type="text" id="alterTracking" placeholder="Obrigatorio"/>
      <button className="btn-addRms" onClick={(event) => Finalizar(event)}> Finalizar </button>
    </form>
    }

    </div>
    <Footer />
    </>
  )
}

export default AddAlterTracking