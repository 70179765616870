import React, { useState, useEffect } from 'react'
import { useHistory} from 'react-router-dom'

import api from '../../services/api'

import BuscaToken from '../Components/Functions/BuscaToken.js'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import Loading from '../Components/Loading'

const EntradaRequisicao = () => {
  const [User, setUser] = useState()
  const [TxtArea, setTxtArea] = useState()
  const [QtdBipada, setQtdBipada] = useState()
  const [TimeLoading, setTimeLoading] = useState()
  
  
  const config = BuscaToken()
  const history = useHistory()

  useEffect(() => {
    api.get('/users/show', config)
    .then( r => {
      if(!r.data.message){
        setUser(r.data)
      }
    })
  }, [])

  
  function onTxtArea (event) {
    setTxtArea(event.target.value)
  }

  function CheckTxtArea (event) {
    if(event.key === 'Enter') {
      let Requisicoes = TxtArea.split('\n')
      Requisicoes = Requisicoes.filter((item) => item.length > 1)
      Requisicoes = Requisicoes.filter((item, index) =>  Requisicoes.indexOf(item) === index)
      setQtdBipada(Requisicoes.length)
      Requisicoes = Requisicoes.toString().replace(/,/g, '\n')
      setTxtArea(Requisicoes)
    }
  }

  async function Finalizar () {
    let requisicoes = TxtArea.split('\n')
    requisicoes = requisicoes.filter(item => item.length > 1)
    const valor = document.querySelector('#Valor').value
    if(valor.length === 0){
      alert('Campo valor nao pode ficar em branco.')
      return false
    }
    const data = {
      requisicoes,
      valor,
      autor: User.name
    }
    setTimeLoading(1)
    const msg = await api.post('/requisicao/entrada', data, config)
    setTimeLoading()
    alert(JSON.stringify(msg.data.message))
    return history.push('/')
  }

  return (
    <>
      <Menu />
      <div className="container contentCenter container-Requisicao grid">
          <h2>Requisicoes</h2>
          <span className="span-M">Requisicoes - Entrada</span>
          <form>
            <label htmlFor="Valor" style={{display: "inline"}}>Valor: </label>
            <input type="text" name="Valor" id="Valor"/>
            {QtdBipada && <span className="span-M ativo">Quantidade: {QtdBipada}</span>}
            <textarea name="requisicoes" id="requisicoes" onChange={onTxtArea} value={TxtArea} onKeyPress={CheckTxtArea}></textarea>
          </form>
          <button className="btn-G" onClick={Finalizar}> Finalizar </button>
      </div>
      {TimeLoading && <Loading />}
      <Footer />
    </>
  )
} 

export default EntradaRequisicao