import React from 'react'


const Table = ({Data}) => {


  return(
    <table className="boxProduction">
          <thead>
            <tr>
              <th>Usuario</th>
              <th>Tipo de Conta</th>
              <th>Criado em</th>
              <th>Desconto em</th>
              <th>Descricao</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            {Data && Data.contas.map((item) => {


              return (
                <tr key={`${item.user}${item.created_at_json.hora}${item.created_at_json.minutos}${item.descricao}`}>
                  <td>{item.user}</td>
                  <td>{item.tipo}</td>
                  <td>{item.created_at_json.dia}/{item.created_at_json.mes}/{item.created_at_json.ano}</td>
                  <td>{item.discount_at_json.dia}/{item.discount_at_json.mes}/{item.discount_at_json.ano}</td>
                  <td>{item.descricao}</td>
                  <td>{`R$ ${item.valor}`.replace('.', ',')}</td>
                </tr>
              )  
            })}
          </tbody>
          <tfoot style={{fontWeight: 'bold'}}>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>Valor Total:</td>
              <td>R$ {Data && Data.total.toFixed(2).replace('.', ',')}</td>
            </tr>
          </tfoot>
        </table>
        
  )
}


export default Table