import React, { useState, useEffect} from 'react'

import RoutesGuest from './Guest'
import RoutesBlank from './Blank'
import RoutesAdmin from './PrivateToAdmin'
import RoutesCourier from './PrivateToCourier'
import RoutesManager from './PrivateToManager'
import RoutesSupport from './PrivateToSupport'
import CheckPermissao from '../Components/Functions/CheckPermissao.js'



const Routes = () => {
 const [atualPermission, setAtualPermission] = useState()

 useEffect(() => {
  CheckPermissao().then( r => setAtualPermission(r))
 }, [])


switch (atualPermission){
  case 'A':
    return <RoutesAdmin/>
  case 'S':
    return <RoutesSupport/>
  case 'M':  
    return <RoutesManager/>
  case 'C':  
    return <RoutesCourier/>  
  case 'G':
    return <RoutesGuest/>
  default: 
    return <RoutesBlank/>
  }
 
}

export default Routes

