import React from 'react'




const Footer = (props) => {



  return (
    <div className={`${props.footerLogin ? 'footerLogIn BorderBox' : ''} grid footer`}>
      <p className="grid"><span className="spanFooter">Expressas Dourados</span>  Todos os direitos reservados. </p>
    </div>
  )

}

export default Footer