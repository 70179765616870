import React, { useState, useEffect } from 'react'
import api from '../../../services/api'
import topLogo from './img/topLogo.png'
import { Link } from 'react-router-dom'

import BuscaToken from '../Functions/BuscaToken.js'

import './style.css'

import Menus from './Menus.jsx'
import MetricasCourier from './MetricasCourier.jsx'
import MetricasSupport from './MetricasSupport.jsx'

const Menu = () => {
  const [User, setUser] = useState()
  const [IdlesCouriers, setIdlesCouriers] = useState([])
  const [NomeUser, setNomeUser] = useState()
  const [BaixasCliente, setBaixasClientes] = useState()
  const [syncs, setSyncs] = useState([])
  const [update, setUpdate] = useState(0)

  const config = BuscaToken()

  useEffect(() => {
    api.get('/users/show', config)
        .then( r => {
          if(!r.data.message){
            setUser(r.data)
            const nomeUserC = r.data.name
            const NomeSplited = nomeUserC.split(' ')
            setNomeUser(NomeSplited[0])
          }
        })

     api.get('/syncs/index', config)
    .then( r => {
      setSyncs(r.data)
    })

  }, [update])


  useEffect(() => {
    if(User){
      if(User.permissao !== 'C') {
        api.get('couriers/idle', config)
        .then( r => {
          setIdlesCouriers(r.data)
        })
        api.get('/baixacliente/index', BuscaToken())
        .then( r => {
          setBaixasClientes(r.data.remessas.length)
        })
      }
      
    }
  }, [User, update])

  function LogOut () {
    localStorage.removeItem('token')
    setTimeout(() => {
      window.location.href = "/"
    }, 50);
  }


  function BuscaRM () {
    const RM = document.getElementById('searchRM').value
    if(RM.length < 5)
      alert('Remessa invalida!')
    else {
      window.location.href = `/search/remessa/${RM}`
    }  
  }


  async function updateStatusSync(client) {
    await api.get(`/sync/status/${client}`, config)
    setUpdate(update+1)
  }


  return (
    <>
      <header className="header grid">
        <a href="/home" className="aTopLogo grid">
          <img className="topLogo grid" src={topLogo} alt="LogoExpressas"/>
        </a>
        <div className="infoOperacao grid">
          <div className={`painelInf grid ${User?.permissao === 'C' ? '' : 'paineInfAdm'}`}>
            {syncs.map( item => 
              <p style={{ textAlign: 'center' }}>
                Sync {item.client} - 
                {' '}
                {item.status ? <span style={{ color: 'green' }}>Ativo</span> : 
                <span style={{ color: 'crimson' }}>Inativo</span>}
                {' '}
                {User && User.permissao === 'A' && 
                  <button
                    className="btn-P" 
                    style={{ padding: '2px', fontSize: '10pt'}}
                    onClick={() => updateStatusSync(item.client)}
                  >
                    {item.status ? 'Inativar' : 'Ativar'}
                  </button>
                }
              </p>
            )}
            
            {BaixasCliente ? <p><Link to="/BaixaCliente" className="informativoBaixaCliente">{BaixasCliente} {+BaixasCliente > 1 ? 'remessas' : 'remessa'} aguardando baixa</Link></p> : ''}
          </div>
              {User && <Menus user={User.permissao}/>}
          {User && User.permissao !== undefined && User.permissao === 'C' ? <MetricasCourier user={User} /> : <MetricasSupport user={User} BuscaRM={BuscaRM} IdlesCouriers={IdlesCouriers}/>}
        </div>
        <div className="containerLogOut grid">
            <section className="grid">
              <p>Bem vindo <span>{NomeUser}</span></p>
              <button className="btn-G" onClick={LogOut}>Logout</button>
              <Link to="/ChangePass">Mudar Senha</Link>
              <Link to="/MinhasContas">Minhas Contas</Link>
            </section>
        </div>
      </header>
    </>
  )
}

export default Menu