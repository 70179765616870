import React, { useState, useEffect } from 'react'
import api from '../../services/api'


const BuscaClientes = (props) => {
  const [Clients, setClients] = useState([])
  
  
  
  
  useEffect(() => {
    const token = localStorage.getItem('token')
      const config ={
        headers: {
          Authorization: token,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    
    api.get('/clients/actives', config).then(response => {
      setClients(response.data.clients)
    })
  }, [])


  return (
    <select name="Clients" id="Clients" onChange={props.SetandoCliente} className={props.bgCoral && 'bg-Coral'}>       
      <option value='false'>Cliente</option>
      {Clients.map(Clients => (
        <option key={Clients.id} value={Clients.name} >{Clients.name}</option>
      ))}
    </select>

  )


}

export default BuscaClientes