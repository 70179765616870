import React, { useEffect, useState } from 'react'
import api from '../../../services/api'

import Loading from '../../Components/Loading'

const EditOneUser = ({user}) => {
  const [User, setUser] = useState({})
  var TimeLoading = 0

  const token = localStorage.getItem('token')
  const config ={
    headers: {
      Authorization: token,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }  
  
  useEffect(() => {
    api.get(`/users/showOne/${user}`, config).then(r => setUser(r.data))
    // eslint-disable-next-line
  }, []) 


  const permissoes = [{sigla: "A", permissao: "Admin"}, {sigla: "M", permissao: "Manager"}, {sigla: "S", permissao: "Support"}, {sigla: "C", permissao: "Courier"},]

  function buscaPermissao (sigla) {
    let permissao = null

    permissoes.forEach((item) => {
      if(item.sigla === sigla)
        permissao = item.permissao
    })

    return permissao
  }

  async function UpdateUser () {
    let name = User.name
    let login = User.login
    let documento = User.documento
    const permissao = document.getElementById('Permissao').value

      if(document.getElementById('name').value.length > 3)
        name = document.getElementById('name').value       
      if(document.getElementById('Login').value.length > 3)
        login = document.getElementById('Login').value        
      if(document.getElementById('Documento').value.length > 3)        
        documento = document.getElementById('Documento').value


    const data = {
      id: user,
      name,
      login,
      documento,
      permissao
    }
    TimeLoading = 1
    const userUpdated = await api.post('/users/update', data, config)
    TimeLoading = 0
    alert(JSON.stringify(userUpdated.data.message))
  }

  

  return (
    <>
      <h3 style={{color: "black", fontSize: "20pt"}}>Alteracao do User <span style={{fontSize: "23pt"}}>{User.name}</span></h3>
      <form>
        <label htmlFor="name">Nome</label>
        <input type="text" id="name"/>
        <label htmlFor="Login">Login</label>
        <input type="text" id="Login"/>
        <label htmlFor="Documento">Documento</label>
        <input type="text" id="Documento"/>
        <label htmlFor="Permissao"> Permissao </label>
        <select  defaultValue={User.Permissao} name="Permissao" id="Permissao">
          <option value={User.permissao}>{buscaPermissao(User.permissao)}</option>
          {permissoes.map((permissao) => {
            if(permissao.sigla !== User.permissao)
              return (
                <option key={permissao.sigla} value={permissao.sigla}>{permissao.permissao}</option>
              )
          })}
        </select>
      </form>
      <button className="btn-addRms" style={{ display: "block", MarginTop: '20px'}} onClick={UpdateUser}> Confirmar Alteracao</button>
      {TimeLoading === 1 && <Loading />}
    </>
  )
}

export default EditOneUser