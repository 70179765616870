import React, { useEffect, useState } from 'react'
import api from '../../services/api'
import { Link } from 'react-router-dom'

import BuscaToken from '../Components/Functions/BuscaToken.js'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import {default as LoadingModal } from '../Components/Loading'

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {
          Chip, 
          CardHeader, 
          Divider,
          TableSortLabel,
          Tooltip,
          MenuItem,
          TextField
  } from '@material-ui/core';

import moment from 'moment'


const Syncs = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [columns, setColumns] = useState([
    { id: 'id', label: 'ID', minWidth: 50, direction: 'asc'},
    { id: 'status', label: 'Status', minWidth: 50, direction: 'asc'},
    { id: 'trips', label: 'Trips finalizadas', minWidth: 50, direction: 'asc'},
    { id: 'initializedAt', label: 'Iniciado em', minWidth: 50, direction: 'asc'},
    { id: 'finishedAt', label: 'Concluído em', minWidth: 50, direction: 'asc'},
  ])
  const [rows, setRows] = useState()
  const [filters, setFilters] = useState({
    initializedAt: new Date().getTime()
  })
  const [pages, setPages] = useState(0)
  const [frontEndFilters, setFrontEndFilters] = useState({})
  const [showLoading, setShowLoading] = useState()

  const config = BuscaToken()

  useEffect(() => {
    updateTable()
  }, [filters, rowsPerPage, page] )

  async function updateTable() {
    setShowLoading(1)
    const data = await api.post(`/syncs/index`, {
      filters, offset: page*rowsPerPage, 
      limit: rowsPerPage+page*rowsPerPage
    } , config)

    setShowLoading()
    if(data.data.auth){
      if(data.data.syncs.length === 0)
        alert('No sync for display.')
      setRows(data.data.syncs)
      setPages(Math.ceil(data.data.pages/rowsPerPage))
      setFrontEndFilters(data.data.filters)
      if(data.data.pages !== pages)
        setPage(0)
    }
  }

  const handleFilter = (id, value) => {
    setFilters({
      ...filters,
      [id]: value
    })

  }
  

  const sortValues = (index) => {
      if(columns[index]['direction'] === 'asc') {
        setRows(rows.sort(function compare(a, b) {
          if (a[columns[index]['id']].value > b[columns[index]['id']].value) return -1
          if (a[columns[index]['id']].value < b[columns[index]['id']].value) return 1
          return 0
        }))
      } else {
        setRows(rows.sort(function compare(a, b) {
          if (a[columns[index]['id']].value < b[columns[index]['id']].value) return -1
          if (a[columns[index]['id']].value > b[columns[index]['id']].value) return 1
          return 0
        }))
      }
        setColumns(columns.map( item => {
          if(columns.indexOf(item) !== index)
            return item
          return {
            ... item,
            direction: item.direction === 'asc' ? 'desc' : 'asc'
          }  
        }))
  }


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };



return  (
  <>
    <Menu />
    <div className="container container-home grid">
      <h2>Lista de sincronizações</h2>
        <Paper style={{ padding: '10px', margin: '20px' }} elevation={5}>
        {showLoading && <LoadingModal />}
        {rows && 
        <>
          <CardHeader title="Sincronizações" />
          <Divider />
          <TableContainer sx={{ maxHeight: "70vh"}}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Tooltip
                        enterDelay={300}
                        title="Sort"
                      >
                      <TableSortLabel
                        active={true}
                        direction={(() => 
                        column.direction ? column.direction : 'desc'
                          )()}
                        onClick={() => sortValues(index)}
                        >
                        {column.label}
                        </TableSortLabel>
                      </Tooltip>
                      <TextField
                        fullWidth
                        label="Filtro"
                        name="Filter"
                        onChange={(event) => handleFilter(column.id, event.target.value)}
                        select
                        margin="normal"
                        size="small"
                        value={filters[column.id] ? filters[column.id] : ''}
                      >
                        <MenuItem value=''>
                          Padrão
                        </MenuItem>
                        {
                          frontEndFilters[column.id] &&  frontEndFilters[column.id].map(item => {
                            if(item.label !== null && item.label !== 'error' && item.value !== undefined)
                              return <MenuItem value={item.value} key={item.label}>
                                {item.label}
                              </MenuItem>
                          }) 
                        }  
                      </TextField>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id.label}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return ( 
                          <TableCell 
                            key={column.id} 
                            align={column.align}
                          >
                            <Link to={`/syncs/show/${row.id.value}`} style={{ color: 'inherit'}}>
                              {(() => {
                                if(value) {
                                  if(column.id === 'status')
                                  return <Chip
                                            style={{
                                              backgroundColor: '#F57C00'
                                            }}
                                            label={value.label}
                                            size="small"
                                          />  
                                  return value.label
                                } else
                                  return ''
                              })()}
                            </Link>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage='Linhas por página'
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={pages}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </>} 
      </Paper>
    </div>
    <Footer />
  </>
)

}

export default Syncs