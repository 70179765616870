import BuscaToken from './BuscaToken.js'
import api from '../../../services/api'

const CheckStatusRms = async (remessas, client) => {
  const config = BuscaToken()

  const data = {
    rms: remessas,
    client
  }
  const Checked = await api.post('/checkStatusRms', data, config)

  return await Checked.data


}


export default CheckStatusRms