import React, { useEffect, useState } from 'react'
import api from '../../services/api'

import BuscaToken from '../Components/Functions/BuscaToken.js'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import ProductionForCourier from './ProductionForCourier'
import ProductionForCourierAll from './ProductionForCourierAll'

const Production = () => {
    const [Couriers, setCouriers] = useState([])
    const [SelectedCourier, setSelectedCourier] = useState()
    const [Ano, setAno] = useState([])
    const [SelectQ, setSelectQ] = useState(0)
    const [SelectedYear, setSelectedYear] = useState()
    const [SelectedMonth, setSelectedMonth] = useState()
    const [SelectedQuinzena, setSelectedQuinzena] = useState(0)
    const [BuscaDados, setBuscaDados] = useState(0)
    const [Data, setData] = useState({})
    const [User, setUser] = useState()
    const [DataServer, setDataServer] = useState()


    const config = BuscaToken()


    const Months = [{mes: 'Janeiro', value: "01"},{mes: 'Fevereiro', value: "02"},{mes: 'Marco', value: "03"},{mes: 'Abril', value: "04"},{mes: 'Maio', value: "05"},{mes: 'Junho', value: "06"},{mes: 'Julho', value: "07"},{mes: 'Agosto', value: "08"},{mes: 'Setembro', value: "09"},{mes: 'Outubro', value: "10"},{mes: 'Novembro', value: "11"},{mes: 'Dezembro', value: "12"}]
    
    const Quinzenas = [{Quinzena: '1°', values: {i: '01', f: '15'}},{Quinzena: '2°', values: {i: '16', f: '31'}}]

    useEffect(() => {
      let DataAtual = null

      api.get('/HoraServer/timestamp', config).then( r => {
        const data = r.data.split('')
        DataAtual = {
          ano: `${data[0]}${data[1]}${data[2]}${data[3]}`,
          mes: `${data[4]}${data[5]}`,
          dia: `${data[6]}${data[7]}`
        }
        setDataServer(DataAtual)
        const anoAtual = Number(DataAtual.ano)
        setAno([anoAtual - 1, anoAtual])

        api.get('/users/show', config)
        .then( r => {
          if(!r.data.message){
            setUser(r.data)
            if(r.data.courier_id){
            let Quinzena = null
            if(Number(DataAtual.dia) <= 15)
              Quinzena = (Quinzenas[0].values)
            else if(Number(DataAtual.dia) > 15)
              Quinzena = (Quinzenas[1].values)         
            setData({
              permissao: r.data.permissao,
              courier_id: r.data.courier_id,
              year: Number(DataAtual.ano),
              month: DataAtual.mes,
              Quinzena
            })
              setBuscaDados(1)
            }
        }
    })
      })
    }, [])


    useEffect(() => {
      if(User){
        if(User.permissao !== 'C'){
          api.get('/couriers', config).then(r => {
          const couriers = r.data.sort(function compare (a,b) {
            if(a.name < b.name) return -1
            if(a.name > b.name) return 1
            return 0
          })
            
            setCouriers(couriers)
    
          })
        }
      }


    }, [User])


    const SetandoCourier = (event) => {
      setSelectedCourier(event.target.value)
    }
    
    const Qatual = () => {
      setSelectQ(0)
      setSelectedYear(Number(DataServer.ano))
      setSelectedMonth(DataServer.mes)
      if(Number(DataServer.dia) <= 15)
        setSelectedQuinzena(Quinzenas[0].values)
      else if(Number(DataServer.dia) >= 15)
        setSelectedQuinzena(Quinzenas[1].values)
    }
    const LiberaSelect = () => {
      setSelectQ(1)
    }

    const SelectYear = (event) => {
      setSelectedYear(event.target.value)
    }
    const SelectMonth = (event) => {
      setSelectedMonth(event.target.value)
    }
    const SelectQuinzena = (event) => {
      setSelectedQuinzena(Quinzenas[event.target.value].values)
      
    }

    const BuscarDados = () => {
      if(SelectedCourier === 'all')
        setBuscaDados(2)
      else
        setBuscaDados(1)
        
      setData({
        permissao: User.permissao,
        courier_id: SelectedCourier,
        year: SelectedYear,
        month: SelectedMonth,
        Quinzena: SelectedQuinzena
      })
    }
  if(User && User.permissao !== 'C')
  return (
    <>
      <Menu />
       <div className="container grid container-Production">
         <h2>Produção</h2>
        {BuscaDados == 0 && 
        <div className='boxProduction BorderBox grid'>
          <select className="select" name="courier" id="courier" onChange={SetandoCourier}>
            <option value="">Selecionar Entregador</option>
            <option value="all">Todos os entregadores</option>
          {Couriers.map((Courier) => {
            return (
            <option key={Courier.id} value={Courier.id}>{Courier.name}</option>
            )
          })}
          </select>
          <section className={`${SelectedCourier ? 'ativo' : 'inativo'}`}>
            <input className="inputProduction" type="radio"  name="periodo" value="QuinzenaAtual" onClick={Qatual}/>
            <label htmlFor="periodo"> Quinzena Atual</label><br/>
            <input className="inputProduction" type="radio"  name="periodo" value="SelecionarQuinzena" onClick={LiberaSelect}/> 
            <label htmlFor="periodo"> Selecionar Quinzena</label><br/>
          </section>
          <section className={SelectQ ? 'ativo grid' : 'inativo'}>
            <p>Selecione o Ano:</p>
            <select id="Ano" onChange={SelectYear}>
              <option value="">Ano</option>
              {Ano.map((ano) => {
                return <option key={ano} value={ano}>{ano}</option>
              })}
            </select>
            <section className={SelectedYear ? 'ativo grid' : 'inativo'}>
              <p>Selecione o Mes:</p>
              <select  id="Mes" onChange={SelectMonth} >
                <option value="">Mes</option>
                {Months.map((mes) => {
                  return <option key={mes.mes} value={mes.value}>{mes.mes}</option>
                })}
              </select>
            </section>
            <section className={SelectedMonth ? 'ativo grid' : 'inativo'}>
              <p>Selecione a Quinzena:</p>
              <select  id="Quinzena" onChange={SelectQuinzena} >
                <option value="">Selecionar Quinzena</option>
                <option value="0">1° Quinzena</option>
                <option value="1">2° Quinzena</option>
              </select>
            </section>
          </section>
          <button className={SelectedQuinzena ? 'ativo grid btn-M' : 'inativo'} onClick={BuscarDados}>Buscar Dados</button>
        </div>}
        {BuscaDados === 1 && <ProductionForCourier Data={Data} /> }
        {BuscaDados === 2 && <ProductionForCourierAll Data={Data} /> }
      </div>
        <Footer />
      </>
  )
  else
    return (
      <>
        <Menu />
        <div className="container grid container-Production">
          {BuscaDados === 1 && <ProductionForCourier Data={Data} /> }
      </div>
        <Footer />
      </>
    )
}

export default Production