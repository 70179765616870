import React, { useState, useEffect} from 'react'
import api from '../../../services/api'
import { Link } from 'react-router-dom'
import BuscaToken from '../Functions/BuscaToken.js'

const MetricasSupport = (props) => {
  const [Metricas, setMetricas] = useState()
  const [user, setUser] = useState()
  const [update, setUpdate] = useState(0)
  const [sync, setSync] = useState('')

  const config = BuscaToken()

  useEffect(() => {
    api.get('/Performance', config).then(r => {
      setMetricas(r.data)
    })
    api.get('/users/show', config)
        .then( r => {
          if(!r.data.message){
            setUser(r.data)
          }
        })

    api.get('/syncs/lastsync', config)
    .then(r => setSync(r.data))

  }, [update])



  function SearchRmEnter (event){
    if(event.key === 'Enter')
      props.BuscaRM(event.target.value)
  }

  return (
    <>
    <div className="statusOperacao grid">
            <p>Status da Operacao:</p>
              <ul>
                <li>Remessas em rota: <span>{Metricas && Metricas.RemessasEmRota}</span></li> 
                <li>Remessas no piso: <span>{Metricas && Metricas.RemessasNoPiso}</span></li>
                <li>Performance atual: <span>{Metricas && Metricas.Performance} %</span></li>
                  {
                    sync.status === 'Concluído' ?
                      <li style={{ fontSize: '12pt'}}>
                        Últ. Sincronismo: <span>{sync.finishedAt}</span>
                      </li> :
                      <li style={{ fontSize: '12pt'}}>
                        Sinc. em Process.: <span>{sync.initializedAt}</span>
                      </li>
                  }
                
              </ul>
              <p className="SearchRemessa"><input type="text" id="searchRM" placeholder="Digite a remessa + Enter" onKeyPress={SearchRmEnter}/></p>
    </div>
     <div className="couriersOciososBase grid">
     <p className="grid">{props.IdlesCouriers.length} Entregadores Ociosos:</p>
     <ul className="grid">
       {props.IdlesCouriers.map((courier) => {
         return (
           <li key={courier.id}><Link to={`/Courier/Show/${courier.id}`}>{courier.name}</Link> <br/> {courier.telefone}</li>
         )
       })}
     </ul>
   </div>    
   </> 
  )
}

export default MetricasSupport