import React, { useState, useEffect} from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { VscArrowRight, VscArrowLeft } from 'react-icons/vsc'
import api from '../../services/api'
import BuscaToken from '../Components/Functions/BuscaToken.js'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import Loading from '../Components/Loading'

const PainelRemessas = () => {
  const [Remessas ,setRemessas] = useState()
  const [CurrentRemessas ,setCurrentRemessas] = useState()
  const [CurrentPage, setCurrentPage] = useState()
  const [Pages, setPages] = useState()
  const [PageRms, setPageRms] = useState()
  const [ArrayPages, setArrayPages] = useState()
  const [Clientes, setClientes] = useState()
  const [Filtro, setFiltro] = useState()


  const config = BuscaToken()
  useEffect(() => {
    api.get('/clients/actives', config).then(response => {
      setClientes(response.data.clients)
    })
    api.get('/indexEmAberto', config)
    .then( r =>{
       setRemessas(r.data)
       setCurrentRemessas(r.data)
      })
  }, [])

  useEffect(() => {
    if(CurrentRemessas){
      const pageVV = () => {
        let arrayPages = ['']
        for(let i = 0; i < CurrentRemessas.length; i = i + 100 ) {
          arrayPages.push({
            i,
            f: i + 99
          })  
        }
        setArrayPages(arrayPages)
        return arrayPages
      }
      const arrayPages = pageVV()
      setPages(Math.ceil(CurrentRemessas.length / 100))
      setCurrentPage(1)
      const RmsPage = CurrentRemessas.filter((rm, index) => (index <= arrayPages[1].f && index >= arrayPages[1].i) )
      setPageRms(RmsPage)
    }
  }, [CurrentRemessas])

  const previousPage = () => {
    const currentPage = CurrentPage - 1
    setCurrentPage(currentPage)
    const RmsPage = CurrentRemessas.filter((rm, index) => (index <= ArrayPages[currentPage].f && index >= ArrayPages[currentPage].i) )
    if(Filtro){
      return setPageRms(RmsPage.filter(item => item.client === Filtro))
    }
    else
      return setPageRms(RmsPage)
  }
  
  const nextPage = () => {
    const currentPage = CurrentPage + 1
    setCurrentPage(currentPage)
    const RmsPage = CurrentRemessas.filter((rm, index) => (index <= ArrayPages[currentPage].f && index >= ArrayPages[currentPage].i) )
    if(Filtro){
      return setPageRms(RmsPage.filter(item => item.client === Filtro))
    }
    else
      return setPageRms(RmsPage)
  }


  const filtro = (event) => {
    if(event.target.value.length > 0){
      setPageRms(PageRms.filter(item => item.client === event.target.value))
      setCurrentRemessas(Remessas.filter(item => item.client === event.target.value))
      return setFiltro(event.target.value)    
    }
    else{
      setPageRms(Remessas.filter((rm, index) => (index <= ArrayPages[CurrentPage].f && index >= ArrayPages[CurrentPage].i) ))
      setCurrentRemessas(Remessas)
      return setFiltro()
    }
  }


  return (
    <>
      <Menu />
      <div className="container contentCenter container-PainelRemessas grid">
        <h2>Painel de Remessas</h2>
    
        <div >
      <label htmlFor="filtro" style={{display: 'inline'}} >Filtro por Clientes </label>
      <select name="clientes" id="clientes"  onChange={(event) => filtro(event)}>
        <option value="">Todos</option>
        {Clientes && Clientes.map( cliente => <option value={cliente.name} key={cliente.id}>{cliente.name}</option>)}
      </select>
      {CurrentRemessas && <span className="span-M ativo">Total em exibicao: {CurrentRemessas.length}</span>}
      </div>  
        <table className="tableProduction">
          <thead>
            <tr>
              <th>Prazo</th>
              <th>Remessa</th>
              <th>Cliente</th>
              <th>Criação</th>
              <th>Status</th>
            </tr>
          </thead>
          {PageRms && 
            <tbody>
              {PageRms.map( remessa => 
                  <tr key={remessa.rm} className="td-PainelRemessas">
                  <td>{remessa.prazo}</td>
                  <td><Link to={`/search/remessa/${remessa.rm}`} >{remessa.rm}</Link></td>
                  <td>{remessa.client}</td>
                  <td>{remessa.createdAt.dia}/{remessa.createdAt.mes}/{remessa.createdAt.ano}</td>
                  <td onClick={() => alert(`ID remessa ${remessa.rm}: ${remessa.id}`)}>{remessa.status}</td>
                </tr>)}
            </tbody>}
            <tfoot>
              <tr>
                <td>Em exibicao: {PageRms &&  PageRms.length}</td>
                <td>Paginas: {CurrentPage}/{Pages ? Pages : 1}</td>
                <td></td>
                <td>{CurrentPage && 
                  CurrentPage > 1 && <button className="btn-M" onClick={previousPage}><VscArrowLeft /> Pagina Anterior</button>
                }</td>
                <td>{CurrentPage && 
                  Pages - CurrentPage > 0 && <button className="btn-M" onClick={nextPage}>Proxima Pagina <VscArrowRight/></button>
                }</td>
              </tr>
            </tfoot>
        </table>
      </div>
      <Footer />
    </>
  )

}

export default PainelRemessas