import React, {useState, useEffect} from 'react'
import api from '../../../services/api'
import BuscaToken from '../Functions/BuscaToken.js'

const MetricasCourier = ({user}) => {
  const [Relatorio, setRelatorio] = useState()
  const [Bonificacao, setBonificacao] = useState()
  const [Prms,setPrms] = useState(0)
  const [Grms,setGrms] = useState(0)
  const [Meta, setMeta] = useState()
  const [RendaBruta, setRendaBruta] = useState(0)
  const [Contas, setContas] = useState(0)


  const config = BuscaToken()
    
    const Quinzenas = [{Quinzena: '1°', values: {i: '01', f: '15'}},{Quinzena: '2°', values: {i: '16', f: '31'}}]
  
    useEffect(() => {
      api.get('/HoraServer/timestamp', config).then( r => {
        const data = r.data.split('')
        const DataAtual = {
          ano: `${data[0]}${data[1]}${data[2]}${data[3]}`,
          mes: `${data[4]}${data[5]}`,
          dia: `${data[6]}${data[7]}`
        }
        let Quinzena = null
        if(Number(DataAtual.dia) <= 15)
          Quinzena = (Quinzenas[0].values)
        else if(Number(DataAtual.dia) > 15)
          Quinzena = (Quinzenas[1].values)         
        const periodo = {
          year: Number(DataAtual.ano),
          month: DataAtual.mes,
          Quinzena
        }
        
        const dataPost = {
          courier_id: user.courier_id,
          year: periodo.year,
          month: periodo.month,
          Quinzena
        }

        api.post('/production/courier', dataPost, config)
        .then(responseProduction => {
          setRelatorio(responseProduction.data)
          const bonificacao = (() => {
            if(((responseProduction.data.resumido.Total) - 500) >= 0)
              return 'Parabens! Meta atingida!'
            else{
              return `Falta ${ 500 - responseProduction.data.resumido.Total } entregas para atingir a meta! `
            }
          })()
          setBonificacao(bonificacao)
        })

        api.get(`/courier/${user.courier_id}`, config).then( r => setMeta(r.data.meta) )

        api.post('/contas/checkSaldo', {
          courier: user.courier_id,
          periodo
        }, config).then( r => setRendaBruta(r.data) )

        api.post('/contas/extratoPerUser', {
          user: user.id,
          periodo
        }, config ).then( r => setContas(r.data.valorTotal)) 

    })}, [])
    
 
  return (
    <>
      <div className="statusOperacao">
        <h3>Metricas da quinzena corrente</h3>
        <p>Total entregas: {Relatorio && Relatorio.resumido.Total}</p>
        <p>Entregas Pequenas: {Relatorio && Relatorio.resumido.Ps}</p>
        <p>Entregas Grandes: {Relatorio && Relatorio.resumido.Gs}</p>
        {Meta === 'true' && <p>{Bonificacao}</p>}
        
      </div>
      
      <div className="couriersOciososBase MetricaCourier-Relatorio grid" >
        <h3> Relatorio Financeiro </h3>
        {Meta === 'true' ? (Bonificacao === 'Parabens! Meta atingida!' ? <p>Bonificacao: R$ 100.00</p> : <p>Bonificacao: R$ 0.00</p>) : ''}
        <p>Renda Bruta: R$ {RendaBruta.toFixed(2).replace('.', ',')}</p>
        <p>Descontos: R$ {Contas ? Contas.toFixed(2).replace('.', ',') : '0,00'}</p>
        <p>Renda Liquida: R$ {(RendaBruta - Contas).toFixed(2).replace('.', ',')}</p>
      </div>
    </>    
  )
}

export default MetricasCourier