import React, { useState, useEffect } from 'react'

import api from '../../services/api'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import BuscaToken from '../Components/Functions/BuscaToken.js'
import moment from 'moment'

const InsercaoDadosRecebedor = () => {
  const [Remessa, setRemessa] = useState()
  const [Recebedor, setRecebedor] = useState('')
  const [Destinatario, setDestinatario] = useState('')
  const [Documento, setDocumento] = useState('')
  const [Parentesco, setParentesco] = useState('')
  const [User, setUser] = useState()

  useEffect(() => {

    api.get('/users/show', BuscaToken())
        .then( r => {
          if(!r.data.message){
            setUser(r.data.name)
          }
        })
  }, [])

  useEffect(() => {
    if(document.querySelector('#remessa'))
      document.querySelector('#remessa').focus()
  }, [User])

  useEffect(() => {
    if(document.querySelector('#nome'))
      document.querySelector('#nome').focus()
  }, [Remessa])


  const BuscaRm = async (event) => {
    const ResponseServer = await api.get(`/baixacliente/search/${event.target.value}`, BuscaToken())
    if(ResponseServer.data.message){
      document.querySelector('#remessa').value = ''
      return alert(ResponseServer.data.message)
    } 
    setRemessa(ResponseServer.data)
    if(ResponseServer.data.destinatario) {
      setDestinatario(ResponseServer.data.destinatario)
    }
    if(ResponseServer.data.recebedor_destinatario){
      setDestinatario(ResponseServer.data.recebedor_destinatario)
      setRecebedor(ResponseServer.data.recebedor_name)
      setDocumento(ResponseServer.data.recebedor_document)
      setParentesco(ResponseServer.data.recebedor_grau)
    }

  }

  const Finalizar = async (event) => {
    event.preventDefault()
    if(!Destinatario || !Recebedor || !Documento || !Parentesco)
      return alert('Dados invalidos.')

      const data = {
        rm: Remessa.rm,
        Parentesco,
        Recebedor,
        Destinatario,
        Documento,
        User
      }

      const ServerResponse = await api.post('/addRecebedor', data, BuscaToken())

      alert(ServerResponse.data.message)
      setRemessa()
      setRecebedor()
      setDestinatario()
      setDocumento()
      setParentesco()
    
  }


  return (
    <>
    <Menu />
    <div className="container contentCenter grid">
    <h2>Painel de inserção de dados.</h2>
    {!Remessa && 
      <>
      <label htmlFor="remessa" style={{display: 'inline'}}>Remessa : <input type="text" className="bg-Coral" id="remessa" onKeyPress={(event) => event.key === "Enter" && BuscaRm(event)}/> </label>
      
      </>}

    {Remessa && 
    <form className="grid" onKeyPress={(event) => event.key === "Enter" && Finalizar(event)}>
      <span className="span-M ativo">Remessa: {Remessa.rm}</span>
              <span className="span-M ativo">Cliente: {Remessa.client}</span>
              {Remessa.prazo && <p>Prazo: {moment(Remessa.prazo).format('DD/MM/YYYY')}</p>}
              {Remessa.destinatario ? 
                <>
                  <p>Destinatario: <span>{Remessa.destinatario}</span></p>
                  <p>Endereço: <span>{Remessa.endereco}</span></p>
                  <p>Bairro: <span>{Remessa.bairro}</span></p>
                  <p>Contato: <span>{Remessa.contato}</span></p>
                  <p>Cidade: <span>{Remessa.cidade}</span></p>
                </> :
                <>
                  <label htmlFor="nomeDestinatario">Destinatario:</label>
                  <input 
                    type="text" 
                    id="nomeDestinatario" 
                    placeholder="Obrigatorio" 
                    onChange={(event) => setDestinatario(event.target.value)}
                    value={Destinatario}
                    />
                </>
              }
      <label htmlFor="Recebedor">Nome Recebedor:</label>
      <input type="text" value={Recebedor} placeholder="Obrigatorio" onChange={(event) => setRecebedor(event.target.value)}/>
      <label htmlFor="Documento">Documento:</label>
      <input type="text" value={Documento} placeholder="Obrigatorio" onChange={(event) => setDocumento(event.target.value)}/>
      <label htmlFor="parentesco">Grau de Parentesco:</label>
      <select name="parentesco" value={Parentesco} onChange={(event) => setParentesco(event.target.value)}>
        <option value="">Selecionar</option>
        <option value="Proprio">Proprio</option>
        <option value="Esposo(a)">Esposo(a)</option>
        <option value="Mae/Pai">Mae/Pai</option>
        <option value="Filho">Filho</option>
        <option value="Primo(a)">Primo(a)</option>
        <option value="Irmao">Irmao</option>
        <option value="Vizinho">Vizinho</option>
        <option value="Funcionario">Funcionario</option>
        <option value="Porteiro">Porteiro</option>
        <option value="Outros">Outros</option>
      </select>
      <button className="btn-G ativo" onClick={(event) => Finalizar(event)}> Finalizar </button>
    </form>
    }

    </div>
    <Footer />
    </>
  )
}

export default InsercaoDadosRecebedor