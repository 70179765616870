import React from 'react';


import logo from '../Components/Menu/img/topLogo.png'



const PdfTransferencia = ({Data}) => {

  return (
    <div className="Comprovante grid"> 
      <section className="headerComprovante grid">
        <img className="LogoComprovante BorderBox" src={logo} alt="Expressas Dourados"/>
        <h2 className="grid">Controle de Transferência</h2>
      </section>      
      <section className="bodyComprovante data-trip-transferencia grid">
        <h2 className="colorBlack">Trip: {Data.ID}</h2>
        <h2 className="colorBlack">Conferente: {Data.Conferente}</h2>
        <h2 className="colorBlack">Courier: {Data.Courier}</h2>
        <h2 className="colorBlack">Quantidade: {Data.Quantidade}</h2>
        <div className={Data.Remessas.length > 1 ? `grid columns-2` : `grid column-1`}>
        {Data.Remessas.map( item => 
          <section className="remessasComprovante container-rms-transferencia grid">
            <span>Cliente: {item.Cliente}</span>
            <span>Quantidade: {item.Quantidade} Volumes</span>
            <span>Conferido: ______ Volumes</span>
          </section>
        )}
        </div>
        {Data.Logins.length > 0 && <section className="remessasComprovante logins-transferencia grid">
          <span style={{fontSize: "20pt"}}>Logins:</span>
          {Data.Logins.map( item =>
            <>
              <span>Cliente: {item.cliente}</span>
              <span>App: {item.app}</span>
              <span>Login: {item.login}</span>
              <span>Senha: {item.senha}</span>
            </>
            )}  
        </section>}
        <br/>
        <span style={{fontSize: "18pt"}}>Data: {Data.Data.dia}/{Data.Data.mes}/{Data.Data.ano} </span>
        <span style={{fontSize: "18pt"}}>Horas: {Data.Data.hora} : {Data.Data.minutos}</span>
        <br />
        <p>Assinatura: _____________________________  </p>
        <br/>
        <p>RG: ____________ </p>
        <br/>
    </section>
      
    <section className="controleComprovante grid">  
      <br/>
      <p>Assinatura Transportador: _____________________</p>
    </section>  
    </div>
  )
}

export default PdfTransferencia