import React from 'react';


import logo from '../Components/Menu/img/topLogo.png'



const GerandoPdf = ({Data}) => {

  return (
    <div className="Comprovante grid"> 
      <section className="headerComprovante grid">
        <img className="LogoComprovante BorderBox" src={logo} alt="Expressas Dourados"/>
        <h2 className="grid">Comprovante de <br/> Recebimento de Cargas</h2>
      </section>   
    <section className="bodyComprovante grid" style={{marginTop: "160px"}}>
      <h2  className="colorBlack">Cliente: {Data.client}</h2>
      <br/>
      {Data && <p style={{fontSize: '22pt', color: `crimson`, margin: "10px", fontStyle: 'italic', fontWeight: '600'}}> Confirmo receber {Data.sacas && `${Data.sacas} Sacas`} {Data.sacas && Data.soltos && 'e'} {Data.soltos && `${Data.soltos} volumes soltos.`}</p>}
      <br/>
      <p>Data: {Data.data.dia}/{Data.data.mes}/{Data.data.ano} <br/> Horas: {Data.data.hora}:{Data.data.minutos}</p>
      <br />
      <p>Assinatura: _____________________________</p>
      <br/>
      <p>RG: ____________</p>
      <br/>
    </section>  
    <section className="controleComprovante grid">  
      <p>Emitido por: {Data.user}</p>
    </section>  
    </div>
  )
}

export default GerandoPdf