import React, { useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import api from '../../services/api'

import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import BuscaToken from '../Components/Functions/BuscaToken.js'
import Loading from '../Components/Loading'


const CreateClientesLogins = () => {
  const [Clientes, setClientes] = useState()
  const [SelectedCliente, setSelectedCliente] = useState()
  const [Login, setLogin] = useState()
  const [Nome, setNome] = useState()
  const [Senha, setSenha] = useState()
  const [Placa, setPlaca] = useState()
  const [LoadingTime, setLoadingTime] = useState()

  useEffect(() => {
    api.get('/clients', BuscaToken()).then( r => {
      const clientes = r.data.clients.filter( c => c.useapp === "1")
      setClientes(clientes)
    })
  }, [])


  const Finish = async (event) => {
    event.preventDefault()
    setLoadingTime(1)
    const data = {
      cliente: SelectedCliente,
      nome: Nome,
      login: Login,
      senha: Senha,
      placa: Placa,
      app: (Clientes.find( c => c.name === SelectedCliente)).app
    }

    const ServerResponse = await api.post('/logins/create', data, BuscaToken())
    setLoadingTime()
    alert(JSON.stringify(ServerResponse.data.message))
    window.location.reload()
  }


  return (
    <>
      <Menu />
      <div className="container container-Logins grid">
        <h2>Criacao de Logins</h2>
        <form>
          <select name="cliente" id="cliente" onChange={(event) => event.target.value.length > 0 ? setSelectedCliente(event.target.value) : 
            (function () {
              setSelectedCliente()
              setLogin()
              setSenha()
              setPlaca()
              setNome()
              document.querySelector('#login').value = ""
              document.querySelector('#senha').value = ""
              document.querySelector('#placa').value = ""
              document.querySelector('#nome').value = ""
            })()}>
            <option value="">Selecionar Cliente</option>
            {Clientes && Clientes.map( cliente => 
              <option key={cliente.id} value={cliente.name}>{cliente.name}</option>
              )}
          </select>
          {SelectedCliente && 
            <label htmlFor="App" style={{fontStyle: "italic", fontSize: "17pt"}}>Aplicativo: {(Clientes.find( c => c.name === SelectedCliente)).app}</label>
          }
          {SelectedCliente && <div>
            <label htmlFor="nome">Nome:</label>
            <input type="text" id="nome" onChange={(event) => setNome(event.target.value)}/>
            <label htmlFor="login">Login:</label>
            <input type="text" id="login" onChange={(event) => setLogin(event.target.value)}/>
            <label htmlFor="senha">Senha</label>
            <input type="text" id="senha" onChange={(event) => setSenha(event.target.value)}/>
            <label htmlFor="placa" >Placa</label>
            <input type="text" maxLength="7"  id="placa" onChange={(event) => setPlaca(event.target.value)}/>
            </div>}
          {(Login && Senha && Placa) && (Login.length > 0 && Senha.length > 0 && Placa.length > 0) && <button className="btn-M"
          onClick={Finish}>Criar</button>}
        </form>
      </div>   
      {LoadingTime && <Loading />}
      <Footer />
    </>
  )
}

export default CreateClientesLogins