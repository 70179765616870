import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'

import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'

import api from '../../services/api'
import BuscaToken from '../Components/Functions/BuscaToken.js'



const IndexBaixaCliente = () => {
  const [Remessas, setRemessas] = useState()
  const [AllRemessas, setAllRemessas] = useState()
  const [Clientes, setClientes] = useState()

  useEffect(() => {
    api.get('/baixacliente/index', BuscaToken())
        .then( r => {
          setRemessas(r.data.remessas)
          setAllRemessas(r.data.remessas)
          setClientes(r.data.clientes)
        })
  }, [])

  async function ConfirmaBaixa (remessa){
    const ResponseServer = await api.get(`/baixacliente/baixa/${remessa}`, BuscaToken())
    if(ResponseServer.data === 0){
      alert('Erro ao baixar remessa.')
      return
    }
    const remessasSerialized = Remessas.filter(item => item.rm !== remessa)
    setRemessas(remessasSerialized)
  }

  const filtro = (event) => {
    if(event.target.value.length > 0){
      const RemessasFiltradas = AllRemessas.filter( remessa =>  remessa.client === event.target.value)
      setRemessas(RemessasFiltradas)
    }
    else {
      setRemessas(AllRemessas)
    }
  }


  return(
    <>
      <Menu />
      <div className="grid container">
        <h2>Remessas aguardando baixas App Cliente</h2>
        <section>
          <label htmlFor="filtro" style={{display: "inline"}}>Filtro por Clientes </label>
          <select name="clientes" id="clientes" style={{display: 'inline', fontSize: "15pt", backgroundColor: "coral"}} onChange={(event) => filtro(event)}>
            <option value="">Todos</option>
            {Clientes && Clientes.map( cliente => <option value={cliente} key={cliente}>{cliente}</option>)}
          </select>
          <h3>Total em exibição: {Remessas && Remessas.length}</h3>
        </section>
        {Remessas &&
          <table className="tableBaixaCliente">
            <thead>
              <tr>
                <th>Data/Hora Entrega</th>
                <th>Cliente</th>
                <th>Remessa</th>
                <th>Destinatario</th>
                <th>Recebedor</th>
                <th>Documento</th>
                <th>Parentesco</th>
                <th>Confirmação</th>
              </tr>
            </thead>
            <tbody>
              {Remessas.map( item => 
                <tr key={item.rm}>
                  <td>{item.recebedor_finished_at_json && `${item.recebedor_finished_at_json.dia}/${item.recebedor_finished_at_json.mes}/${item.recebedor_finished_at_json.ano}  ${item.recebedor_finished_at_json.hora}:${item.recebedor_finished_at_json.minutos}`}</td>
                  <td>{item.client}</td>
                  <td><Link to={`/search/remessa/${item.rm}`}>{item.rm}</Link></td>
                  <td>{item.recebedor_destinatario}</td>
                  <td>{item.recebedor_name}</td>
                  <td>{item.recebedor_document}</td>
                  <td>{item.recebedor_grau}</td>
                  <td><button className="btnCBaixaC btn-P" onClick={() => ConfirmaBaixa(item.rm)}>Confirmar</button></td>
                </tr>
                )}
            </tbody>
          </table>
        }
      </div>
      <Footer />
    </>
  )
}

export default IndexBaixaCliente