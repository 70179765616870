import React, { useEffect, useState} from 'react'

import api from '../../services/api'

import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import BuscaToken from '../Components/Functions/BuscaToken.js'
import Table from './table.jsx'
import TableAll from './tableAll.jsx'

const ContasPerUser = () => {
  const [Users, setUsers] = useState([])
  const [Autor, setAutor] = useState()
  const [SelectedUser, setSelectedUser] = useState()
  const [Ano, setAno] = useState([])
  const [SelectQ, setSelectQ] = useState()
  const [SelectedYear, setSelectedYear] = useState()
  const [SelectedMonth, setSelectedMonth] = useState()
  const [SelectedQuinzena, setSelectedQuinzena] = useState()
  const [DataServer, setDataServer] = useState()
  const [Data, setData] = useState()
  const [DataAll, setDataAll] = useState()
  const config = BuscaToken()

  const Months = [{mes: 'Janeiro', value: "01"},{mes: 'Fevereiro', value: "02"},{mes: 'Marco', value: "03"},{mes: 'Abril', value: "04"},{mes: 'Maio', value: "05"},{mes: 'Junho', value: "06"},{mes: 'Julho', value: "07"},{mes: 'Agosto', value: "08"},{mes: 'Setembro', value: "09"},{mes: 'Outubro', value: "10"},{mes: 'Novembro', value: "11"},{mes: 'Dezembro', value: "12"}]
    
  const Quinzenas = [{Quinzena: '1°', values: {i: '01', f: '15'}},{Quinzena: '2°', values: {i: '16', f: '31'}}]


  useEffect(() => {
    api.get('/users/index', config).then(r => {
      setUsers(r.data)
    })

    api.get('/users/show', config)
    .then( r => {
      if(!r.data.message){
        setAutor(r.data)
      }
    })

    let DataAtual = null

      api.get('/HoraServer/timestamp', config).then( r => {
        const data = r.data.split('')
        DataAtual = {
          ano: `${data[0]}${data[1]}${data[2]}${data[3]}`,
          mes: `${data[4]}${data[5]}`,
          dia: `${data[6]}${data[7]}`
        }
        setDataServer(DataAtual)
        const anoAtual = Number(DataAtual.ano)
        setAno([anoAtual - 1, anoAtual])
      })
  }, [])

  function addUser (event) {
    if(event.target.value.length > 0)
      setSelectedUser(event.target.value)
    else 
      setSelectedUser()  
  }

  const Qatual = () => {
    setSelectQ(0)
    setSelectedYear(Number(DataServer.ano))
    setSelectedMonth(DataServer.mes)
    if(Number(DataServer.dia) <= 15)
      setSelectedQuinzena(Quinzenas[0].values)
    else if(Number(DataServer.dia) >= 15)
      setSelectedQuinzena(Quinzenas[1].values)
  }
  const LiberaSelect = () => {
    setSelectQ(1)
  }

  const SelectYear = (event) => {
    setSelectedYear(event.target.value)
  }
  const SelectMonth = (event) => {
    setSelectedMonth(event.target.value)
  }
  const SelectQuinzena = (event) => {
    setSelectedQuinzena(Quinzenas[event.target.value].values)
    
  }

  async function Buscar () {
    const data = {
      periodo: {
        Quinzena:SelectedQuinzena,
        year: SelectedYear,
        month: SelectedMonth,
      },
      user: SelectedUser
    }

    const Relatorio = await api.post('/contas/extratoPerUser', data, config)
    


    if(!Relatorio.data.total)
      return setData(Relatorio.data)
    setDataAll(Relatorio.data)
  }


  return(
    <>
      <Menu />
      <div className="container contentCenter grid">
      <h2>Contas</h2>
      <span className="span-M">Contas - Consulta por User</span>
      {!Data && <div> 
      <form>
        <select name="User" id="User" onChange={addUser}>
          <option value="">Selecionar Usuario</option>
          <option value="all">Todos Usuarios</option>
          {Users.map(user => (
            <option value={user.id} key={user.id}>{user.name}</option>
          ))}
        </select>
        <section className={`${SelectedUser ? 'ativo' : 'inativo'} `}>
            <input className="inputProduction" type="radio"  name="periodo" value="QuinzenaAtual" onClick={Qatual}/>
            <label htmlFor="periodo" style={{display: "inline"}}> Quinzena Atual</label><br/>
            <input className="inputProduction" type="radio"  name="periodo" value="SelecionarQuinzena" onClick={LiberaSelect}/> 
            <label htmlFor="periodo" style={{display: "inline"}}> Selecionar Quinzena</label><br/>
          </section>
          <section className={SelectQ ? 'ativo' : 'inativo'}>
            <p>Selecione o Ano:</p>
            <select id="Ano" onChange={SelectYear}>
              <option value="">Ano</option>
              {Ano.map((ano) => {
                return <option key={ano} value={ano}>{ano}</option>
              })}
            </select>
            <section className={SelectedYear ? 'ativo' : 'inativo'}>
              <p>Selecione o Mes:</p>
              <select  id="Mes" onChange={SelectMonth} >
                <option value="">Mes</option>
                {Months.map((mes) => {
                  return <option key={mes.mes} value={mes.value}>{mes.mes}</option>
                })}
              </select>
            </section>
            <section className={SelectedMonth ? 'ativo' : 'inativo'}>
              <p>Selecione a Quinzena:</p>
              <select  id="Quinzena" onChange={SelectQuinzena} >
                <option value="">Selecionar Quinzena</option>
                <option value="0">1° Quinzena</option>
                <option value="1">2° Quinzena</option>
              </select>
            </section>
          </section>
      </form>
      {SelectedQuinzena && <button className="btn-M" onClick={Buscar}> Buscar </button>}          
      </div>}
      {Data && <Table Data={Data}/>}
      {DataAll && <TableAll Data={DataAll}/>}
      </div>
      <Footer />
    </>
  )
}

export default ContasPerUser