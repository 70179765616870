import React, { useEffect, useState, useCallback} from 'react'
import api from '../../services/api'
import { useHistory } from 'react-router-dom'
import { debounce } from 'lodash'

import duplicity from '../Medias/duplicity.wav'
import ModalError from './Modal.jsx'
import erro from '../Medias/Erro.wav'
import Sucess from '../Medias/Sucess.wav'
import LimiteSound from '../Medias/limite.wav'
import { AiFillCopy } from 'react-icons/ai'

import BuscaToken from '../Components/Functions/BuscaToken.js'
import CheckStatusRms from '../Components/Functions/CheckStatusRms.js'

import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import Loading from '../Components/Loading'

const LastMile = () => {
    const [ShowModalError, setShowModalError] = useState()
    const [Couriers, setCouriers] = useState()
    const [Type,setType] = useState()
    const [Txt,setTxt] = useState()
    const [SelectedClient, setSelectedClient] = useState()
    const [Courier, setCourier] = useState()
    const [RMS, setRMS] = useState([])
    const [QtdBipada, setQtdBipada] = useState()
    const [ClienteLista, setClienteLista] = useState()
    const [ClienteListaValue, setClienteListaValue] = useState('')
    const [User, setUser] = useState()
    const [LoadingTime, setLoadingTime] = useState()
    const [IdlesCouriers, setIdlesCouriers] = useState([])
    const [ShowModal, setShowModal] = useState()
    const [TripObservacao, setTripObservacao] = useState()
    const [Finished, setFinished] = useState()
    const [Logins, setLogins] = useState({})
    const [Clients, setClients] = useState()
    const [SelectedLogin, setSelectedLogin] = useState()
    const [TotalRms, setTotalRms] = useState(0)
    const [UnitRemessas, setUnitRemessas] = useState([])
    const [ConfigBipagem, setConfigBipagem] = useState()
    const [StatusUnitRm, setStatusUnitRm] = useState()
    const [CheckBUnitBg, setCheckBUnitBg] = useState()
    const [Cenzao, setCenzao] = useState()
    const [Zerao, setZerao] = useState()
    const [SoundSucess, setSoundSucess] = useState()
    const [SoundErro, setSoundErro] = useState()
    const [SoundDuplicity, setSoundDuplicity] = useState()
    const [finishButton, setFinishButton] = useState(true)

    if(!SoundSucess){
      setSoundSucess(new Audio(Sucess))
    } 
    if(!SoundErro){
      setSoundErro(new Audio(erro))
    }
    if(!SoundDuplicity){
      setSoundDuplicity(new Audio(duplicity))
    }

    const history = useHistory()

    const config = BuscaToken()

    class addCliente{
      create (cliente,type,rms,lista,RmsSave, SelectedLogin){
      const viagem = {
        cliente,
        type,
        quantidade: rms.length,
        rms,
        lista,
        RmsSave,
        Login: SelectedLogin
      }
      return viagem
      }
    }
    const addcliente = new addCliente()

    useEffect(() => {
      api.get('/users/show', config)
        .then( r => {
          if(!r.data.message){
            setUser(r.data)
          }
        })

        api.get('/couriers', config).then(response => {
          const c = response.data
          
          const couriers = c.sort(function compare (a,b) {
            if(a.name < b.name) return -1
            if(a.name > b.name) return 1
            return 0
          })
        setCouriers(couriers)
        
    })  

    api.get('/couriers/idle', config).then(r => setIdlesCouriers(r.data))

    api.get('/clients/actives', config).then(response => {
      setClients(response.data.clients)
    })

  }, [])

  

  useEffect(() => {
    setTxt('')
    if(RMS.length > 0)
    localStorage.setItem('RMS', JSON.stringify(RMS))
  }, [RMS])



  const DefaultBG = useCallback(
      debounce(function(){
      setCheckBUnitBg()
      setStatusUnitRm()
  }, 5000),[])

  async function CheckUnitRemessa (remessa) {
    setLoadingTime(1)
    const data = {
      remessa: remessa.trim(),
      courier: Courier,
      client: SelectedClient
    }
    const ResponseServer = await api.post(`/trips/check/rm`, data, BuscaToken())
    setStatusUnitRm(ResponseServer.data.message)
    setLoadingTime()
    document.querySelector('#InputRemessa').value = ''
    let RemessasBipadas = []
    if(RMS.length > 0){
      let remessas = RMS.map( item => item.rms)
      remessas = [].concat(...remessas)
      RemessasBipadas = remessas
    }
    if(!ResponseServer.data.auth){
      setCheckBUnitBg('BGred')
      await SoundErro.play() 
      DefaultBG()
      return false 
    }
    setCheckBUnitBg('BGgreen')
    if((RemessasBipadas.concat(UnitRemessas)).indexOf(remessa) !== -1){
      await SoundDuplicity.play()
      DefaultBG()
      setStatusUnitRm('Remessa ja bipada!')
      return false
    }
    setUnitRemessas([...UnitRemessas, remessa])
    setQtdBipada(UnitRemessas.length + 1)
    DefaultBG()
      await SoundSucess.play()
  }

    async function postTrip(newTrip) {
    setLoadingTime(1)
    const message = await api.post('trips', newTrip, config)
    if(message.data.erro){
      alert(JSON.stringify(message.data.message))
      setFinishButton(true)
      setLoadingTime()
      return 
    }
    localStorage.removeItem('RMS')
    setLoadingTime()
    setFinished()
    alert(JSON.stringify(message.data.message))
    history.push('/home')
    }

  

  function addCourier (event) {
    if(event.target.value.length > 0){
      setShowModal()
      const authorization = IdlesCouriers.filter(courier => Number(event.target.value) === courier.id)
      if(authorization.length > 0){
        setCourier(event.target.value)
        if(localStorage.getItem('RMS')){
          setRMS(JSON.parse(localStorage.getItem('RMS')))
          const RmsArrays = (JSON.parse(localStorage.getItem('RMS')).map(item => item.rms))
          setTotalRms( [].concat(...RmsArrays).length)
        }
      }
      else{
        const observacao = prompt('Por gentileza descrever o motivo de abrir nova viagem, courier ja possui viagem pendente')
        if(!observacao || observacao.length < 2){
          setSelectedClient()
          setCourier()
          setType()
          return alert('Observacao nao inserida, criacao abortada!')
        } 
        setTripObservacao(observacao)
        setCourier(event.target.value)
        if(localStorage.getItem('RMS'))
        setRMS(JSON.parse(localStorage.getItem('RMS')))
      }
    }
    else
      setCourier() 
  } 

    async function typeRM (event) {
      if(event.target.value.length === 0){
        setType(event.target.value)
        setClienteLista()
        return 
      }
      setType(event.target.value)
      setLoadingTime(1)
      const lista = await api.get(`/clientlista/${SelectedClient}`, config)
      setLoadingTime()
      if(+lista.data.lista){
        let lista = RMS.filter( item => item.cliente === SelectedClient)
        if(lista.length > 0){
          lista = lista[0].lista
          setClienteListaValue(lista)
          setClienteLista(1)
          return true
        }
        setClienteLista(1)
        setClienteListaValue()
      }
      else
        setClienteLista() 
        setClienteListaValue() 
    }

    async function SetandoCliente (event){
      if(event.target.value.length > 0){
        setTxt()
        setUnitRemessas([])
        setConfigBipagem()
        setQtdBipada()
        setSelectedClient(event.target.value)
        setType()
        setClienteLista()
        if(['Carvalima', 'Chips', 'Transfolha'].indexOf(event.target.value) === -1)
          setConfigBipagem(1)
        const cliente = Clients.filter(item => item.name === event.target.value).map(item => item.useapp)
        if(cliente[0] === "1"){
          if((RMS.filter( rms => rms.cliente === event.target.value)).length === 0){
            setSelectedLogin()
            setLoadingTime(1)
            const logins = await api.get(`/logins/allows/${event.target.value}`, config)
            setLogins(logins.data.sort(function compare (a,b) {
              if(a.nome < b.nome) return -1
              if(a.nome > b.nome) return 1
              return 0
            }))
            setLoadingTime()
            if(document.querySelector('#type'))
              document.querySelector('#type').value = ''
          }
          else{
            const login = RMS.filter( rms => rms.cliente === event.target.value)
            setSelectedLogin(login[0].Login)
            setLogins()
            if(document.querySelector('#type'))
              document.querySelector('#type').value = ''
          }
        }
        else{
          setSelectedLogin()
          setLogins()
          if(document.querySelector('#type'))
            document.querySelector('#type').value = ''
        }
      }
      else {
        setSelectedClient()
        setSelectedLogin()
        setLogins()
      }
   }

  function editTxt(event){
    setTxt(event.target.value)
  }

  function add001 () {
    if(!Cenzao){
      let txtarea = Txt.split('\n')
      txtarea = txtarea.filter(remessa => remessa.length > 1) 
      const txtarea001 = txtarea.map(remessa => `${remessa}001`)
      const txtareaSerialized = txtarea001.toString().replace(/,/g, '\n')
      setTxt(txtareaSerialized)
      setCenzao(1)
    }
  }

  function add0 () {
    if(!Zerao){
      let txtarea = Txt.split('\n')
      txtarea = txtarea.filter(remessa => remessa.length > 1) 
      const txtarea001 = txtarea.map(remessa => `0${remessa}`)
      const txtareaSerialized = txtarea001.toString().replace(/,/g, '\n')
      setTxt(txtareaSerialized)
      setZerao(1)  
    }
  }

  async function qtdBipada (event) {
    if(event.key === 'Enter'){
      let txt = Txt.toUpperCase().split('\n')
      txt = txt.filter( item => item.length > 0).map(item => item.trim())
      setQtdBipada(txt.length)
      let remessas = txt
      let RemessasRms
      if(RMS){
        const rms = RMS.map(item => item.rms)
        RemessasRms = [].concat(...rms)
      }
      const textserialized = txt.filter( (item, index) => txt.indexOf(item) === index
                            && RemessasRms.indexOf(item) === -1)
      
      if(textserialized.length !== txt.length){
        await SoundDuplicity.play()
        let NewTxtSerialized = textserialized.toString().replace(/,/g, '\n')
            NewTxtSerialized += '\n'
        setTxt(NewTxtSerialized)
        setQtdBipada(textserialized.length)     
      }

      

      /*
      txt.map(async (item,index) => {
        if(txt.indexOf(item) !== index || RemessasRms.indexOf(item) !== -1) {
          const NewTxt = txt.filter((item,i) => {
            if(i !== index)
              return item
          })
          const soundError = new Audio(duplicity)
          await soundError.play()
          const stringNewTxt = NewTxt.toString()
          let NewTxtSerialized = stringNewTxt.replace(/,/g, '\n')
              NewTxtSerialized += '\n'
          remessas = NewTxtSerialized.split('\n').filter(item => item.length > 0)    
          setTxt(NewTxtSerialized)
          setQtdBipada(NewTxt.length)  
        } 
      })
      */
        /*
        if(TotalRms + remessas.length > 70){
          const soundError = new Audio(LimiteSound)
          await soundError.play()
          alert('Limite de 70 remessas atingido!')
          const NewTxt = remessas.filter((item, index) => index !== remessas.length - 1 )
          const stringNewTxt = NewTxt.toString()
          let NewTxtSerialized = stringNewTxt.replace(/,/g, '\n')
              NewTxtSerialized += '\n'
          setTxt(NewTxtSerialized)  
          setQtdBipada(NewTxt.length)     
        }
      */
    }
  }

  async function addTrip () {
    if(QtdBipada < 1) {
      alert('Nenhuma remessa bipada!')
      return 
    }
    if(ClienteLista){
      if(document.getElementById('spanLista').value.length > 0){
        var lista = document.getElementById('spanLista').value
      }
      else{
        alert('Favor inserir numero da Lista!')
        document.getElementById('spanLista').focus()
        return
      }  
    }

    
    async function addNewViagem() {
      let remessas
      if(ConfigBipagem === 2){
        let rms = Txt.toUpperCase().replace(/\$/g, '')
        rms = rms.split('\n').map( rm => rm.trim())
        let RemessasBipadas = []
        if(RMS.length > 0){
          let remessas = RMS.map( item => item.rms)
          remessas = [].concat(...remessas)
          RemessasBipadas = remessas
        }
        rms = rms.filter( (item, index) => rms.indexOf(item) === index)
        rms = rms.filter(remessa => RemessasBipadas.indexOf(remessa) === -1)
        setLoadingTime(1)
         remessas = await CheckStatusRms(rms.filter((item) => item.length > 2),SelectedClient)
        setLoadingTime()
        if(remessas.barradas.length > 0){
          setShowModalError(remessas.barradas)
        }
        remessas = remessas.autorizadas
      }
      else {
        remessas = UnitRemessas
      }
      

      setTotalRms(TotalRms + remessas.length)
    
      const RmsSave = remessas.map((remessa) => {
        return {
          remessa,
          Type,
          cliente: SelectedClient,
          lista
        }
      })
      
      const newViagem = addcliente.create(SelectedClient,Type,remessas,lista,RmsSave, SelectedLogin)
      return newViagem
    }
    const newViagem = await addNewViagem()
    let contain = false
    RMS.map((item) => {
      if(item.cliente === newViagem.cliente && item.type === newViagem.type && item.lista === newViagem.lista)
        contain = true
      else if(!newViagem.lista && item.cliente === newViagem.cliente && item.type === newViagem.type)
        contain = true
    })
      
    if(contain) {
        const newRMS = RMS.map((item) => {
        if(item.lista === newViagem.lista && item.cliente === newViagem.cliente && item.type === newViagem.type ){
          let OriginItem = item
          newViagem.rms.map((item) => {OriginItem.rms.push(item)})
          OriginItem.quantidade = OriginItem.rms.length
          newViagem.RmsSave.map((item) => {OriginItem.RmsSave.push(item)})
          return OriginItem
        } 
        else if(!newViagem.lista && item.cliente === newViagem.cliente && item.type === newViagem.type ){
          let OriginItem = item
          newViagem.rms.map((item) => {OriginItem.rms.push(item)})
          OriginItem.quantidade = OriginItem.rms.length
          newViagem.RmsSave.map((item) => {OriginItem.RmsSave.push(item)})
          return OriginItem
        } 
      else 
        return item 
      })
      setRMS(newRMS)
    }
    else {
      if(newViagem.rms.length > 0)
        setRMS([...RMS, newViagem])
    }
    setSelectedLogin()
    if(document.querySelector('#Clients'))
      document.querySelector('#Clients').value = ''
    setSelectedClient()
    setType()
    setClienteLista()
    setQtdBipada()
    setUnitRemessas([])
    setConfigBipagem()
    setSelectedLogin()
    setLogins()
  }

  function removeRMS () {
    setRMS([])
    localStorage.removeItem('RMS')
    setTotalRms(0)
  }


  async function FinishTrip() {
    if(!Finished){
      const RmsSave = []
      RMS.map((item) => {
        item.RmsSave.map((item) => {
          RmsSave.push(item)
        })
      })
      const newTrip = {courier: Courier,
        agente: User.name,
        observacao: TripObservacao,
        clients_rms: [...RMS],
        RmsSave
      }
      await postTrip(newTrip)
    }
    else {
      alert('Viagem ja foi criada, gentileza verificar HOME.')
      history.push('/')
    }
  }

 const SetandoLogin = async (event) => {
   if(event.target.value.length > 0){
    setLoadingTime(1)
    const login = await api.get(`/logins/show/${event.target.value}`, config)
    setSelectedLogin(login.data)
    setLogins()
    setLoadingTime()
  } 
 }

  return (
    <>
      <Menu />
      <div className={`container grid ${CheckBUnitBg ? CheckBUnitBg : ''}`}>
        <h2>Last Mile</h2>
        <div className="LastMile grid">
        {Couriers &&
          <select name="Courier" id="Courier" onChange={addCourier} className="select">  
          <option value="" className="option">Entregador</option>     
          {Couriers.map(courier => (
            <option key={courier.id} value={courier.id}  className="option">{courier.name}</option>
          ))}
        </select>}
        {Courier && <select name="Clients" id="Clients" onChange={SetandoCliente} className="select">       
          <option value='' >Cliente</option>
          {Clients.map(Clients => (
          <option key={Clients.id} value={Clients.name} >{Clients.name}</option>
          ))}
        </select>}
        {SelectedLogin && <span className="span-M" >Login: {SelectedLogin.nome} </span>}
        {SelectedLogin && <span className="span-M">CPF: {SelectedLogin.login}</span>}
        {SelectedLogin && <span className="span-M"> Placa: {SelectedLogin.placa} </span>}
        {(!Logins && SelectedClient ) && <select name="type" id="type" onChange={typeRM}>
          <option value='' className="option">Tipo Remessa</option>
          <option value="G" className="option">Grande</option>
          <option value="P" className="option">Pequena</option>
        </select>}
        {Logins && Logins.length > 0 &&  <select name="logins" id="logins" onChange={(event) => SetandoLogin(event)}>
          <option value="" key="0">Selecionar Login</option>
          {Logins.map( login => <option key={login.id} value={login.id}> {login.nome} </option>)}
          </select>}
        {Type && !ConfigBipagem && <div className="TipoBipagem grid">
          <button className="grid" onClick={() => setConfigBipagem(1)}>Bipagem Unitario</button>
          <button className="grid" onClick={() => setConfigBipagem(2)}>Bipagem Lote</button>
        </div>}    
        {(SelectedClient === 'Transfolha' && Txt && !Cenzao) && <button className="btn-P" style={{marginBottom: '15px'}} onClick={add001}> Add 001 ao final das remessas</button>}
        {(SelectedClient === 'Transfolha' && Txt && !Zerao) && <button className="btn-P" style={{marginBottom: '15px'}} onClick={add0}> Add 0 ao inicio das remessas</button>}
        {QtdBipada && <span className="span-M">Quantidade Bipada : {QtdBipada}</span>}
        {StatusUnitRm && <span>{StatusUnitRm}</span>}
        {Type && ConfigBipagem === 2 && <textarea className="grid" name="textarea" id="textarea" value={Txt} onChange={editTxt} onKeyPress={qtdBipada}></textarea>}
        {Type && ConfigBipagem === 1 && <input id="InputRemessa" style={{justifySelf: 'center'}} placeholder="Entre com a Remessa"
         onKeyPress={(event) => event.key === 'Enter' && event.target.value.length > 0 && CheckUnitRemessa(event.target.value.trim().toUpperCase())} />}
        {ClienteLista && ConfigBipagem && <p id="clienteLista">
          Lista: <input type="text" id="spanLista" value={ClienteListaValue} onChange={(event) => setClienteListaValue(event.target.value)}  />
        </p>}
      </div>
      {Type && <button className="btn-G grid" onClick={addTrip} >Adicionar Remessas</button>}
      {RMS && RMS.length > 0 && !QtdBipada && 
        <button 
        className="btn-G grid" 
        disabled={!finishButton}
        onClick={() => {
          setFinishButton(false)
          FinishTrip()
        }}
        >
          Finalizar Trip
        </button>
        }
      
      {RMS.length > 0 && <button onClick={removeRMS} className="btn-P" style={{marginTop: "20px"}}>Limpar remessas bipadas.</button>}
      <div className="box-rms-boxTrip grid">
        {RMS.map((item) => {
                if(item.rms.length > 0)
                return (
                  <div key={`${item.cliente}${item.type}`} className="div-rms-boxTrip grid">
                    <p>Cliente: {item.cliente} <button className="btn-Copy" onClick={() => navigator.clipboard.writeText(document.querySelector(`.ul${item.cliente.replace(' ', '-')}${item.type}`).innerText)}><AiFillCopy/></button></p>
                    <p>Quantidade: {item.quantidade}</p>
                    <p>Type: {item.type}</p>
                    {item.Login && <p>Login: {item.Login.nome} </p>}
                    {item.Login && <p>Placa: {item.Login.placa}</p>}
                    {item.lista && <p>Lista: {item.lista} </p>}
                    <br/>
                    <ul key={`${item.cliente}${item.type}`} className={`ul${(item.cliente).replace(' ', '-')}${item.type}`}>
                      {item.rms.map((item) => {
                        return <li className="liEmRota" key={item}>{item}</li>
                      })}
                    </ul>
                  </div>
                )
        })}
      </div>        
              
    </div>
      {LoadingTime && <Loading />}
      {ShowModalError && <ModalError message={ShowModalError} setShowModalError={setShowModalError}/>}
      <Footer />
    </>
  )
}

export default LastMile


