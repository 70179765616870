import React, {useState, useEffect} from 'react'
import api from '../../../services/api'
import BuscaToken from '../Functions/BuscaToken.js'

const MetricasCourier = ({user}) => {
  const [Relatorio, setRelatorio] = useState([])
  const [Meta, setMeta] = useState(0)
  const [Periodo, setPeriodo] = useState()
  const config = BuscaToken()
      
    
    useEffect(() => {
      const data = {
        courier_id: user.courier_id,
        year: user.year,
        month: user.month,
        Quinzena: user.Quinzena
      }

      api.post('/production/courier', data, config)
      .then(r =>{
        setRelatorio(r.data.resumido)
        setPeriodo(r.data.Periodo)
      })

      api.get(`/courier/${user.courier_id}`, config).then( r => {
        setMeta(r.data.meta)
      })
    }, [])


  return (
    <div className={`${user.permissao !== 'C' ? "extratoSupport grid" : "extratoCourier"}`}>
      <div className="ResumoProducao BorderBox">
        <p>Total entregas: <span>{Relatorio.Total}</span></p>
        <p>Entregas Pequenas: <span>{Relatorio.Ps}</span></p>
        <p>Entregas Grandes: <span>{Relatorio.Gs}</span></p>
        {Meta === 'true' && Relatorio.Total >= 500 ? <p><span>Meta Atingida!</span></p> :
        <p>{Periodo === 'Referencia: Quinzena Atual' ? 'Falta ' : 'Faltou ' }<span>{500 - Relatorio.Total}</span> entregas para atingir a meta.</p>}
      </div>
    </div>    
  )
}

export default MetricasCourier