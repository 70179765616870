import React, { useEffect, useState} from 'react'

import api from '../../services/api'

import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import BuscaToken from '../Components/Functions/BuscaToken.js'

const IndexOverflows = () => {
  const [Orders, setOrders] = useState()
  const [Permissao, setPermissao] = useState()

  const config = BuscaToken()

  useEffect(() => {
    api.get('/overflows/index', config).then( r => {
      if(!r.data.auth)
        return alert(r.data.message)
      setOrders(r.data.orders)  
    })
    api.get('/users/CheckPermission', config).then( r => setPermissao(r.data.Permissao))
  }, [])

  async function DeleteOrder (order) {
    const confirmation = prompt('Escrever CONFIRMAR, para prosseguir com a exclusao.')
    if(confirmation !== 'CONFIRMAR')
      return alert('Confirmacao incorreta, operacao abortada.')
    const OrderDeleted = await api.get(`/overflows/delete/${order}`, config)
    if(!OrderDeleted.data.auth)
      return alert(OrderDeleted.data.message)
    window.location.reload()
  } 

  return (
    <>
      <Menu />
      <div className="container contentCenter grid">
        <h2>Ordens de Transbordo</h2>

        <div className="contentCenter grid"> 
          <h3>Ultimas ordens</h3>
          <table className="boxProduction">
            <thead>
              <tr>
                <th>ID</th>
                <th>Data</th>
                <th>Autor</th>
                <th>Courier</th>
                <th>Trip</th>
                <th>Valor</th>
                {Permissao === 'A' && <th>Excluir</th>}
              </tr>
            </thead>
            <tbody>
              {Orders && Orders.map( item => {
                return (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.created_at_json.dia}/{item.created_at_json.mes}/{item.created_at_json.ano}</td>
                  <td>{item.autor}</td>
                  <td>{item.user}</td>
                  <td>{item.trip}</td>
                  <td>R$ {item.value},00</td>
                  {Permissao === 'A' && <th><button className="btn-P" onClick={event => DeleteOrder(item.id)}>Excluir</button></th>}
                </tr>
              )})}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default IndexOverflows