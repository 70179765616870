import { Route, BrowserRouter, Switch} from 'react-router-dom'
import React from 'react'

import LogIn from '../../LogIn'


const RoutesGuest = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={LogIn} path='/' exact/>
        <Route component={LogIn} path='/home' exact/>
        <Route component={LogIn} path='*'/>
      </Switch>
    </BrowserRouter>
  )
}

export default RoutesGuest

