
import api from '../../../services/api'
import BuscaToken from './BuscaToken.js'

const CheckPermissao = async () => {

  const config = BuscaToken()
    
  
    const data = await api.get('/users/CheckPermission', config)

        if(await data.data.Permissao)
          return data.data.Permissao
        else
          return 'G'  
    


}

export default CheckPermissao

