import React, { useState, useEffect} from 'react'
import api from '../../../services/api'
import { useHistory } from 'react-router-dom'

import Menu from '../../Components/Menu/Menu'
import Footer from '../../Components/Footer'
import EditOneUser from './EditOneUser.jsx'


const EditUsers = () => {
  const [Users, setUsers] = useState([])
  const [UserToEdit, setUserToEdit] = useState()
  const [ActiveEditUser, setActiveEditUser] = useState()
  const [DisableTable, setDisableTable] = useState(0)

  const history = useHistory()
  const token = localStorage.getItem('token')
      const config ={
        headers: {
          Authorization: token,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
  
  useEffect(() => {
    api.get('/users/index', config).then(r => {
      setUsers(r.data)
    })
  }, [])

  function Permissao (Sigla)  {
    const PERMISSAO = [{Sigla: 'A', Permissao: 'Admin'}, {Sigla: 'S', Permissao: 'Support'}, {Sigla: 'M', Permissao: 'Manager'}, {Sigla: 'C', Permissao: 'Courier'}]

    let permissao = null
    PERMISSAO.forEach((item) => {
      if(Sigla === item.Sigla)
        permissao = item.Permissao
    })
    return permissao
  }

  function EditUser (user) {
    setUserToEdit(user)
    setActiveEditUser(1)
    setDisableTable(1)
  }

  async function RemoveUser (user) {
    const confirmacao = prompt('Por favor confime a exclusao do user digitando, CONFIRMACAO.')
    if(confirmacao === 'CONFIRMACAO'){
      const data = {
        user
      }
      const userDeleted = await api.post('/users/delete', data, config)
      alert(JSON.stringify(userDeleted.data.message))
      history.push('/')

    }
    else
      alert('Confirmacao Incorreta, exclusao cancelada.')

  }


  return (
    <>
      <Menu />
      <div className='container contentCenter grid'>
        <h3>Painel Admin - Edit Users</h3>
        <table className={` ${DisableTable ? "inativo" : 'flex'} boxProduction`}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nome</th>
              <th>Login</th>
              <th>Documento</th>
              <th>Permissao</th>
              <th>Editar User</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>  
        {Users.map((User) => {
          if (User.permissao !== 'C'){
          return (
          <tr key={User.id}>
            <td>{User.id}</td>
            <td>{User.name}</td>
            <td>{User.login}</td>
            <td>{User.documento}</td>
            <td>{Permissao(User.permissao)}</td>
            <td><button onClick={() => EditUser(User.id)}>Editar User</button></td>
            <td><button onClick={() => RemoveUser(User.id)}>Deletar User</button></td>
          </tr>
        )}})}
        </tbody>
        </table>
        {ActiveEditUser && <EditOneUser user={UserToEdit}/>}
      </div>
      <Footer />
    </>
  )
}


export default EditUsers