import React, { useEffect, useState} from 'react'
import api from '../../../services/api'

import Menu from '../../Components/Menu/Menu'
import Loading from '../../Components/Loading'
import Footer from '../../Components/Footer'
import Table from './table.jsx'

const ProducaoClientes = () => {
  const [Clientes, setClientes] = useState([])
  const [Ano, setAno] = useState([])
  const [SelectQ, setSelectQ] = useState(0)
  const [SelectedYear, setSelectedYear] = useState()
  const [SelectedMonth, setSelectedMonth] = useState()
  const [SelectedQuinzena, setSelectedQuinzena] = useState(0)
  const [Relatorio, setRelatorio] = useState({})
  const [LoadingTime, setLoadingTime] = useState()
  const [Data, setData] = useState([])
  const [CallTable, setCallTable] = useState()
  const [DataServer, setDataServer] = useState()

  const token = localStorage.getItem('token')
      const config ={
        headers: {
          Authorization: token,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }

    const Months = [{mes: 'Janeiro', value: "01"},{mes: 'Fevereiro', value: "02"},{mes: 'Marco', value: "03"},{mes: 'Abril', value: "04"},{mes: 'Maio', value: "05"},{mes: 'Junho', value: "06"},{mes: 'Julho', value: "07"},{mes: 'Agosto', value: "08"},{mes: 'Setembro', value: "09"},{mes: 'Outubro', value: "10"},{mes: 'Novembro', value: "11"},{mes: 'Dezembro', value: "12"}]

    const Quinzenas = [{Quinzena: '1°', values: {i: '01', f: '15'}},{Quinzena: '2°', values: {i: '16', f: '31'}}]
    
      useEffect(() => {
        let DataAtual = null
        api.get('/HoraServer/timestamp', config).then( r => {
          const data = r.data.split('')
          DataAtual = {
            ano: `${data[0]}${data[1]}${data[2]}${data[3]}`,
            mes: `${data[4]}${data[5]}`,
            dia: `${data[6]}${data[7]}`
          }
          setDataServer(DataAtual)


        api.get('/clients', config).then(r => {
          setClientes(r.data.clients)
        })
        const anoAtual = Number(DataAtual.ano)
        setAno([anoAtual - 1, anoAtual])
        })
      }, [])

        const buscandoDados =  async () => {
            setLoadingTime(1)
              const data = {
                Clientes,
                year: Number(SelectedYear),
                month: SelectedMonth,
                Quinzena: SelectedQuinzena
              }

                const Relatorio = await api.post('/production/client', data, config)
                setData(Relatorio.data)
                setLoadingTime()
                setCallTable(1)
          }

          const Qatual = () => {
            setSelectQ(0)
            setSelectedYear(Number(DataServer.ano))
            setSelectedMonth(DataServer.mes)
            if(Number(DataServer.dia) <= 15)
              setSelectedQuinzena(Quinzenas[0].values)
            else if(Number(DataServer.dia) >= 15)
              setSelectedQuinzena(Quinzenas[1].values)
          }
          const LiberaSelect = () => {
            setSelectQ(1)
          }
      
          const SelectYear = (event) => {
            setSelectedYear(event.target.value)
          }
          const SelectMonth = (event) => {
            setSelectedMonth(event.target.value)
          }
          const SelectQuinzena = (event) => {
            setSelectedQuinzena(Quinzenas[event.target.value].values) 
          }


  return (
    <>
      <Menu />
      <div className="container grid">
        <h2>Producao de Clientes</h2>
        <div className={` ${ CallTable === 1 ? "inativo" : "ativo" } boxProduction`}>
          <section className="ativo">
              <input className="inputProduction" type="radio"  name="periodo" value="QuinzenaAtual" onClick={Qatual}/>
              <label htmlFor="periodo"> Quinzena Atual</label><br/>
              <input className="inputProduction" type="radio"  name="periodo" value="SelecionarQuinzena" onClick={LiberaSelect}/> 
              <label htmlFor="periodo"> Selecionar Quinzena</label><br/>
            </section>

            <section className={SelectQ ? 'ativo' : 'inativo'}>
              <p>Selecione o Ano:</p>
              <select id="Ano" onChange={SelectYear}>
                <option value="">Ano</option>
                {Ano.map((ano) => {
                  return <option key={ano} value={ano}>{ano}</option>
                })}
            </select>
              
            <section className={SelectedYear ? 'ativo' : 'inativo'}>
              <p>Selecione o Mes:</p>
              <select  id="Mes" onChange={SelectMonth} >
                <option value="">Mes</option>
                {Months.map((mes) => {
                  return <option key={mes.mes} value={mes.value}>{mes.mes}</option>
                })}
              </select>
            </section>

            <section className={SelectedMonth ? 'ativo' : 'inativo'}>
              <p>Selecione a Quinzena:</p>
              <select  id="Quinzena" onChange={SelectQuinzena} >
                <option value="">Selecionar Quinzena</option>
                <option value="0">1° Quinzena</option>
                <option value="1">2° Quinzena</option>
              </select>
            </section>
              </section>
          </div>
          {SelectedQuinzena !== 0 && <button onClick={buscandoDados} className={` ${ CallTable === 1 ? "inativo" : "ativo" } btn-finishTrip`}> Gerar Relatorio </button>}
          {CallTable && <Table Data={Data} />}
        
      </div>
      {LoadingTime && <Loading />}
      <Footer />
    </>
  )
}


export default ProducaoClientes