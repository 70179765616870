import React, { useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import BuscaClientes from '../Components/BuscaClientes'
import BuscaToken from '../Components/Functions/BuscaToken.js'

import GerandoPdf from './GerandoPdf.jsx'
import api from '../../services/api'


const Comprov = () => {
  const [Sacas, setSacas] = useState('')
  const [User, setUser] = useState()
  const [Soltos, setSoltos] = useState('')
  const [Client, setClient] = useState()
  const [Data, setData] = useState()

  const history = useHistory()
  
  useEffect(() => {
    api.get('/users/show', BuscaToken())
    .then( r => {
      if(!r.data.message){
        setUser(r.data)
      }
    })
  }, [])

  function SetandoCliente (e){
    setClient(e.target.value)
  }

    async function Finish(e){
      e.preventDefault()
      const DataHora = await api.get(`/HoraServer/json`, BuscaToken())
      if(Client && (Sacas || Soltos)){
        setData({sacas: Sacas, soltos: Soltos, client: Client, data: DataHora.data, user: User.name}) 
        window.print()
        history.push('/')
        return 
     }
     else  
       return alert('Erro. Favor selecionar Cliente e inserir ao menos uma quantia.')
    }
  return(
    <>
      {!Data && <Menu />}
      {!Data && 
      <div className="container contentCenter container-RecebimentoCarga grid">
        <h2>Recebimento de Carga</h2>
        
          <BuscaClientes SetandoCliente={SetandoCliente} bgCoral='bg-Coral'/>
          <form className="grid">
              <label htmlFor="sacas">Sacas</label>
              <input type="text" value={Sacas} maxLength="5" onChange={e => setSacas(e.target.value.replace(/\D/g,""))}/>
              <label htmlFor="soltos">Volumes Soltos</label>
              <input type="text" value={Soltos} onChange={e => setSoltos(e.target.value.replace(/\D/g,""))}/>
              <button className="btn-G" onClick={Finish}>Finalizar</button>
          </form>
          </div>}
      {Data && 
         <section id="pdf" className="ativo">
          <GerandoPdf Data={Data}/>
        </section>}
     
      {!Data && <Footer />}
    </>
  )
}

export default Comprov