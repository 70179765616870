import React, { useEffect, useState} from 'react'

import {useHistory } from 'react-router-dom'

import api from '../../services/api'

import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import BuscaToken from '../Components/Functions/BuscaToken.js'
import Loading from '../Components/Loading'
import GerandoPdf from './GerandoPdf.jsx'



const RequisicaoIndex = () => {
  const [RequisicoesQatual, setRequisicoesQatual] = useState()  
  const [Permissao, setPermissao] = useState()
  const [Pdf, setPdf] = useState()
  const [MostraPdf, setMostraPdf] = useState()
  const [Hidden, setHidden] = useState()

  const history = useHistory()
  const config = BuscaToken()

  const Quinzenas = [{Quinzena: '1°', values: {i: '01', f: '15'}},{Quinzena: '2°', values: {i: '16', f: '31'}}]


  useEffect(() => {
    api.get('/users/CheckPermission', config).then( r => setPermissao(r.data.Permissao))

    api.get('/HoraServer/timestamp', config).then( r => {
      const data = r.data.split('')
      const DataAtual = {
        ano: `${data[0]}${data[1]}${data[2]}${data[3]}`,
        mes: `${data[4]}${data[5]}`,
        dia: `${data[6]}${data[7]}`
      }
      let Quinzena = null
    if(Number(DataAtual.dia) <= 15)
      Quinzena = (Quinzenas[0].values)
    else if(Number(DataAtual.dia) > 15)
      Quinzena = (Quinzenas[1].values)         
    const Data = {
      year: Number(DataAtual.ano),
      month: DataAtual.mes,
      Quinzena
    }

    api.post('/requisicao/emitidas', Data, config).then( r =>{ 
      const requisicoesQAtual = r.data.sort( function compare(a,b){
        if(Number(a.emitida_at_json.dia) > Number(b.emitida_at_json.dia)) return -1
        if(Number(a.emitida_at_json.dia) < Number(b.emitida_at_json.dia)) return 1
        return 0
      })
      setRequisicoesQatual(requisicoesQAtual)})
    })  
    
  }, [])

  async function SegundaVia (requisicao) {
    const data = await api.get(`/requisicao/show/${requisicao}`, config)
    if(!data.data.message){
      setPdf(data.data)
      setHidden(1)
      setMostraPdf(1)
      window.print()
      history.push('/')
    }
    else
      alert('Erro ao encontrar requisicao.')
  }  

  async function CancelRequisicao(requisicao) {
    const cancelamento = await api.get(`/requisicao/cancel/${requisicao}`, config)

    alert(JSON.stringify(cancelamento.data.message))
    
    return false
  }



    return (
       <>
        {Hidden !== 1 &&
          <>
          <Menu />
          <div className="container contentCenter grid">
            <h2>Requisicoes</h2>
              <span className="span-M">Requisicoes emitidas - Quinzena corrente</span>
              { RequisicoesQatual && (
                <table className="tableProduction" >
                  <thead>
                    <tr>
                      <th>Data Emissao</th>
                      <th>Autorizado por</th>
                      <th>Usuario</th>
                      <th>N° Requisicao</th>
                      <th>Valor</th>
                      <th>2° Via</th>
                      {Permissao === 'A' && <th>Cancelar</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {RequisicoesQatual.map((item) => (
                      <tr key={item.id}>
                        <td>{item.emitida_at_json.dia}/{item.emitida_at_json.mes}/{item.emitida_at_json.ano}</td>
                        <td>{item.autor_emissor}</td>
                        <td>{item.user}</td>
                        <td>{item.requisicao}</td>
                        <td>R$ {item.valor},00</td>
                        <td><button className="btn-P" onClick={() => SegundaVia(item.requisicao)}> 2° Via </button></td>
                        {Permissao === 'A' && <th><button className="btn-P" onClick={() => CancelRequisicao(item.requisicao)}> Cancelar </button></th>}
                      </tr> 
                    ))}
                    
                  </tbody>
                </table>
              )}  
          </div>
          <Footer />
          </>}
         <section id="pdf" className={MostraPdf === 1 ? 'ativo' : 'inativo'}>
            {Pdf && <GerandoPdf Data={Pdf}  />}
         </section>                                 
       </>
    )
    
}

export default RequisicaoIndex