import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import Quagga from 'quagga'
import Footer from '../Components/Footer'
import _ from 'lodash'

import moment from 'moment'

import api from '../../services/api'
import BuscaToken from '../Components/Functions/BuscaToken.js'
import { AiOutlineHome} from 'react-icons/ai'
import Loading from '../Components/Loading'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import SignaturePad from 'react-signature-canvas'

import * as styles from './styles.module.css'
import dataURItoBlob from '../../helpers/dataURItoBlob'

const BaixaClienteCourier = () => {
  const [Remessa, setRemessa] = useState()
  const [Recebedor, setRecebedor] = useState('')
  const [Destinatario, setDestinatario] = useState('')
  const [Documento, setDocumento] = useState('')
  const [Parentesco, setParentesco] = useState('')
  const [User, setUser] = useState()
  const [Camera, setCamera] = useState()
  const [RemessaValue, setRemessaValue] = useState('')
  const [loadingTime, setLoadingTime] = useState()
  const [toGetSignature, setToGetSignature] = useState(false)

  let sigPad = {}
  const history = useHistory()

  useEffect(() => {
    api.get('/users/show', BuscaToken())
    .then( r => {
      if(!r.data.message){
        setUser(r.data.name)
      }
    })
    const rm = window.location.href.split('?rm=')[1]
    if(rm) {
      setRemessaValue(rm)
      BuscaRm(rm)
    }
  }, [])


  useEffect(() => {
    if(document.querySelector('#remessa'))
      document.querySelector('#remessa').focus()
  }, [User])



  async function BuscaRm (rm) {
      const currentRM = RemessaValue || rm

      if(currentRM.length < 1){
        alert('Por favor entre com a remessa.')
        return false
      }
      setLoadingTime(1)
      const ResponseServer = await api.get(`/baixacliente/search/${currentRM}`, BuscaToken())
    
      if(ResponseServer.data.message){
        alert(ResponseServer.data.message)
        setLoadingTime()
        return setRemessaValue('')
      } 
      setRemessa(ResponseServer.data)  
      if(ResponseServer.data.destinatario){
        setDestinatario(ResponseServer.data.destinatario)
      }
      setLoadingTime()
  }


  async function ShowSignaturePad (event){
    event.preventDefault()

    if(!Destinatario || !Recebedor || !Documento || !Parentesco) 
      return alert('Dados invalidos!')

    setToGetSignature(true)

    return 
  }

  async function Finish(event) {
    event.preventDefault()

    if(sigPad.isEmpty()) return alert('Assinatura necessária.')
    
    setLoadingTime(1)
      const data = {
        rm: Remessa.rm,
        Destinatario,
        Parentesco,
        Recebedor,
        Documento
      }
    

      const canvas = sigPad.getCanvas()

      const base64 = canvas.toDataURL('image/jpeg', 1.0)

      const file = await dataURItoBlob(base64)


      const formData = new FormData()

      formData.append('rm', data.rm)
      formData.append('destinatario', data.Destinatario)
      formData.append('grau', data.Parentesco)
      formData.append('recebedor', data.Recebedor)
      formData.append('documento', data.Documento)
      formData.append('signature', file, 'signature.jpeg')
     
      const ServerResponse = await api.post('/baixacliente/baixa', formData, {
        ...BuscaToken(),
        'Content-Type': 'multipart/form-data'
      })
      setLoadingTime()
      alert(ServerResponse.data.message)
      return history.push('/')
  }


  function Scanner () {
    Quagga.init({
      inputStream : {
        name : "Live",
        type : "LiveStream",
        constraints: {
          width: 1080,
          height: 780,
          facingMode: "environment"
        },
        area: {
          top: "40%",
          right: "0%",
          left: "0%",
          bottom: "40%",
        },
        target: document.querySelector('#camera')    // Or '#yourElement' (optional)
      },
      decoder : {
        readers : ["code_128_reader"]
      },
    }, function(err) {
        if (err) {
            console.log(err);
            return
        }
        console.log("Initialization finished. Ready to start");
        setCamera(1)
        Quagga.start();
    });
  }

  Quagga.onDetected(function (data){
    if(data.codeResult.code){
      if(data.codeResult.code.includes('%') || data.codeResult.code.includes('.') || data.codeResult.code.includes('"'))
        return 
      else {
        Quagga.stop()
        setCamera()
        setRemessaValue(data.codeResult.code)
      } 
    }
  })

  return (
    !toGetSignature ?
    <>
      {<div className="grid headerBaixaCourier">
        <Link to="/home"><AiOutlineHome /></Link>
      </div>}
       
      <div className="grid container divBaixaClienteCourier">
        {      loadingTime && <Loading />}
        <h2>Baixa de remessa</h2>
        {!Camera && <>
        {!Remessa && 
        <>
        <label htmlFor="remessa" style={{display: 'inline'}}>Remessa: <input type="text" className="bg-Coral" value={RemessaValue}
         onChange={(event) => setRemessaValue(event.target.value)} id="remessa" onKeyPress={(event) => event.key === "Enter" && BuscaRm()}/><button onClick={BuscaRm} style={{ marginLeft: '5px' }}>Buscar</button></label> 
        <button onClick={Scanner}>Scan</button> 
        
        </>}
          {Remessa && 
            <form className="grid">
              <span className="span-M ativo">Remessa: {Remessa.rm}</span>
              <span className="span-M ativo">Cliente: {Remessa.client}</span>
              {Remessa.prazo && <p>Prazo: {moment(Remessa.prazo).format('DD/MM/YYYY')}</p>}
              {Remessa.destinatario ? 
                <>
                  <p>Destinatario: <span>{Remessa.destinatario}</span></p>
                  <p>Endereço: <span>{Remessa.endereco}</span></p>
                  <p>Bairro: <span>{Remessa.bairro}</span></p>
                  <p>Contato: <span>{Remessa.contato}</span></p>
                  <p>Cidade: <span>{Remessa.cidade}</span></p>
                </> :
                <>
                  <label htmlFor="nomeDestinatario">Destinatario:</label>
                  <input
                   type="text" 
                   id="nomeDestinatario" 
                   placeholder="Obrigatorio"
                   onChange={(event) => setDestinatario(event.target.value)}
                   value={Destinatario}
                   />
                </>
              }
              <label htmlFor="parentesco">Grau de Parentesco:</label>
              <select 
                name="parentesco" 
                id="parentesco"
                onChange={(event) => {
                  setParentesco(event.target.value)
                  if(event.target.value === 'Proprio'){
                    setRecebedor(Destinatario)
                  } else {
                    if(Recebedor === Destinatario)
                      setRecebedor('')
                  }
                }}
                value={Parentesco}
              >
                <option value="">Selecionar</option>
                <option value="Proprio">Proprio</option>
                <option value="Esposo(a)">Esposo(a)</option>
                <option value="Mae/Pai">Mae/Pai</option>
                <option value="Avô/Avó">Avô/Avó</option>
                <option value="Filho">Filho</option>
                <option value="Primo(a)">Primo(a)</option>
                <option value="Irmão/Irmã">Irmão/Irmã</option>
                <option value="Vizinho">Vizinho</option>
                <option value="Funcionario">Funcionario</option>
                <option value="Porteiro">Porteiro</option>
                <option value="Outros">Outros</option>
              </select>
              <label htmlFor="nome">Recebedor:</label>
              <input 
                type="text" 
                id="nome" 
                placeholder="Obrigatorio"
                onChange={(event) => setRecebedor(event.target.value)}
                value={Recebedor}
                />
              <label htmlFor="documento">Documento:</label>
              <input 
                type="text" 
                id="documento" 
                placeholder="Obrigatorio"
                onChange={(event) => setDocumento(event.target.value)}
                value={Documento}
              />
              <button className="btn-M ativo" disabled={loadingTime} onClick={ShowSignaturePad}> Assinar </button>
            </form>
          } </>}
          <div id="camera" className={Camera ? 'grid' : 'inativo'}></div>
         
      </div>
      
      { <Footer /> }
      
    </> :

    <>
      {loadingTime && <Loading />}
      <div className={styles.containerSignature}>
        <h5 style={{ textAlign: 'center', fontWeight: '400'}}>Assinatura</h5>
        <div className={styles.sigContainer}>
          <SignaturePad canvasProps={{ className: styles.sigPad }} backgroundColor="rgb(255,255,255)"
            ref={(ref) => { sigPad = ref }} />
        </div>
      <div>
          <button
            onClick={Finish}
            className="btn-M ativo"
            style={{margin: '0 auto', display: 'block'}}
          >
            Finalizar
          </button>
        <button
          onClick={() => { sigPad.clear() }}
          className="btn-P ativo"
          style={{margin: '0 auto', display: 'block', marginTop: '2%', paddingTop: '5px'}}
        >
            Limpar
          </button>
        <button
          onClick={() => { setToGetSignature(false) }}
          className="btn-P ativo"
          style={{margin: '0 auto', display: 'block', marginTop: '2%', paddingTop: '5px'}}
        >
            Cancelar
          </button>
        </div>
      </div>
    </>
  )
}

export default BaixaClienteCourier