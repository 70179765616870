import React, { useEffect, useState } from 'react'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import BuscaToken from '../Components/Functions/BuscaToken.js'
import api from '../../services/api'

import {
  Tabs,
  Tab,
  Paper,
} from '@material-ui/core'
import moment from 'moment'
import { useHistory } from 'react-router-dom'


const HomeCourier = () => {
  const [remessas, setRemessas] = useState([])
  const [allRemessas, setAllRemessas] = useState([])
  const [clients, setClients] = useState([])

  const history = useHistory()


  function orderRemessas(noPrazo, atrasados) {
    const rmsSerialized = []

    atrasados.forEach( item => rmsSerialized.push({
      ...item,
      atrasada: true
    }))
    noPrazo.forEach( item => rmsSerialized.push({
      ...item,
      atrasada: false
    }))
    setAllRemessas(rmsSerialized)
    setRemessas(rmsSerialized)
  }

  useEffect(() => {
    api.get('/couriers/open/rms', BuscaToken())
    .then( r => {
      if(r.data.auth){
        orderRemessas(r.data.noPrazo, r.data.atrasados)
        const allremessas = [...r.data.noPrazo].concat([...r.data.atrasados])
        let clients = allremessas.map( item => item.client)
        clients = clients.filter( (item, index) => clients.indexOf(item) === index)
        setClients(clients)
      }
    })
  }, [])

  const filtro = (event) => {
    if(event.target.value.length > 0){
      const RemessasFiltradas = allRemessas.filter( remessa =>  remessa.client === event.target.value)
      setRemessas(RemessasFiltradas)
    }
    else {
      setRemessas(allRemessas)
    }
  }

  function copyAddresses () {
    let rms = remessas
    rms = rms.map( item => ({
      ...item,
      zipCode: item.cidade ? item.cidade.includes('-') ? +item.cidade.slice(0, 9).replace('-', '') : +item.cidade.slice(0, 8) : 0
    })) 

    rms = rms.sort(function (a, b) {
      if(a.zipCode > b.zipCode)
        return -1
      if(a.zipCode < b.zipCode)
        return 1
      return 0  
    })

    let addresses = ''
    for(const rm of rms) {
      if(rm.endereco) {
        addresses += `\n ${rm.endereco} - ${rm.bairro} - ${rm.client}(${rm.rm})`
      } else {
        addresses += `\n ___________________________ - ${rm.client}(${rm.rm})`
      }
      
    }
    navigator.clipboard.writeText(addresses)
  }

  function textNormalize(arg) {
    return arg?.toLocaleLowerCase()
    .trim()
    .normalize('NFD')
    .replace(/ /g, '-')
    .replace(/[\u0300-\u036f]|[\/]$|[^\/a-z0-9\-]/g, '');

  }

  function filterRemessas (value) {
    if(value){
      const RemessasFiltradas = allRemessas.filter( remessa => 
        textNormalize(remessa.destinatario)?.includes(textNormalize(value)) ||
        textNormalize(remessa.endereco)?.includes(textNormalize(value))
         )
      setRemessas(RemessasFiltradas)
    }
    else {
      setRemessas(allRemessas)
    }
  }

  function goToBaixa(rm) {
    history.push(`/Baixa?rm=${rm}`)
  }

  return (
    <>
      <Menu />
      <div
        className="container container-home grid"
       >
         <h3> Painel de remessas </h3>

        <button onClick={copyAddresses} style={{ marginBottom: '5px' }}> Copiar Endereçamento </button>
        
          <Paper 
            elevation={5}
            square
            style={{
              margin: '5px',
              padding: '10px',
              height: 'fit-content'
            }}
          >
          <div
            style={{
              display: 'grid',
              marginTop: '10px'
            }}
          >
            <label style={{ display: 'inline-block'}}>Filtro Clientes</label>
            <select name="clientes" id="clientes" style={{display: 'inline', fontSize: "15pt", backgroundColor: "coral"}} onChange={(event) => filtro(event)}>
            <option value="">Todos</option>
              {clients && clients.map( cliente => <option value={cliente} key={cliente}>{cliente}</option>)}
            </select>


            <input
              className='grid'
              style={{ display: 'inline-block', margin: '0 auto', 'marginBottom': '5px' }}
              onChange={(event) => filterRemessas(event.target.value)}
              type="text" 
              placeholder='Buscar remessa' 
            />

            <span style={{ color: 'crimson', fontSize: '15pt', margin: '7px'}}>Total de {allRemessas.length} remessas em aberto</span>

            {remessas.map( (item, index) => 
              <div
                className={`div-rm-index-courier ${item.atrasada ? 'rm-vencida' : ''}`}
                key={item.rm+index}
                onClick={() => goToBaixa(item.rm)}
              >
                <p 
                  style={{
                    fontSize: '12pt',
                    fontWeight: '400'
                  }}
                >Remessa: <span>{item.rm}</span></p>
                <p 
                  style={{
                    fontSize: '12pt',
                    fontWeight: '400'
                  }}
                >Cliente: 
                {' '}
                <span 
                  style={{
                    fontSize: '12pt',
                    fontWeight: '400'
                  }}
                >{item.client}</span></p>
                {item.destinatario ? 
                  <p 
                    style={{
                      fontSize: '11pt',
                      fontWeight: '400'
                    }}
                  >Destinatário: 
                    {' '}
                  <span 
                    style={{
                      fontSize: '11pt',
                      fontWeight: '400'
                    }}
                  >{item.destinatario}</span></p> : ''}
                {item.endereco ? 
                  <p 
                    style={{
                      fontSize: '11pt',
                      fontWeight: '400'
                    }}
                  >Endereço:
                  {' '}
                  <span
                    style={{
                      fontSize: '11pt',
                      fontWeight: '400'
                    }}
                  >{item.endereco}</span></p> : ''}
                {item.bairro ? 
                  <p
                    style={{
                      fontSize: '11pt',
                      fontWeight: '400'
                    }}
                  >Bairro: 
                  {' '}
                  <span 
                    style={{
                      fontSize: '11pt',
                      fontWeight: '400'
                    }}
                  >{item.bairro}</span></p> : ''}
                {item.cidade ?
                  <p 
                    style={{
                      fontSize: '11pt',
                      fontWeight: '400'
                    }}
                  >Cidade: 
                  {' '}
                  <span 
                    style={{
                      fontSize: '11pt',
                      fontWeight: '400'
                    }}
                  >{item.cidade}</span></p> : ''}
                {item.contato ? 
                  <p 
                    style={{
                      fontSize: '11pt',
                      fontWeight: '400'
                    }}
                  >Contato: 
                  {' '}
                  <span 
                    style={{
                      fontSize: '11pt',
                      fontWeight: '400'
                    }}
                  >{item.contato}</span></p> : ''}
                {item.prazo ? 
                  <p 
                    style={{
                      fontSize: '11pt',
                      fontWeight: '400'
                    }}
                  >Prazo: 
                  {' '}
                  <span 
                    style={{
                      fontSize: '11pt',
                      fontWeight: '400'
                    }}
                  >{item.prazo}</span></p> : ''}
              </div>
              
              )}
          </div>
        </Paper>
      </div>
      <Footer />
    </>
  )
}

export default HomeCourier