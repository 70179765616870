import React, { useEffect, useState } from 'react'

import {useHistory} from 'react-router-dom'

import api from '../../services/api'
import BuscaToken from '../Components/Functions/BuscaToken.js'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import BuscaClientes from '../Components/BuscaClientes'
import Loading from '../Components/Loading'
import duplicity from '../Medias/duplicity.wav'
import GerandoPdf from './GerandoPdf.jsx'

const Avarias = () => {
  const [User, setUser] = useState()
  const [SelectedCliente, setSelectedCliente] = useState()
  const [TxtArea, setTxtArea] = useState()
  const [QtdBipada, setQtdBipada] = useState()
  const [LoadingTime, setLoadingTime] = useState()
  const [Pdf, setPdf] = useState()
  const [Hidden, setHidden] = useState()
  const [Data, setData] = useState()
  const [MostraPdf, setMostraPdf] = useState()
  const [SoundDuplicity, setSoundDuplicity] = useState()
  if(!SoundDuplicity){
      setSoundDuplicity(new Audio(duplicity))
    }

  const history = useHistory()
  if(!SoundDuplicity){
    setSoundDuplicity(new Audio(duplicity))
  }
  useEffect(() => {
    api.get('/users/show', BuscaToken())
    .then( r => {
      if(!r.data.message){
        setUser(r.data)
      }
    })
  }, [])

  useEffect(() => {
    if(MostraPdf === 1){
      window.print()
      history.push('/')
    } 
  }, [MostraPdf])

  function SetandoCliente (event) {
    if(event.target.value !== 'false')
      setSelectedCliente(event.target.value)
    else 
      setSelectedCliente()  
  }


  function textareaSave (event) {
    if(event.key === 'Enter'){
        let txt = TxtArea.toUpperCase().split('\n')
        txt = txt.filter( item => item.length > 0)
        setQtdBipada(txt.length)
        txt.map(async (item,index) => {
          if(txt.indexOf(item) !== index) {
            const NewTxt = txt.filter((item,i) => {
              if(i !== index)
                return item
            })
            await SoundDuplicity.play()
            const stringNewTxt = NewTxt.toString()
            let NewTxtSerialized = stringNewTxt.replace(/,/g, '\n')
                NewTxtSerialized += '\n'
            setTxtArea(NewTxtSerialized.toUpperCase())
            setQtdBipada(NewTxt.length)  
            
          } 
        })
    }
  }

  const Finalizar = async () => {
    const rms = TxtArea.split('\n').filter(item => item.length > 0).map(item => item.trim().toUpperCase())
    const data = {
      User: User.name,
      rms,
      cliente: SelectedCliente
    }
    setLoadingTime(1)
    const ResponseServer = await api.post('/Avarias', data, BuscaToken())
    alert(ResponseServer.data.message)
    setLoadingTime()
    
    if(ResponseServer.data.relatorio){
      setData({
        relatorio:ResponseServer.data.relatorio,
        remessas: ResponseServer.data.remessas,
        autor: ResponseServer.data.user,
        data: ResponseServer.data.data,
        cliente: ResponseServer.data.cliente
      })
      setPdf(1)
      setHidden(1)
      setMostraPdf(1)
    }
  }



  return(
    <>
      {!Hidden && <>
        <Menu />
        <div className={` ${Hidden === 1 ? 'inativo' : 'ativo'} contentCenter container grid`}>
          <h2>Inserir Avarias</h2>
          <BuscaClientes SetandoCliente={SetandoCliente} />
          {QtdBipada && <span className="ativo span-M">Quantidade bipada: {QtdBipada}</span>}
          {SelectedCliente && <textarea onChange={(event) => setTxtArea(event.target.value)} value={TxtArea} onKeyPress={textareaSave}></textarea>}
          {QtdBipada > 0 && <button className="ativo btn-G" onClick={Finalizar}> Finalizar </button>}
        </div>
        <Footer />
      </>}
      <section id="pdf" className={MostraPdf === 1 ? 'ativo' : 'inativo'}>
            {Pdf && <GerandoPdf Data={Data}  />}
      </section>  
    </>
  )

}



export default Avarias