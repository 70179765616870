import React, { useEffect, useState} from 'react'
import api from '../../../services/api'

import BuscaToken from '../../Components/Functions/BuscaToken.js'

import Menu from '../../Components/Menu/Menu'
import Footer from '../../Components/Footer'
import Loading from '../../Components/Loading'

const AddTaxasCourier = () => {
  const [Clientes, setClientes] = useState()
  const [Couriers, setCouriers] = useState()  
  const [SelectedCourier, setSelectedCourier] = useState()
  const [SelectedCliente, setSelectedCliente] = useState()

  useEffect(() => {
    api.get('/clients', BuscaToken()).then( r => setClientes(r.data.clients))
    api.get('/couriers', BuscaToken()).then( r => setCouriers(r.data))
  }, [])


  async function Finalizar (event) {
    event.preventDefault()
    const G = document.querySelector('#G').value
    const P = document.querySelector('#P').value

    if(G.length === 0 || P.length === 0){
      return alert('Favor preencher todos os campos.')
    }

    const data = {
      courier: SelectedCourier,
      taxas: {
        cliente: SelectedCliente,
        taxas: {'G': G,
                'P': P}              
      }
    }

    const ResponseServer = await api.post('/courier/addTaxas', data, BuscaToken())

    alert(ResponseServer.data.message)

    return window.location.reload()
  }

  return (
    <>
    <Menu />
    <div className="container contentCenter grid">
      <h2>Taxas Courier</h2>
      <form className="addTaxasCourier grid">
        {Couriers && <select onChange={(event) => event.target.value !== '' && setSelectedCourier(event.target.value)}>
          <option value="">Selecionar Courier</option>
          {Couriers.map( courier => 
            <option key={courier.id} value={courier.id}>{courier.name}</option>
            )}
          </select>}
        {Clientes && SelectedCourier && <select onChange={(event) => event.target.value !== '' && setSelectedCliente(event.target.value)}>
          <option value="">Selecionar Cliente</option>
          {Clientes.map( cliente => 
            <option key={cliente.name} value={cliente.name}>{cliente.name}</option>
          )}
          </select>}  
        {SelectedCliente && 
        <>
          <label htmlFor="P">Taxas Pequenas</label>
          <input type="text" id="P"/>
          <label htmlFor="G">Taxas Grandes</label>
          <input type="text" id="G"/>
        </>
        }
        {SelectedCliente && <button id="Finalizar" className="btn-addRms" onClick={(event) => Finalizar(event)}> Finalizar </button>}    
      </form>
    </div>
    <Footer />
    </>
  )
}


export default AddTaxasCourier