import React from 'react';
import './App.css';

import Routes from './pages/routes'

function App() {
  return (
       <>
         <Routes />
       </>
  );
}

export default App;
