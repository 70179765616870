import React, { useState, useCallback} from 'react'
import { debounce } from 'lodash'
import api from '../../services/api'
import BuscaToken from '../Components/Functions/BuscaToken.js'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import Loading from '../Components/Loading'

import duplicity from '../Medias/duplicity.wav'

import NotFound from '../Medias/Error.wav'
import Sucess from '../Medias/Sucess.wav'

const PrazosCheck = () => {
  const [deadline, setDeadline] = useState('')
  const [deadlineValidation, setDeadlineValidation] = useState(false)
  const [bg, setBg] = useState('')
  const [message, setMessage] = useState('')
  const [SoundNotFound, setSoundNotFound] = useState()
  const [SoundSucess, setSoundSucess] = useState()
  const [SoundDuplicity, setSoundDuplicity] = useState()
  const [showLoading, setShowLoading] = useState()
  const [Rm, setRm] = useState('')


  if(!SoundNotFound){
    setSoundNotFound(new Audio(NotFound))
  }
  if(!SoundSucess){
    setSoundSucess(new Audio(Sucess))
  } 
  if(!SoundDuplicity){
    setSoundDuplicity(new Audio(duplicity))
  }

  const DefaultBG = useCallback(
    debounce(function(){
    setBg('')
    setMessage('')
  }, 10000),[])



  const config = BuscaToken()


  async function Check(event){
    console.log(event)
    if(event.key === 'Enter') {

      const query = `${deadline.replaceAll('/', '-')}=${event.target.value}`

      setShowLoading(1)
      const response = await api.get(`/prazo/check=${query}`, config)
      setShowLoading()
      if(response.data.auth === 1){
        setBg('yellow')
        setMessage(response.data.message)
        await SoundDuplicity.play()
        setRm('')
        DefaultBG()
      }
      else if(response.data.auth === 2){
        setBg('green')
        setMessage(response.data.message)
        await SoundSucess.play()
        setRm('')
        DefaultBG()
      }
      else {
        setBg('red')
        setMessage(response.data.message)
        await SoundNotFound.play()
        setRm('')
        DefaultBG()
      }

      
    }
  }

 

  return (
    <>
      <Menu />
      <div className="container contentCenter container-PainelRemessas grid" style={{ backgroundColor: `${bg}`}}>
        <h2 style={{ color: 'black'}}>Checando Prazos</h2>
        <p>Insira a data limite para verificação.</p>
        {showLoading && <Loading />}
        <input
          type="text" 
          placeholder="Ex: 20/04/2020"
          style={{
            width: '200px',
            margin: '0 auto'
          }}
          onChange={e => {
            const value = (e.target.value.replace(/\D/g,"").replace(/(^[0-9]{2})([0-9]{2})([0-9]{4})/, "$1/$2/$3"))
            setDeadline(value)
            if(!value || value.length !== 10)
              return setDeadlineValidation(false)

            const date = value.split('/')
            if(+date[0] > 31 || +date[0] < 1|| +date[1] > 12 || +date[1] < 1)
              return setDeadlineValidation(false)
            return setDeadlineValidation(true)
          }}
          value={deadline}
          maxLength="10"
        />
        {deadline && !deadlineValidation &&
          <span style={{
            color: "crimson"
          }}>
            Data inválida
          </span>
        }

        {deadlineValidation &&
          <div
            style={{
              marginTop: '7vh',
              marginBottom: '20vh'
            }}
          >
            <label htmlFor="rm">Entre com a remessa</label>
            <input
              type="text" 
              style={{
                width: '300px',
                margin: '0 auto'
                }}
              placeholder="Remessa + Enter"  
              onChange={e => setRm(e.target.value)}
              value={Rm}
              onKeyPress={Check}
            />
            {message && <span style={{ display: 'block' }}>{message}</span>}
          </div>
        }
      </div>
      <Footer />
    </>
  )

}

export default PrazosCheck