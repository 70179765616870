import React, { useState, useEffect, useCallback } from 'react'
import { debounce } from 'lodash'
import api from '../../services/api'
import { useHistory, Link } from 'react-router-dom'
import { AiFillCopy } from 'react-icons/ai'



import BuscaToken from '../Components/Functions/BuscaToken.js'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import RmtoArray from '../Components/Functions/RmtoArray.js'
import duplicity from '../Medias/duplicity.wav'
import erro from '../Medias/Erro.wav'
import Sucess from '../Medias/Sucess.wav'
import Loading from '../Components/Loading'
import CheckStatusRms from '../Components/Functions/CheckStatusRms.js'

const EditTrip = () => {
  const [Trip, setTrip] = useState()
  const [Type,setType] = useState()
  const [Txt,setTxt] = useState('')
  const [SelectedClient, setSelectedClient] = useState()
  const [RMS, setRMS] = useState([])
  const [QtdBipada, setQtdBipada] = useState()
  const [ClienteLista, setClienteLista] = useState()
  const [ClienteListaValue, setClienteListaValue] = useState()
  const [AddRemessas, setAddRemessas] = useState()
  const [User, setUser] = useState()
  const [LoadingTime, setLoadingTime] = useState()
  const [Logins, setLogins] = useState({})
  const [Clients, setClients] = useState()
  const [SelectedLogin, setSelectedLogin] = useState()
  const [TotalRms, setTotalRms] = useState(0)
  const [UnitRemessas, setUnitRemessas] = useState([])
  const [ConfigBipagem, setConfigBipagem] = useState()
  const [StatusUnitRm, setStatusUnitRm] = useState()
  const [CheckBUnitBg, setCheckBUnitBg] = useState()
  const [SoundSucess, setSoundSucess] = useState()
  const [SoundErro, setSoundErro] = useState()
  const [SoundDuplicity, setSoundDuplicity] = useState()

  if(!SoundSucess){
    setSoundSucess(new Audio(Sucess))
  } 
  if(!SoundErro){
    setSoundErro(new Audio(erro))
  }
  if(!SoundDuplicity){
    setSoundDuplicity(new Audio(duplicity))
  }

  const config = BuscaToken()

  const history = useHistory()

  class addCliente{
    create (cliente,type,rms,lista,RmsSave, SelectedLogin){
    const viagem = {
      cliente,
      type,
      quantidade: rms.length,
      rms,
      lista,
      RmsSave,
      Login: SelectedLogin
    }
    return viagem
    }
  }
  const addcliente = new addCliente()



  useEffect(() => {
    api.get('/clients/actives', config).then(response => {
      setClients(response.data.clients)
    })

    api.get('/users/show', config)
    .then( r => {
      if(!r.data.message){
        setUser(r.data)
      }
    })
    const params = window.location.href.split('EditTrip/')
  
    api.get(`/trips/${params[1]}`, config).then(r => {   
      if(r.data.status !== 'Pending'){
        alert('Trip nao localizada ou com Status diferente de Pending!')
        return (
          history.push('/home')
        )
      }
        
        if(r.data.clients_rms){
          const RMScurrentTrip = r.data.clients_rms.map(item => item)
          setRMS(RMScurrentTrip)
          const RmsArrays = (RMScurrentTrip.map(item => item.rms))
          setTotalRms( [].concat(...RmsArrays).length)
          } 
      setTrip(r.data)
    })
    
  }, [])

  const DefaultBG = useCallback(
    debounce(function(){
    setCheckBUnitBg()
    setStatusUnitRm()
  }, 5000),[])

  async function CheckUnitRemessa (remessa) {
    setLoadingTime(1)
    const data = {
      remessa: remessa.trim(),
      courier: Trip.courier,
      client: SelectedClient
    }
    const ResponseServer = await api.post(`/trips/check/rm`, data,  BuscaToken())
    setStatusUnitRm(ResponseServer.data.message)
    setLoadingTime()
    document.querySelector('#InputRemessa').value = ''
    let RemessasBipadas = []
    if(RMS.length > 0){
      let remessas = RMS.map( item => item.rms)
      remessas = [].concat(...remessas)
      RemessasBipadas = remessas
    }
    if(!ResponseServer.data.auth){
      setCheckBUnitBg('BGred')
      await SoundErro.play() 
      DefaultBG()
      return false 
    }
    setCheckBUnitBg('BGgreen')
    if((RemessasBipadas.concat(UnitRemessas)).indexOf(remessa) !== -1){
      await SoundDuplicity.play()
      DefaultBG()
      setStatusUnitRm('Remessa ja bipada!')
      return false
    }
    setUnitRemessas([...UnitRemessas, remessa])
    setQtdBipada(UnitRemessas.length + 1)
    DefaultBG()

      await SoundSucess.play()
  }
 
  async function typeRM (event) {
    if(event.target.value.length === 0){
      setType(event.target.value)
      setClienteLista()
      return 
    }
    setType(event.target.value)
    setLoadingTime(1)
    const lista = await api.get(`/clientlista/${SelectedClient}`, config)
    setLoadingTime()
    if(+lista.data.lista){
      let lista = RMS.filter( item => item.cliente === SelectedClient)
      if(lista.length > 0){
        lista = lista[0].lista
        setClienteListaValue(lista)
        setClienteLista(1)
      }
      setClienteLista(1)
      setClienteListaValue()
      
    }  
    else
      setClienteLista()  
      setClienteListaValue()
  }

  async function SetandoCliente (event){
    if(event.target.value.length > 0){
      setTxt()
      setUnitRemessas([])
      setConfigBipagem()
      setQtdBipada()
      setSelectedClient(event.target.value)
      setType()
      setClienteLista()
      if(['Carvalima', 'Chips', 'Transfolha'].indexOf(event.target.value) === -1)
          setConfigBipagem(1)
      const cliente = Clients.filter(item => item.name === event.target.value).map(item => item.useapp)
      if(cliente[0] === "1"){
        if((RMS.filter( rms => rms.cliente === event.target.value)).length === 0){
          setSelectedLogin()
          setLoadingTime(1)
          const logins = await api.get(`/logins/allows/${event.target.value}`, config)
          setLogins(logins.data.sort(function compare (a,b) {
            if(a.nome < b.nome) return -1
            if(a.nome > b.nome) return 1
            return 0
          }))
          setLoadingTime()
          if(document.querySelector('#type'))
            document.querySelector('#type').value = ''
        }
        else{
          const login = RMS.filter( rms => rms.cliente === event.target.value)
          setSelectedLogin(login[0].Login)
          setLogins()
          if(document.querySelector('#type'))
            document.querySelector('#type').value = ''
        }
      }
      else{
        setSelectedLogin()
        setLogins()
        if(document.querySelector('#type'))
          document.querySelector('#type').value = ''
      }
    }
    else {
      setSelectedClient()
      setSelectedLogin()
      setLogins()
    }
 }

  function editTxt(event){
    setTxt(event.target.value)
  }

  //ao teclar enter no TextArea, verifica se tem remessa duplicada, e altera o span de QtdBipada
  async function qtdBipada (event) {
    
    if(event.key === 'Enter'){
      const rms_saida= Trip.remessas_saida
      let txt = Txt.toUpperCase().split('\n')
      txt = txt.filter( item => item.length > 0)
      setQtdBipada(txt.length)
      let remessas = txt

      const textserialized = txt.filter( (item, index) => txt.indexOf(item) === index
                            && rms_saida.indexOf(item) === -1)
      
      if(textserialized.length !== txt.length){
        await SoundDuplicity.play()
        let NewTxtSerialized = textserialized.toString().replace(/,/g, '\n')
            NewTxtSerialized += '\n'
        setTxt(NewTxtSerialized)
        setQtdBipada(textserialized.length)     
      }

      /*
      function RemessaDuplicada (NewTxt){
        const soundError = new Audio(duplicity)
        soundError.play()
        const stringNewTxt = NewTxt.toString()
        let NewTxtSerialized = stringNewTxt.replace(/,/g, '\n')
            NewTxtSerialized += '\n'
        remessas = NewTxtSerialized.split('\n').filter(item => item.length > 0)
        setTxt(NewTxtSerialized)    
        setQtdBipada(NewTxt.length)  
      }
      txt.map((item,index) => {
        if(txt.indexOf(item) !== index || rms_saida.indexOf(item) !== -1) {
          const NewTxt = txt.filter((item,i) => {
            if(i !== index)
              return item
               
          })
          RemessaDuplicada(NewTxt)
          
        } 
      })*/
      /*
      if(TotalRms + remessas.length > 70){
        const soundError = new Audio(LimiteSound)
        await soundError.play()
        alert('Limite de 70 remessas atingido!')
        const NewTxt = remessas.filter((item, index) => index !== remessas.length - 1 )
        const stringNewTxt = NewTxt.toString()
        let NewTxtSerialized = stringNewTxt.replace(/,/g, '\n')
            NewTxtSerialized += '\n'
        setQtdBipada(NewTxt.length)    
        setTxt(NewTxtSerialized)  

      }
      */
    }
  }


  //ao clicar em adicionar remessas, adiciona as remessas ao RMS atual, zera QtdBipada, 
  async function addTrip () {
    if(QtdBipada < 1) {
      alert('Nenhuma remessa bipada!')
      return 
    }
    if(ClienteLista){
      if(document.getElementById('spanLista').value.length > 0){
        var lista = document.getElementById('spanLista').value
      }
        
      else{
        alert('Favor inserir numero da Lista!')
        document.getElementById('spanLista').focus()
        return
      }  
    }

    setQtdBipada()

    async function addNewViagem() {
      let remessas
      if(ConfigBipagem === 2){
        let rms = Txt.toUpperCase().replace(/\$/g, '')
        rms = rms.split('\n').map( rm => rm.trim())
        rms = rms.filter( (item, index) => rms.indexOf(item) === index)
        let RemessasBipadas = []
        if(RMS.length > 0){
          let remessas = RMS.map( item => item.rms)
          remessas = [].concat(...remessas)
          RemessasBipadas = remessas
        }
        rms = rms.filter( (item, index) => rms.indexOf(item) === index)
        rms = rms.filter(remessa => RemessasBipadas.indexOf(remessa) === -1)
        setLoadingTime(1)
         remessas = await CheckStatusRms(rms.filter((item) => item.length > 2),SelectedClient)
        setLoadingTime()
        if(remessas.barradas.length > 0){
          let barradas = `Erro ao adicionar as remessas abaixo. \n`
          remessas.barradas.forEach( item => {
            barradas += `${item.rm} : ${item.status} \n`
          })
          alert(barradas)
        }
        remessas = remessas.autorizadas
      }
      else {
        remessas = UnitRemessas
      }

      setTotalRms(TotalRms + remessas.length)
      const RmsSave = remessas.map((remessa) => {
        return {
          remessa,
          Type,
          cliente: SelectedClient,
          lista: ClienteListaValue
        }
      })
      const newViagem = addcliente.create(SelectedClient,Type,remessas,lista,RmsSave, SelectedLogin)
      return newViagem
    }
    const newViagem = await addNewViagem()
    // verifica se a trip ja tem cliente e type iguais para adicionar junto na mesma ul, caso nao tenha cria uma ul nova
    let contain = false
    RMS.map((item) => {
      if(item.cliente === newViagem.cliente && item.type === newViagem.type && item.lista === newViagem.lista)
        contain = true
      else if(!newViagem.lista && item.cliente === newViagem.cliente && item.type === newViagem.type)
        contain = true
    })
      
    if(contain) {
    const newRMS = RMS.map((item) => {
        if(item.lista === newViagem.lista && item.cliente === newViagem.cliente && item.type === newViagem.type ){
          let OriginItem = item
          OriginItem.rms = OriginItem.rms.concat(...newViagem.rms)
          OriginItem.quantidade = OriginItem.rms.length
          OriginItem.RmsSave = OriginItem.RmsSave.concat(...newViagem.RmsSave)
          return OriginItem
        } 
        else if(!newViagem.lista && item.cliente === newViagem.cliente && item.type === newViagem.type ){
          let OriginItem = item
          OriginItem.rms = OriginItem.rms.concat(...newViagem.rms)
          OriginItem.quantidade = OriginItem.rms.length
          OriginItem.RmsSave = OriginItem.RmsSave.concat(...newViagem.RmsSave)
          return OriginItem
        } 
      else 
        return item 
      })
      setRMS(newRMS)
    }
    else {
      if(newViagem.rms.length > 0)
        setRMS([...RMS, newViagem])
    }
    setSelectedClient()
    if(document.getElementById('Clients'))
      document.getElementById('Clients').value = ''
    setType()
    if(document.getElementById('type'))
      document.getElementById('type').value = ''
    setClienteLista()
    setAddRemessas()
    setTxt()
    if(document.querySelector('#Clients'))
      document.querySelector('#Clients').value = ''
    setSelectedClient()
    setType()
    setClienteLista()
    setQtdBipada()
    setUnitRemessas([])
    setConfigBipagem()
    setSelectedLogin()
    setLogins()
  }


  async function FinishTrip(clients_rms = RMS, params = null) {
    const RmsSaveArray = clients_rms.map( item => item.RmsSave)
    const RmsSave = [].concat(...RmsSaveArray)  

    const TripEdited = 
    {
      id: Trip.id,
      clients_rms: [...clients_rms],
      RmsSave,
      courier: Trip.courier,
      autor: User.name
    }
    
    setLoadingTime(1)
    const toEdit = await api.post('/trips/edit', TripEdited, config)
    setLoadingTime()
    localStorage.removeItem('RMS')
    if(params !== 'removeuniqueRm'){
      alert(JSON.stringify(toEdit.data.message))
      return history.push('/home')
    }
    
   return 
  }

  function AllowAddRemessas() {
    if(!AddRemessas)
      setAddRemessas(1)
    else
      setAddRemessas()
  }

  const SetandoLogin = async (event) => {
    if(event.target.value.length > 0){
     setLoadingTime(1)
     const login = await api.get(`/logins/show/${event.target.value}`, config)
     setSelectedLogin(login.data)
     setLogins()
     setLoadingTime()
   } 
  }

  // estornar remessa
  async function VerificandoInputEstorno (event) {
    if(event.key === 'Enter'){
      const RmsSavesArray = RMS.map( item => item.RmsSave)
      const RmsSaves = [].concat(...RmsSavesArray)
      const remessa = (function(){
        const remessa = RmsSaves.filter( item => item.remessa === event.target.value)
        return remessa[0]
      })()
      if(!remessa){
        await SoundErro.play()
        alert('Remessa nao consta nessa Trip!')
        document.querySelector('#inputEstorno').value = ''
        return 
      }
      
      const remessa_estorno_data = {
        rm: remessa.remessa,
        autor: User.name,
        trip: Trip.id
      }
      setLoadingTime(1)
      const ResponseEstorno = await api.post('/trips/estorno/rm', remessa_estorno_data, config)
      if(!ResponseEstorno.data.auth)
        alert(ResponseEstorno.data.message)

      const attTrip = await api.get(`/trips/${Trip.id}`, config)
      if(attTrip.data){
        if(attTrip.data.status !== 'Pending'){
          alert('Trip nao localizada ou com Status diferente de Pending!')
          return (
            history.push('/home')
          )
        }
          
        if(attTrip.data.clients_rms){
          const RMScurrentTrip = attTrip.data.clients_rms.map(item => item)
          setRMS(RMScurrentTrip)
          const RmsArrays = (RMScurrentTrip.map(item => item.rms))
          setTotalRms( [].concat(...RmsArrays).length)
          } 
        setTrip(attTrip.data)
      }
      setLoadingTime(0)
      document.querySelector('#inputEstorno').value = ''
      return
    }
  }

    return(
      <>
        <Menu />
        {Trip && 
        <div className={`container container-EditTrip grid ${CheckBUnitBg ? CheckBUnitBg : ''}`}>
          <h2>Edit Trip</h2>
            <section>
              <p>Courier: <Link to={`/Courier/Show/${Trip.courier_id}`}>{Trip.courier}</Link></p>
              <p>ID Trip: {Trip.id}</p>
            </section>
            <input type="text" id="inputEstorno" placeholder="Remessa + Enter para Estornar" onKeyPress={VerificandoInputEstorno}/>
            <div className="grid LastMile">
              <button className="btn-M btn-addRms" onClick={AllowAddRemessas}>Add + Remessas</button>
              {AddRemessas && <>
              <select name="Clients" id="Clients" onChange={SetandoCliente} className="select">       
                <option value='' >Cliente</option>
                {Clients.map(Clients => (
                <option key={Clients.id} value={Clients.name} >{Clients.name}</option>
                ))}
              </select>
              {SelectedLogin && <span className="span-M">Login: {SelectedLogin.nome}</span>}
              {SelectedLogin && <span className="span-M">CPF: {SelectedLogin.login}</span>}
              {SelectedLogin && <span className="span-M"> Placa: {SelectedLogin.placa} </span>}
              {(!Logins && SelectedClient ) && <select name="type" id="type" onChange={typeRM}>
                <option value='' >Tipo Remessa</option>
                <option value="G">Grande</option>
                <option value="P">Pequena</option>
              </select>}  
              {Logins && Logins.length > 0 &&  <select name="logins" id="logins" onChange={(event) => SetandoLogin(event)}>
                <option value="" key="0">Selecionar Login</option>
                {Logins.map( login => <option key={login.id} value={login.id}> {login.nome} </option>)}
                </select>}
                {Type && !ConfigBipagem && <div className="TipoBipagem grid">
                <button className="grid" onClick={() => setConfigBipagem(1)}>Bipagem Unitario</button>
                <button className="grid" onClick={() => setConfigBipagem(2)}>Bipagem Lote</button>
              </div>}    
              {QtdBipada && <span className="span-M">Quantidade Bipada : {QtdBipada}</span>}
              {StatusUnitRm && <span>{StatusUnitRm}</span>}
              {Type && ConfigBipagem === 2 &&  <textarea name="textarea" id="textarea" value={Txt} onChange={editTxt} onKeyUp={qtdBipada}></textarea>}
              {Type && ConfigBipagem === 1 && <input id="InputRemessa" style={{justifySelf: 'center'}} placeholder="Entre com a Remessa"
              onKeyPress={(event) => event.key === 'Enter' && event.target.value.length > 0 && CheckUnitRemessa(event.target.value.trim().toUpperCase())} />}
              {ClienteLista && <p id="clienteLista">
                Lista: <input type="text" id="spanLista" value={ClienteListaValue} onChange={(event) => setClienteListaValue(event.target.value)} />
              </p>}
              {Type && ConfigBipagem && <button className="btn-M " onClick={addTrip}>Adicionar Remessas</button>}
            </>}
              {!ConfigBipagem && <button className="btn-G" onClick={() => FinishTrip()}>Finalizar Trip</button>}
          </div>    
          <h3>Relação de Remessas: </h3>  
          <div className="box-rms-boxTrip grid">
            {RMS && RMS.map((item) => {
              if(item.rms.length > 0)
              return (
                <div key={`${item.cliente}${item.type}`} className="div-rms-boxTrip grid"> 
                  <p>Cliente: {item.cliente} <button className="btn-Copy" onClick={() => navigator.clipboard.writeText(document.querySelector(`.ul${item.cliente.replace(' ', '-')}${item.type}`).innerText)}><AiFillCopy/></button></p>
                  <p>Quantidade: {item.quantidade}</p>
                  <p>Type: {item.type}</p>
                  {item.Login && <p>Login: {item.Login.nome} </p>}
                  {item.Login && <p>Placa: {item.Login.placa}</p>}
                  {item.lista && (<p>Lista: {item.lista} </p>)}
                  <ul key={`${item.cliente}${item.type}`} className={`ul${(item.cliente).replace(' ', '-')}${item.type}`}>
                    {item.rms.map((item) => {
                      if(Trip.remessas_retornadas || Trip.remessas_finalizadas){
                      if(RmtoArray(Trip.remessas_retornadas).indexOf(item) !== -1)
                      return <li key={item.rm} className="retornada liRetornada">{item}</li>
                      else if(RmtoArray(Trip.remessas_finalizadas).indexOf(item) !== -1)
                      return <li key={item} className="entregue liEntregue">{item}</li>
                      }
                      else
                      return <li key={item} className="liEmRota">{item}</li>
                    })}
                  </ul>
                </div>
              )
              })}
          </div>
      </div>}
      {LoadingTime && <Loading />}
        <Footer />
      </>
    )



}

export default EditTrip