import React , { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'

import api from '../../services/api'
import BuscaToken from '../Components/Functions/BuscaToken.js'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import Modal from './modal.jsx'

const SearchRemessa = () => {
  const [Remessa, setRemessa] = useState({})
  const [MessageModal, setMessageModal] = useState()
  const [MostrarModal, setMostrarModal] = useState(0)
  const [update, setUpdate] = useState(0)

  const history = useHistory()
  const config = BuscaToken()


  useEffect(() => {
  const params = window.location.href.split('remessa/')
    api.get(`/search/rm/${params[1]}`, config)
    .then(r => {
      if(r.data.message){
        alert(JSON.stringify(r.data.message))
        return history.push('/home')
      }
      setRemessa(r.data)
    })
  }, [update])


  function fecharModal () {
    setMostrarModal(0)
  }

  async function ConfirmarBaixa (){
    const ResponseServer = await api.get(`/baixacliente/baixa/${Remessa.rm}`, BuscaToken())
    if(ResponseServer.data === 0){
      alert('Erro ao baixar remessa.')
      return
    }
    const updateRemessa = await api.get(`/search/rm/${Remessa.rm}`, config)
      if(updateRemessa.data.message){
        alert(JSON.stringify(updateRemessa.data.message))
        return history.push('/home')
      }
      setRemessa(updateRemessa.data)

      const data = {
        rm: updateRemessa.data.rm,
        destinatario: updateRemessa.data.recebedor_destinatario,
        nome: updateRemessa.data.recebedor_name,
        documento: updateRemessa.data.recebedor_document,
        parentesco: updateRemessa.data.recebedor_grau,
        user: updateRemessa.data.recebedor_user,
        baixaCliente: updateRemessa.data.baixaCliente,
        finished_at: updateRemessa.data.recebedor_finished_at_json,
        signature: updateRemessa.data.signature
      }

      setMessageModal(data)
      
  }

  const Recebedor = (event) => {
    event.preventDefault()

    const data = {
      rm: Remessa.rm,
      destinatario: Remessa.recebedor_destinatario,
      nome: Remessa.recebedor_name,
      documento: Remessa.recebedor_document,
      parentesco: Remessa.recebedor_grau,
      user: Remessa.recebedor_user,
      baixaCliente: Remessa.baixaCliente,
      finished_at: Remessa.recebedor_finished_at_json,
      signature: Remessa.signature
    }

    setMessageModal(data)
    setMostrarModal(1)
  }

  async function updateStatusRm() {
    const response = await api.get(`/rm/update/${Remessa.id}`, config)

    if(response.data.auth){
      return setUpdate(update + 1)
    }
    return alert(response.data.message)
  }


  return (
    <>
      <Menu />
      <div className="container contentCenter grid">
        <h2>Tracking Remessa <span id="SingleRm">{Remessa.rm} {Remessa.alterTracking && ` - ${Remessa.alterTracking}`} </span>
         <br/><span style={{fontSize: "15pt", color: 'black'}}><span style={{color: 'crimson'}}>ID:</span> {Remessa.id}</span></h2>
        
        {Remessa.endereco && 
          <div style={{
            border: '1px solid black',
            margin: '15px',
            padding: '5px',
            borderRadius: '6px'
          }}>
            <p>Destinatario: <span>{Remessa.destinatario}</span></p>
            <p>Endereço: <span>{Remessa.endereco}</span></p>
            <p>Bairro: <span>{Remessa.bairro}</span></p>
            <p>Cidade: <span>{Remessa.cidade}</span></p>
            <p>Contato: <span>{Remessa.contato}</span></p>
            <p>Prazo: <span>{Remessa.prazo.label}</span></p>
            <p>Status Cliente: <span>{Remessa.status_client ? 'Baixa confirmada' : 'Em aberto'}</span></p>
          </div>
        }  
        
        <table className="tableTrackingHeader">
          <thead>
            <tr>
              <th>Criacao</th>
              <th>Cliente</th>
              <th>Status Atual</th>
              <th>Prazo</th>
              {Remessa.lista && <th>Lista</th> }
              {Remessa.type && <th>Tamanho</th>}
              {Remessa.finished_at_json && <th>Finalizada em</th>}
              {Remessa.login && <th>Login Cliente</th>}
            </tr>
          </thead>
          <tbody>
            <tr>
              {Remessa.created_at_json && <td>{Remessa.created_at_json.dia}/{Remessa.created_at_json.mes}/{Remessa.created_at_json.ano}  {Remessa.created_at_json.hora}:{Remessa.created_at_json.minutos}</td>}
              <td>{Remessa.client}</td>
              <td>{Remessa.status}</td>
              <td>{Remessa.prazo ? Remessa.prazo.label : 'N/D'}</td>
              {Remessa.lista && <td>{Remessa.lista}</td> }
              {Remessa.type && <td>{Remessa.type}</td>}
              {Remessa.finished_at_json && <td> {Remessa.finished_at_json.dia}/{Remessa.finished_at_json.mes}/{Remessa.finished_at_json.ano}  {Remessa.finished_at_json.hora}:{Remessa.finished_at_json.minutos}{Remessa.finished_at_json.segundos && `: ${Remessa.finished_at_json.segundos}`}</td>}
              {Remessa.login && <td>{Remessa.login[0].nome}</td>}
            </tr>
          </tbody>
        </table>
        {Remessa.recebedor_name && <button className="btn-G" onClick={(event) => Recebedor(event)}>Click para dados do Recebedor</button>}
        {Remessa.status === 'Pendencia' && Remessa.pendencia_obs && <span class="span-P" style={{marginTop: "15px", color: 'crimson'}}>Motivo da pendencia: <span style={{color: 'black'}}>{Remessa.pendencia_obs} </span></span>}
        <table className="tableTracking">
          <thead>
            <tr>
              <th>Atualizacao</th>
              <th>Autor</th>
              <th>Movimento</th>
              <th>Trip</th>
              <th>Courier</th>
              {Remessa.status === 'Devolvida' && <th>Devolucao</th>}
              {Remessa.login && <th> Login </th>}
            </tr>
          </thead>            
          <tbody>
      {Remessa.movimentacao && Remessa.movimentacao.map((item) => {
        return (
          <tr key={Math.random()}>
            <td>{item.createdAt}</td>
            <td>{item.autor}</td>
            {item.status === 'Remessa avariada' ? <td><Link to={`/Avarias/Via2/${item.avaria}`} >{item.status}</Link></td> : <td>{item.status}</td>}
            {item.trip && <td><Link to={`/trip/${item.trip}`}>{item.trip} </Link></td>}
            {(!item.trip && item.courier) && <td> </td>}
            {item.devolucao && <td> </td>}
            {item.courier && <td>{item.courier}</td>}
            {item.devolucao && <td> </td>}
            {item.devolucao && <td><Link to={`/devolucao/Via2/${item.devolucao}`}>{item.devolucao}</Link></td>}
            {item.login && <td>{item.login[0].nome}</td> }
          </tr>
          )
      })}
        </tbody>
      </table>
      </div>
      {MessageModal && MostrarModal && <Modal message={MessageModal} fecharModal={fecharModal} ConfirmarBaixa={ConfirmarBaixa}/>}
      <Footer />
    </>
  )
}

export default SearchRemessa