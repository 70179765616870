import React, { useState, useEffect} from 'react'

import { useHistory} from 'react-router-dom'
import api from '../../services/api'

import BuscaToken from '../Components/Functions/BuscaToken.js'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'

const FinanceiroCourier = () => {
  const [Courier, setCourier] = useState()

  const history = useHistory()

  useEffect(() => {
    const params = window.location.href.split('/Courier/Financeiro/')
    api.get(`/courier/${params[1]}`, BuscaToken())
    .then( r => {
      if(r.data.message){
        alert(JSON.stringify(r.data.message))
        return history.push('/')
      }
      setCourier(r.data)
    })
  }, [])


  useEffect(() => {
    if(Courier && Courier.financeiro) {
      document.querySelector('#bancoCourier').value = Courier.financeiro.banco
      document.querySelector('#agenciaBancoCourier').value = Courier.financeiro.agencia
      document.querySelector('#contaBancoCourier').value = Courier.financeiro.conta
      document.querySelector('#tipoContaBancoCourier').value = Courier.financeiro.tipoContaBanco
      document.querySelector('#pixBancoCourier').value = Courier.financeiro.pix
      document.querySelector('#destinatarioBancoCourier').value = Courier.financeiro.destinatario
      document.querySelector('#obsBancoCourier').value = Courier.financeiro.obs
    }
  }, [Courier])

  async function Finalizar () {
      const banco = document.querySelector('#bancoCourier').value
      const agencia = document.querySelector('#agenciaBancoCourier').value
      const conta = document.querySelector('#contaBancoCourier').value
      const tipo = document.querySelector('#tipoContaBancoCourier').value
      const pix = document.querySelector('#pixBancoCourier').value
      const destinatario = document.querySelector('#destinatarioBancoCourier').value
      const obs = document.querySelector('#obsBancoCourier').value

      if(([banco,agencia,conta,tipo,pix,destinatario].filter( item => item.length == 0).length > 0))
        return alert('Dados invalidos.')
      const data = {
                    courier: Courier.id,
                    banco,
                    agencia,
                    conta,
                    tipo,
                    pix,
                    destinatario,
                    obs
      }

      const ResponseServer = await api.post('/courier/financeiro', data, BuscaToken())

      alert(JSON.stringify(ResponseServer.data.message))
      history.push('/Courier')
  }

  return(
    <>
    <Menu />
    {Courier && 
      <div className="container grid contentCenter BorderBox">
        <h2>Financeiro</h2>
        <h3>Courier: {Courier.name}</h3>
        <h3>Dados Bancarios</h3>
          <form className="contentCenter grid">
          <section>
            <label htmlFor="BancoCourier">Banco:</label>
            <input type="text" name="BancoCourier" id="bancoCourier"/>
          </section>
          <section>
            <label htmlFor="agenciaBancoCourier">Agencia:</label>
            <input type="text" name="agenciaBancoCourier" id="agenciaBancoCourier"/>
          </section>
          <section>
            <label htmlFor="contaBancoCourier">Conta:</label>
            <input type="text" name="contaBancoCourier" id="contaBancoCourier"/>
          </section>
          <section>
            <label htmlFor="tipoContaBancoCourier">Tipo de Conta:</label>
            <select name="tipoContaBanco" id="tipoContaBancoCourier" className="BorderBox">
              <option value="">Selecionar</option>
              <option value="Conta Corrente">Conta Corrente</option>
              <option value="Conta Poupanca">Conta Poupanca</option>
              <option value="Conta de Pagamento">Conta de Pagamento</option>
            </select>
          </section>
          <section>
            <label htmlFor="pixBancoCourier">PIX:</label>
            <input type="text" name="pixBancoCourier" id="pixBancoCourier"/>
          </section>
          <section>
            <label htmlFor="destinatarioBancoCourier">Destinatario:</label>
            <input type="text" name="destinatarioBancoCourier" id="destinatarioBancoCourier"/>
          </section>
          <section>
            <label htmlFor="obs">Observacao:</label>
            <input type="text" name="obs" id="obsBancoCourier"/>
          </section>
        </form>
        <button onClick={Finalizar}>Finalizar</button>
      </div>
    }
    <Footer />
    </>
  )
}

export default FinanceiroCourier