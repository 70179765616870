import React, { useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import api from '../../services/api'

import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import BuscaToken from '../Components/Functions/BuscaToken.js'

const ControleEntradas = () => {
  const [Romaneios, setRomaneios] = useState()
  const [CurrentRomaneios, setCurrentRomaneios] = useState()
  const [Clientes, setClientes] = useState()

  useEffect(() => {
    api.get('/clients/actives', BuscaToken()).then(response => {
      setClientes(response.data.clients)
    })
    api.get('/romaneiosEntrada', BuscaToken()).then(r =>{
      const RomaneiosOrdenados = r.data.sort(function compare (a,b) {
        if(a.status < b.status) return -1
        if(a.status > b.status) return 1
        return 0
      })
      setRomaneios(RomaneiosOrdenados)
      setCurrentRomaneios(RomaneiosOrdenados)
    } )
  }, [])


  function filtro (event) {
    if(event.target.value.length > 0){
      setCurrentRomaneios(Romaneios.filter(item => item.cliente === event.target.value))    
    }
    else{
      setCurrentRomaneios(Romaneios)
    }
  }

  return(
    <>
    <Menu />
    <div className="container container-cEntradas grid">
      <h2>Controle Entradas Romaneios</h2>
      <div style={{textAlign: 'center'}}>
        <label htmlFor="filtro" style={{display: "inline"}}>Filtro por Clientes </label>
        <select name="clientes" id="clientes" style={{display: 'inline', fontSize: "15pt", backgroundColor: "coral"}} onChange={(event) => filtro(event)}>
          <option value="">Todos</option>
          {Clientes && Clientes.map( cliente => <option value={cliente.name} key={cliente.id}>{cliente.name}</option>)}
        </select>
      </div>
      {CurrentRomaneios && <span className="span-M">Total em exibicao : {CurrentRomaneios.length}</span>}
      <table className="tableProduction">
        <thead>
          <tr>
            <th>Romaneio</th>
            <th>Manifesto Cliente</th>
            <th>Cliente</th>
            <th>Quantidade</th>
            <th>Criado em</th>
            <th>Porcentagem</th>
          </tr>
        </thead>
        <tbody>
          {CurrentRomaneios && CurrentRomaneios.map( romaneio => 
            <tr key={romaneio.romaneio}>
              <td><Link to={`/ControleEntradas/romaneio/${romaneio.romaneio}`}>{romaneio.romaneio}</Link></td>
              <td>{romaneio.ManifestoCliente ? romaneio.ManifestoCliente : 'N/D'}</td>
              <td>{romaneio.cliente}</td>
              <td>{romaneio.quantidade}</td>
              <td>{romaneio.created_at_json.dia} / {romaneio.created_at_json.mes} / {romaneio.created_at_json.ano} 
                {' '} {romaneio.created_at_json.hora}:{romaneio.created_at_json.minutos}
              </td>
              <td style={romaneio.porcentagem === '100.00'? {color: "green"} : {color: 'red'}}>{romaneio.porcentagem}</td>
            </tr>
            )}
        </tbody>
      </table>
    </div>
    <Footer />
    </>
  )
}

export default ControleEntradas