import React, { useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import api from '../../services/api'

import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import BuscaToken from '../Components/Functions/BuscaToken.js'
import Loading from '../Components/Loading'


const ClientesLogins = () => {
  const [Logins, setLogins] = useState()
  const [AllLogins, setAllLogins] = useState()
  const [LoadingTime, setLoadingTime] = useState()
  const [Clientes, setClientes] = useState()
  const [Permissao, setPermissao] = useState()
  const [FiltroUso, setFiltroUso] = useState(0)

  useEffect(() => {
    api.get('/users/CheckPermission', BuscaToken()).then( r => setPermissao(r.data.Permissao))
    api.get('/logins/index', BuscaToken()).then(r => { setLogins(r.data); setAllLogins(r.data) })
    api.get('/clients', BuscaToken()).then( r => {
      const clientes = r.data.clients.filter( c => c.useapp === "1")
      setClientes(clientes)
    })
  }, [])

  const ExcluirLogin = async (login) => {
    const confirmacao = prompt('Digite CONFIRMACAO para concluir a exclusao')
    if(confirmacao === 'CONFIRMACAO'){
      setLoadingTime(1)
      const ServerResponse = await api.get(`/logins/remove/${login}`, BuscaToken())
      setLoadingTime()
      alert(JSON.stringify(ServerResponse.data.message))
      window.location.reload()
    }
    else
      alert('Campo de confirmacao invalido! Operacao abortada.')
  }

  const filtro = (event) => {
    if(event.target.value.length > 0){
      const LoginsFiltrados = AllLogins.filter( login => login.cliente === event.target.value)
      setLogins(LoginsFiltrados)
    }
    else {
      setLogins(AllLogins)
    }
  }

  const AlteraFiltro = (event) => {
    setFiltroUso(FiltroUso + 1)
    
      if(FiltroUso % 2 == 0){
        setLogins(Logins.slice(0).sort((a,b) => {
        if (a.status > b.status) {
          return -1;
        }
        if (a.status < b.status) {
          return 1;
        }
        // a deve ser igual a b
        return 0;
      } ))
    }
      else{
        setLogins(Logins.slice(0).sort((a,b) => {
          if (a.status < b.status) return -1
          if (a.status > b.status) return 1
          return 0;
        }))
      }
  }


  return (
    <>
      <Menu />
      <div className="container container-Logins grid">
        <h2>Controle de Logins</h2>
      <div style={{textAlign: "center"}}>
      <label htmlFor="filtro" style={{display: "inline"}}>Filtro por Clientes </label>
      <select name="clientes" id="clientes" style={{display: 'inline', fontSize: "15pt", backgroundColor: "coral"}} onChange={(event) => filtro(event)}>
        <option value="">Todos</option>
        {Clientes && Clientes.map( cliente => <option value={cliente.name} key={cliente.name}>{cliente.name}</option>)}
      </select>
      <span className="span-M ativo" >Logins em exibicao: {Logins && Logins.length}</span>
      </div>
      <div>
        <table className="tableProduction" style={{fontSize: "13pt"}}>
          <thead>
            <tr>
              <th onClick={(event) => AlteraFiltro(event)}>Cliente</th>
              <th onClick={(event) => AlteraFiltro(event)}>Nome</th>
              <th onClick={(event) => AlteraFiltro(event)}>Login</th>
              <th onClick={(event) => AlteraFiltro(event)}>Senha</th>
              <th onClick={(event) => AlteraFiltro(event)}>Placa</th>
              <th onClick={(event) => AlteraFiltro(event)}>Status</th>
              {(Permissao === 'A' || Permissao === 'M') &&<th onClick={(event) => AlteraFiltro(event)}>Excluir</th> }
            </tr>
          </thead>
          <tbody>
            {Logins && Logins.map( login => 
              <tr key={login.id}>
                <td>{login.cliente}</td>
                <td>{login.nome}</td>
                <td>{login.login}</td>
                <td>{login.senha}</td>
                <td>{login.placa}</td>
                {login.status === 1 ? <td style={{color: "green"}}>Liberado</td> : 
                <td><Link className="td-login-busy" to={`/trip/${login.status}`}>
                  Em utilizacao - {login.status}</Link></td> }
                {(Permissao === 'A' || Permissao === 'M') && <td><button className="btn-P" onClick={() => ExcluirLogin(login.id)}>Excluir</button></td>}
              </tr>
              )}
          </tbody>
        </table>
      </div>
      </div>  
      {LoadingTime && <Loading />}
      <Footer />
    </>
  )
}

export default ClientesLogins