import React, { useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import api from '../../services/api'


import BuscaToken from '../Components/Functions/BuscaToken.js'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'

const DeleteRemessa = () => {
  const [LoadingTime, setLoadingTime] = useState()
  const [Remessa, setRemessa] = useState()
  const [User, setUser] = useState()
  
  useEffect(() => {
    api.get('/users/show', BuscaToken())
    .then( r => {
      if(!r.data.message){
        setUser(r.data.name)
      }
    })
  }, [])


  async function Finish () {
    if(!Remessa)
      return false

    const data = {
      id: Remessa,
      autor: User
    }
    const ResponseServer = await api.post('/deleteRemessa', data, BuscaToken())

    alert(ResponseServer.data.message)
    window.location.reload()
  }


  return(
    <>
    <Menu />
    <div className="container contentCenter grid">
      <h2>Excluir remessa</h2>
      
      <input type="text" id="remessa" style={{width: "100%"}} placeholder="Entre com a remessa"
       onKeyPress={(event) => setRemessa(event.target.value) }/>
      {Remessa && <button onClick={Finish}> Finalizar Exclusao</button>}
      </div>
    <Footer />
    </>
  )
}

export default DeleteRemessa