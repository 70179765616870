import React, { useState, useEffect} from 'react'
import { Link } from 'react-router-dom'


import api from '../../services/api'
import BuscaToken from '../Components/Functions/BuscaToken.js'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'

const Pendencia = () => {
  const [Remessas, setRemessas] = useState()
  const [CurrentRemessas, setCurrentRemessas] = useState()
  const [Clientes, setClientes] = useState()

  const config = BuscaToken()

  useEffect(() => { 
    api.get('/pendencia/index', config)
    .then( r => {
       setRemessas(r.data)
       setCurrentRemessas(r.data)
      })
      api.get('/clients/actives', config).then(response => {
        setClientes(response.data.clients)
      })
  }, [])

  const filtro = (event) => {
    if(event.target.value.length > 0){
      setCurrentRemessas(Remessas.filter(item => item.client === event.target.value))
      return    
    }
    else{
      setCurrentRemessas(Remessas)
      return
    }
  }


  return (
    <>
      <Menu />
      <div className="container contentCenter container-Pendencias grid">
        <h2>Painel de Pendencias</h2>
        <div>
          <label htmlFor="filtro" style={{display: 'inline'}}>Filtro por Clientes </label>
            <select name="clientes" id="clientes" className="bg-Coral"  onChange={(event) => filtro(event)}>
              <option value="">Todos</option>
              {Clientes && Clientes.map( cliente => <option value={cliente.name} key={cliente.id}>{cliente.name}</option>)}
            </select>
        </div>
        <span className="span-M">Total em exibicao: {CurrentRemessas && CurrentRemessas.length}</span>
      <table className="tableProduction">
        <thead>
          <tr>
            <th>Remessa</th>
            <th>Cliente</th>
            <th>Pendenciada em:</th>
            <th>Observacao</th>
          </tr>
        </thead>
        <tbody>
          {CurrentRemessas && CurrentRemessas.map( remessa => 
            <tr key={remessa.remessa}>
              <td><Link to={`/search/remessa/${remessa.rm}`}>{remessa.rm}</Link></td>
              <td>{remessa.client}</td>
              <td>{remessa.movimentacao}</td>
              <td>{remessa.pendencia_obs && remessa.pendencia_obs}</td>
            </tr>
            )}
        </tbody>
      </table>
      </div>
      <Footer />
    </>
  )
}

export default Pendencia