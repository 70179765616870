import React, { useEffect, useState} from 'react'

import api from '../../services/api'

import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import BuscaToken from '../Components/Functions/BuscaToken.js'

const EmAberto = () => {
  const [Requisicoes, setRequisicoes] = useState()
  const [RequisicoesTotais, setRequisicoesTotais] = useState()
  const [Permissao, setPermissao] = useState()

  const config = BuscaToken()

  useEffect(() => {
    api.get('/requisicao/index', config).then( r => {
      setRequisicoes(r.data[0])
      setRequisicoesTotais(r.data[1])
    })
    api.get('/users/CheckPermission', config).then( r => setPermissao(r.data.Permissao))
  }, [])

  return (
    <>
      <Menu />
      <div className="container contentCenter grid">
        <h2>Requisicoes</h2>
        <h3>Requisicoes Totais</h3>
        <ul className="ulRequisicoesTotais contentCenter BorderBox">
          {RequisicoesTotais && RequisicoesTotais.map((item) => <li key={item.valor}>Valor: R${item.valor},00 # Quantidade: {item.quantidade}</li>)}
        </ul>

        {Permissao === 'A' && 
        <div className="contentCenter grid"> 
          <h3>Requisicoes em Aberto</h3>
          <table className="boxProduction">
            <thead>
              <tr>
                <th>Entrada</th>
                <th>Autor</th>
                <th>Requisicao</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              {Requisicoes && Requisicoes.map((requisicao) => {
                const data = JSON.parse(requisicao.created_at_json)
                return (
                <tr key={requisicao.requisicao}>
                  <td>{data.dia}/{data.mes}/{data.ano}</td>
                  <td>{requisicao.autor}</td>
                  <td>{requisicao.requisicao}</td>
                  <td>R$ {requisicao.valor},00</td>
                </tr>
              )})}
            </tbody>
          </table>
        </div>}
      </div>
      <Footer />
    </>
  )
}

export default EmAberto