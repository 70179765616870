import React from 'react'
import { useHistory} from 'react-router-dom'
import api from '../../services/api'

import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import Loading from '../Components/Loading'
import { useEffect } from 'react'
import { useState } from 'react'


const ChangePassAll = () => {
  const [Users, setUsers] = useState([])
  const [MostrarModal, setMostrarModal] = useState()
  const [SelectedUser, setSelectedUser] = useState()
  const [LoadingTime, setLoadingTime] = useState()

  const history = useHistory()

  const PERMISSOES = [{sigla: "A", permissao: 'Admin'},{sigla: "C", permissao: 'Courier'}
  ,{sigla: "S", permissao: 'Support'},{sigla: "M", permissao: 'Manager'}]


  function ChecandoPermissao (sigla) {
    const permissao = PERMISSOES.filter((permissao) => {
      if(permissao.sigla === sigla)
        return permissao.permissao
    })

    return permissao[0].permissao
  }

  const token = localStorage.getItem('token')
    const config ={
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }

    async function UpdatePass (event){
      if(event.key === 'Enter'){
      
        const data = {
          senha: event.target.value,
          user: SelectedUser
        }
        event.preventDefault()
        setLoadingTime(1)
        const passUpdated = await api.post('/users/changepassAsAdmin', data, config)
        setLoadingTime(0)
        alert(JSON.stringify(passUpdated.data.message))
        return history.push('/home')
      }
    }


    useEffect(()=> {
      api.get('/users/index', config).then(r => {
        setUsers(r.data)
      })
    }, [])

    function changePass(user){
      setMostrarModal(1)
      setSelectedUser(user)
    }


    function fechaModal() {
      if(MostrarModal === 1)
        setMostrarModal(0)
    }

  return (
    <>
      <Menu />
      <div className="container grid contentCenter" >
        <h2>Alterar Senhas</h2>
        <table className="boxProduction">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nome</th>
              <th>Login</th>
              <th>Permissao</th>
              <th>Alterar Senha</th>
            </tr>
          </thead>
            <tbody>
              {Users.map((user) => {
                return (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.name}</td>
                    <td>{user.login}</td>
                    <td>{ChecandoPermissao(user.permissao)}</td>
                    <td><a style={{cursor: "pointer"}} onClick={() => changePass(user.id)}>Alterar Senha</a></td>
                  </tr>
                )
              })}
            </tbody>
        </table> 

      </div>
        <div id="modal-changepass" className={`${MostrarModal && "mostrar"} modal`}>
            <div className="modal-interno">
              <button onClick={fechaModal}>X</button>
              <h3>Change Password</h3>
                <form>
                  <input type="text" placeholder="Digite a nova senha." onKeyPress={UpdatePass}/>
                </form>
            </div>
          </div>
          {LoadingTime && <Loading />}
        <Footer />
    </>
  )
}

export default ChangePassAll