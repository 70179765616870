import React, { useState, useEffect} from 'react'

import { AiFillCopy } from 'react-icons/ai'

const Modal = ({message}) => {
  const [Mostrar, setMostrar] = useState(1)

  function fecharModal (event) {
    setMostrar(0)
  }


  return (
    <div className={`modal ${Mostrar && 'mostrar'}`}>
      <div className="modal-interno">
        <button onClick={fecharModal} className="btn-M btn-closeModal">Fechar</button>
        <div>
          <section className="section-modal BorderBox">
            <p> Voce tem {message.length} viagem em aberto </p> 
            {message && message.map( trip => {
                if(trip.logins){
                  return <p>Trip: {trip.id} <br/> Logins Relacionados a viagem: <br/> <br/>
                  {trip.logins.map(login => {
                    return <span>Cliente: <span>{login.cliente}</span> <br/> 
                    login: <span>{login.login}</span> <button className="btn-Copy" onClick={() => navigator.clipboard.writeText(login.login)}><AiFillCopy/></button> <br/> 
                    senha: <span>{login.senha}</span> <button className="btn-Copy" onClick={() => navigator.clipboard.writeText(login.senha)}><AiFillCopy/></button> <br/> <br/>
                    app: <span>{login.app}</span> <br/><br/></span>
                  })}
                  </p>
              }
              })} 
          </section>
        </div>
      </div>
    </div>
  )
}

export default Modal