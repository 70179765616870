import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import api from '../../services/api'

import BuscaToken from '../Components/Functions/BuscaToken.js'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'

import Paper from '@material-ui/core/Paper';

import moment from 'moment'


const ShowSync = () => {
  const [data, setData] = useState()


  const history = useHistory()

  useEffect(() => {
    const params = window.location.href.split('show/')
    api.get(`/syncs/show/${params[1]}`, BuscaToken())
    .then( r => {
      if(!r.data.auth){
        alert(r.data.message)
        return history.push('/')
      }
      setData(r.data.data)
    })
  }, [])



return  (
  <>
    <Menu />
    <div className="container container-home grid">
      <h2>Visualização sincronização</h2>
      {data && 
        <Paper style={{ padding: '10px', margin: '20px' }} elevation={5}>
          <h4>Id de sincronismo: <span style={{ color: 'crimson'}}>{data.id}</span></h4>
          <br />
          <h5>Status: <span style={{ color: 'crimson'}}>{data.status}</span> </h5>
          <br/>
          <p>Iniciado em: <span>{data.initializedAt}</span></p>
          <p>Finalizado em: <span>{data.finishedAt}</span></p>
          <p>Trips: <span>{data.trips.message}</span></p>
          {data.trips.tripsUpdated.length > 0 &&
            <div style={{ border: '1px solid black', width: '200px', margin: '0 auto'}}>
              <ul>
                {data.trips.tripsUpdated.map( item => <li><Link to={`/trip/${item}`}>{item}</Link> </li>)}
              </ul>
            </div>
          }
          <br />
          {data.syncs.map(item => 
            <>
              <div 
                style={{
                  height: 'fit-content',
                  border: '1px solid black',
                  width: 'fit-content',
                  margin: '0 auto',
                  padding: '10px',
                  marginBottom: '20px'
                }}
              >
                <h4>ID do relatório: <span style={{ color: 'crimson'}}>##</span></h4>
                <h4>Cliente:  <span style={{ color: 'crimson'}}>{item.client}</span></h4>
                <h4>Status:  <span style={{ color: 'crimson'}}>{item.message}</span></h4>
                            
                {item.errors?.length > 0 &&        
                  <div style={{ margin: '10px 0 10px 0'}}>     
                    <h5>Relação de remessas com erro de sincronismo</h5>
                    <div style={{ border: '1px solid black', marginTop: '10px'}}>
                      {item.errors.map( item => 
                        <Link
                          style={{
                            display: 'block'
                          }}
                          to={`/search/remessa/${item}`}
                          target="_blank"
                        >
                          {item}
                        </Link>
                      )}
                    </div>
                  </div>  
                }
              </div>
            </>
          )}
        </Paper>    
          
      }
        
    </div>
    <Footer />
  </>
)

}

export default ShowSync