import React, { useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import api from '../../services/api'

import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import BuscaToken from '../Components/Functions/BuscaToken.js'



const ShowRomaneioEntrada = () => {
  const [Romaneio, setRomaneio] = useState()

  const history = useHistory()

  useEffect(() => {
    const params = window.location.href.split('/ControleEntradas/romaneio/')
    api.get(`/romaneios/show/${params[1]}`, BuscaToken()).then( r=> {
      if(r.data.message){
        alert(r.data.message)
        return history.push('/ControleEntradas')
      }
      setRomaneio(r.data)

    })
  }, [])

  return (
    <>
    <Menu />
    <div className="container grid">
      <h2>Show Romaneio</h2>
      <div className="showRomaneioEntrada contentCenter grid">
        <section className="grid">
          {Romaneio &&
          <div className="grid detailsRomaneioEntrada BorderBox"> 
            <span>Romaneio: <span className="black">{Romaneio.romaneio}</span></span>
            {Romaneio.ManifestoCliente && <span>Manifesto Cliente: <span className="black">{Romaneio.ManifestoCliente}</span></span>}
            <span>Quantidade: <span> {Romaneio.quantidade} </span></span>
            <span>Porcentagem: <span>{Romaneio.porcentagem}%</span></span>
            <span>Cliente: <span>{Romaneio.cliente}</span></span>
            <span>Criado em: <span>{Romaneio.created_at_json.dia} / {Romaneio.created_at_json.mes} / {Romaneio.created_at_json.ano}</span></span>
            <span><span>{Romaneio.remessas.remessasFinalizadas.length}</span> {Romaneio.remessas.remessasFinalizadas.length > 1 ? 'remessas' : 'remessa'} Finalizadas</span>
            <span><span>{Romaneio.remessas.remessasEmAberto.length}</span> {Romaneio.remessas.remessasEmAberto.length > 1 ? 'remessas' : 'remessa'} Em Aberto</span>
            <span><span>{Romaneio.remessas.remessasDevolvidas.length}</span> {Romaneio.remessas.remessasDevolvidas.length > 1 ? 'remessas' : 'remessa'} Devolvidas</span>
          </div>
          }
        </section>
        <section className={`grid romaneio-remessas columns-3`}>
          <div className="grid divRmsControleEntradas BorderBox">
            <h3>Remessas Finalizadas</h3>
            <br/>
            {Romaneio && Romaneio.remessas.remessasFinalizadas.map(remessa => 
              <span key={remessa.rm}>{remessa.rm} : {remessa.status}</span>
              )}
             
          </div>
          {Romaneio && Romaneio.remessas.remessasEmAberto.length > 0 &&
          <div className="grid divRmsControleEntradas BorderBox">
              <h3>Remessas em Aberto</h3>
            <br/>
            {Romaneio && Romaneio.remessas.remessasEmAberto.map(remessa => 
              <span key={remessa.rm}>{remessa.rm} : {remessa.status} </span>
              )}
          </div> 
          } 
          {Romaneio && Romaneio.remessas.remessasDevolvidas.length > 0 &&
          <div className="grid divRmsControleEntradas BorderBox">
              <h3>Remessas Devolvidas</h3>
            <br/>
            {Romaneio && Romaneio.remessas.remessasDevolvidas.map(remessa => 
              <span key={remessa.rm}>{remessa.rm} : {remessa.status} </span>
              )}
          </div> 
          } 
        </section>
      </div>
    </div>
    <Footer />
    </>
  )
}

export default ShowRomaneioEntrada