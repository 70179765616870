import React from 'react'
import { useHistory} from 'react-router-dom'
import api from '../../services/api'

import BuscaToken from '../Components/Functions/BuscaToken.js'

import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import { useEffect } from 'react'
import { useState } from 'react'


const ChangePass = () => {
  const [User, setUser] = useState({})

  const config = BuscaToken()

  useEffect(()=> {
    api.get('/users/show', config).then( r => {
      if(!r.data.message)
        setUser(r.data)
    })

  }, [])
  


  const history = useHistory()

  async function Changepass () {
    const SenhaAtual = document.getElementById('senhaAtualUser').value
    const novaSenha1 = document.getElementById('senhaNova1User').value
    const novaSenha2 = document.getElementById('senhaNova2User').value

    if(SenhaAtual === novaSenha1 || SenhaAtual === novaSenha2)
      return alert('A nova senha nao pode ser a Atual!')
    if(novaSenha1 !== novaSenha2)
      return alert('Nova senha nao sao iguais!')
    if(SenhaAtual.length < 4 || novaSenha1.length < 1 || novaSenha2.length < 4)
      return alert('Campos Invalidos!')

      
      const data = {
        User: User.id,
        SenhaAtual,
        SenhaNova: novaSenha2
      }
      
      const responseChangePass = await api.post('/users/changepass', data, config)
      alert(JSON.stringify(responseChangePass.data.message))
      localStorage.removeItem('token')
      return setTimeout(function(){return history.push('/home')}, 1000)
  }



  return (
    <>
      <Menu />
      <div className="container grid">
        <h3>Atualização de senha </h3>
        <form action="submit">
          <label htmlFor="senhaAtualUser">Senha Atual:</label>
          <input type="password" name="senhaAtualUser" id="senhaAtualUser"/>
          <label htmlFor="senhaNova1User">Nova Senha:</label>
          <input type="password" name="senhaNova1User" id="senhaNova1User"/>
          <label htmlFor="senhaNova2User">Confirme a nova Senha:</label>
          <input type="password" name="senhaNova2User" id="senhaNova2User"/>
        </form>
        <button className="btncreateCourier" onClick={Changepass}>Alterar Senha</button>

      </div>
        <Footer />
    </>
  )
}

export default ChangePass