import React, { useEffect, useState} from 'react'
import api from '../../../services/api'

import { useHistory } from 'react-router-dom'

const EditTrip = ({trip}) => {
  const [Trip, setTrip] = useState()
  const [Courier, setCourier] = useState()

  const history = useHistory()

  const token = localStorage.getItem('token')
      const config ={
        headers: {
          Authorization: token,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }

  useEffect(()=> {
    api.get(`trips/${trip}`, config).then(r =>{
        setTrip(r.data) 
    })
  },[])

  function SelectStatus (status) {
    const AllStatus = ['Finished', 'Integracao', "Pending", "Excluida"]
    const r = AllStatus.filter(s => status !== s)
    return r
  }

  async function ChangeTrip (event) {
    const status = event.target.value
    const data = {
      trip: Trip.id,
      status
    }

    const result = await api.post('/trips/changeStatus', data, config)
    alert(JSON.stringify(result.data.message))
    history.push('/')
  }

  return (
    <>
      <table className="tableProduction">
        <thead>
          <tr>
            <th>ID</th>
            <th>Bipador</th>
            <th>Courier</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>
            {Trip && (
              <tr key={Trip.id}>
                <td>{Trip.id}</td>
                <td>{Trip.agente}</td>
                <td>{Trip.courier}</td>
                <td><select name="status" id="status" onChange={ChangeTrip}>
                      <option value={Trip.status}>{Trip.status}</option>
                      {SelectStatus(Trip.status).map(r => 
                        <option value={r} key={r}>{r}</option>
                        )}
                    </select></td>
              </tr>
            )}
          </tbody>  
      </table>
    </>
  )
}


export default EditTrip
