


function BuscaToken () {
  const token = localStorage.getItem('token')
      const config ={
        headers: {
          Authorization: token,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
      
  return config    
}

export default BuscaToken