import React, { useState, useEffect } from 'react'
import api from '../../services/api'
import { useHistory, Link } from 'react-router-dom'

import duplicity from '../Medias/duplicity.wav'
import erro from '../Medias/Erro.wav'
import Sucess from '../Medias/Sucess.wav'

import { LinearProgress } from '@material-ui/core'

import { AiFillCopy } from 'react-icons/ai'
import { IoMdCheckmark } from 'react-icons/io'
import { TiInputChecked } from 'react-icons/ti'

import BuscaToken from '../Components/Functions/BuscaToken.js'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import ToFinish from '../Components/ToFinish'
import ProducaoTrip from '../Components/Production/ProducaoTrip'
import Loading from '../Components/Loading'
import RmtoArray from '../Components/Functions/RmtoArray.js'
import PdfTransferencia from './PdfTransferencia'


const TRIP = () =>{
  const [Hidden, setHidden] = useState()
  const [Trip, setTrip] = useState()
  const [Retornos, setRetornos] = useState(0)
  const [TxtRetornos, setTxtRetornos] = useState('')
  const [QtdRetornos, setQtdRetornos] = useState(0)
  const [Finished, setFinished] = useState(0)
  const [Integracao, setIntegracao] = useState(0)
  const [User, setUser] = useState()
  const [LoadingTime, setLoadingTime] = useState()
  const [Data, setData] = useState()
  const [MostraPdf, setMostraPdf] = useState()
  const [SoundSucess, setSoundSucess] = useState()
  const [SoundErro, setSoundErro] = useState()
  const [SoundDuplicity, setSoundDuplicity] = useState()

  const config = BuscaToken()
  if(!SoundSucess){
    setSoundSucess(new Audio(Sucess))
  } 
  if(!SoundErro){
    setSoundErro(new Audio(erro))
  }
  if(!SoundDuplicity){
    setSoundDuplicity(new Audio(duplicity))
  }
  const history = useHistory()

  useEffect(() => {
    if(MostraPdf === 1){
      window.print()
      window.location.reload()
    } 
  }, [MostraPdf])

  useEffect(() => {
    api.get('/users/show', config)
        .then( r => {
          if(!r.data.message){
            setUser(r.data)
          }
        }, [])


    const params = window.location.href.split('trip/')
    
    api.get(`/trips/${params[1]}`, config).then(r => {
      if(r.data.message){
        alert('Viagem nao Localizada!') 
        return history.push('/home') 
      }
      setTrip(r.data)
      if(r.data.status){
        if(r.data.status === 'Finished')
        setFinished(1)
        if(r.data.status === 'Integracao'){
          setFinished(1)
          setIntegracao(1)
        }   
      }

    })
  }, [])

  async function putTrip () {
    let TxtRm = TxtRetornos
      TxtRm = TxtRm.split('\n')
    TxtRm = TxtRm.filter(item => item.length > 0)
    const rmsRetornadas = TxtRm
    const RmsFinalizadas = RmtoArray(Trip.remessas_saida).filter(rm => (rmsRetornadas.indexOf(rm) === -1))
    const RmsFinalizadasClients = RmsFinalizadas.filter((RM) => {
      const RMSerialized = Trip.clients_rms.filter((item) => {
        if(item.rms.indexOf(RM) !== -1){
          return {
            remessa: RM,
            cliente: item.cliente,
            type: item.type
          }
        }
      })
      return RMSerialized
    })
    const tripFinished = {
      conferente: User.name,
      trip: Trip.id,
      remessas_finalizadas: RmsFinalizadas,
      remessas_retornadas: rmsRetornadas,
    }
    setLoadingTime(1)
    const Finish = await api.post('/trips/integracao', tripFinished, config)
    setLoadingTime(0)
    return Finish.data

  }

   async function FinishTrip() {
    if(TxtRetornos.length === 0){
        alert(JSON.stringify((await putTrip()).message))
       return history.push('/tripsIntegracao') 
    }  
    let TxtRm = TxtRetornos.split('\n')
    TxtRm = TxtRm.filter(item => item.length > 0)
    const RmTrip = RmtoArray(Trip.remessas_saida)
    let authFinish = 0
    TxtRm.map(item => {
      if(RmTrip.indexOf(item) === -1)
        authFinish = 1
    })
    if(authFinish === 1)
    return alert(`Remessas retornadas nao batem com remessas da Trip! Impossivel finalizar Trip!`)
    else{
      alert(JSON.stringify((await putTrip()).message))
      return history.push('/tripsIntegracao') 
    }
  }

  function InserirRetornos(){
    setRetornos(1)
  }

  function VerificandoTxt (event) {
    if(event.key === 'Enter'){
      let TxtRm = TxtRetornos.replace(/\$/g, '')
      setTxtRetornos(TxtRm)
      TxtRm = TxtRm.split('\n')
      TxtRm = TxtRm.filter(item => item.length > 0)
      setQtdRetornos(TxtRm.length)
      const RmTrip = RmtoArray(Trip.remessas_saida)
      TxtRm.map(async (item,index) => {
        if(TxtRm.indexOf(item) !== index) {
          const NewTxt = TxtRm.filter((item,i) => {
            if(i !== index)
              return item
          })
          await SoundDuplicity.play()
          const stringNewTxt = NewTxt.toString()
          let NewTxtSerialized = stringNewTxt.replace(/,/g, '\n').replace(/\$/g, '')
              NewTxtSerialized += '\n'
          setTxtRetornos(NewTxtSerialized)
          setQtdRetornos(NewTxt.length) 
          
        } 
      })
      TxtRm.map(async item => {
        if(RmTrip.indexOf(item) === -1){
          await SoundErro.play()
          return alert(`${item} nao consta nessa Trip!`)
        }
          await SoundSucess.play()
        return item
        })
    }
  }

  const setTRetornos = (event) => {
    setTxtRetornos(event.target.value)
  }


  async function IntegrarTrip () {
    const request = { trip: Trip.id, integrador: User.name }
    setLoadingTime(1)
    const Integrado = await api.post('/trips/finish', request, config)
    setLoadingTime(0)
    alert(JSON.stringify(Integrado.data.message))
    return history.push('/home')
  }

  function ControleTransferencia () {
    const ClientesResumo = (function(){
      const clientes_rms = Trip.clients_rms.map( item => {
        return {Cliente: item.cliente, Quantidade: item.quantidade, Type: item.type, lista: item.lista }
      })
      
      if(clientes_rms.length == 1)
        return clientes_rms
      
      let clientes = clientes_rms.map( item => item.Cliente)
      clientes = clientes.filter( (item, index) => clientes.indexOf(item) === index)

      clientes = clientes.map( item => { return {Cliente: item, Quantidade: 0} })

      clientes_rms.map( item => {
        clientes = clientes.map( cliente => {
          if(cliente.Cliente === item.Cliente){
            return {
              Cliente: cliente.Cliente,
              Quantidade: cliente.Quantidade + item.Quantidade
            }
          }
          return cliente
        })
      })
      return clientes      
    })()

    setData( {
      Remessas: ClientesResumo,
      Courier: Trip.courier,
      Conferente: Trip.agente,
      Data: Trip.created_at_json,
      Quantidade: (function() {
        let Qtd = 0
        ClientesResumo.forEach( item => {
          Qtd += item.Quantidade
        })
        return Qtd
      })(),
      ID: Trip.id,
      Logins: Trip.logins ? JSON.parse(Trip.logins).filter( login => login.cliente === 'Direct Log') : []
    })
    setMostraPdf(1)
    setHidden(1)
  }
      
    async function cargoOverflow() {
      setLoadingTime(1)
      const createOrder = await api.post('/overflows/create', {trip: Trip.id}, BuscaToken())
      if(!createOrder.data.auth){
        return alert(createOrder.data.message)
      }
      alert(createOrder.data.message)
      setLoadingTime()
      window.location.reload()
    } 

        return (
        <>
        {Hidden !== 1 &&
          <>
          <Menu />
          {Trip && 
          <div className="container grid">
            <h2>Show Trip</h2>
            <button className={`${Integracao ? 'ativo' : 'inativo'} btn-G`} onClick={IntegrarTrip}>Finalizar Integracao!</button>
            {Finished === 0 ? (<button className="btn-M" onClick={() => history.push(`/EditTrip/${Trip.id}`)}>Editar Trip</button>) : ''}
            <button onClick={ControleTransferencia}>Imprimir Controle de Transferencia</button>
            <section className="details-Trip grid">
              <p>Numero da viagem: {Trip.id} </p>
              <p>Status da Viagem: {Trip.status}</p>
              {Trip.observacao && <p>Observacao: <span>{Trip.observacao}</span></p>}
              <p>Bipador: {Trip.agente}</p>
              <p>Courier: <Link to={`/Courier/Show/${Trip.courier_id}`}>{Trip.courier}</Link></p>
              <p>Data Criacao: {`${Trip.created_at_json.dia} de ${Trip.created_at_json.mes} / ${Trip.created_at_json.ano}
              Hora: ${Trip.created_at_json.hora}: ${Trip.created_at_json.minutos}`}</p>
              { Trip.finished_at_json &&
              <p>Data Finalizacao: {`${Trip.finished_at_json.dia} de ${Trip.finished_at_json.mes} / ${Trip.finished_at_json.ano}
              Hora: ${Trip.finished_at_json.hora}: ${Trip.finished_at_json.minutos}`}</p>}
              {Trip.conferente && <p>Conferente: {Trip.conferente}</p>}
              {Trip.integracao && <p>Viagem Integrada em: {Trip.integracao.data.dia} de {Trip.integracao.data.mes} / {Trip.integracao.data.ano}   Hora: {Trip.integracao.data.hora}:{Trip.integracao.data.minutos} Autor: {Trip.integracao.integrador} </p>}
              <p>Total remessas: {RmtoArray(Trip.remessas_saida).length}</p>
              {Trip.overflow ? <span className="span-M" style={{color: 'crimson', margin: '10px'}}>Ordem de transbordo criada, autor: {Trip.overflow.autor}</span> : <button onClick={cargoOverflow}>Emitir ordem de Transbordo</button>}
              <ProducaoTrip Trip={Trip}/>
              <p>Progresso: {Trip.progress}%</p>
              <LinearProgress 
                variant="determinate" 
                value={Trip.progress}
                color="secondary"
              />
            </section>
            <h3>Relação de Remessas: </h3>
            <div className="box-rms-boxTrip grid">
              {Trip.clients_rms_show.map((item) => {
                if(item.rms.length > 0)
                return (
                  <div key={`${item.cliente}${item.type}`} className="div-rms-boxTrip grid">
                    <p>Cliente: {item.cliente} <button className="btn-Copy" onClick={() => navigator.clipboard.writeText(document.querySelector(`.ul${item.cliente.replace(' ', '-')}${item.type}`).innerText)}><AiFillCopy/></button></p>
                    <p>Quantidade: {item.quantidade} {item.checked && <span style={{color: 'green'}} title="Recebedor inserido">
                      <IoMdCheckmark/></span>}</p>
                    <p>Type: {item.type}</p>
                    {item.Login && <p>Login: {item.Login.nome} </p>}
                    {item.Login && <p>Placa: {item.Login.placa}</p>}
                    {item.lista && (<p>Lista: {item.lista} </p>)}
                    <ul key={`${item.cliente}${item.type}`} className={`ul${(item.cliente).replace(' ', '-')}${item.type}`}>
                      {item.rms.map((item) => {
                        if(Trip.remessas_retornadas || Trip.remessas_finalizadas){
                          if(RmtoArray(Trip.remessas_retornadas.toString()).indexOf(item.rm) !== -1)
                            return <li key={item.rm} className="retornada liRetornada">{item.rm} 
                            {item.recebedor_name ? <span style={{color: 'green'}} title="Recebedor inserido"><IoMdCheckmark/> </span>: ''}
                            {item.status_client ? <TiInputChecked/> : ''}
                            </li>
                          else if(RmtoArray(Trip.remessas_finalizadas).indexOf(item.rm) !== -1)
                            return <li key={item.rm} className="entregue liEntregue">{item.rm} 
                            {item.recebedor_name ? <span style={{color: 'green'}} title="Recebedor inserido"><IoMdCheckmark/> </span>: ''}
                            {item.status_client ? <TiInputChecked/> : ''}
                            </li>
                        }
                        else
                          return <li key={item.rm} className={item.status === 'Finalizada' ? 'entregue liEntregue' : "liEmRota"}>{item.rm} 
                          {item.recebedor_name ? <span style={{color: 'green'}} title="Recebedor inserido"><IoMdCheckmark/> </span>: ''}
                          </li>
                      })} 
                    </ul>
                  </div>
                )
              } )}
            </div>                            
            <ToFinish Finished={Finished}Retornos={Retornos} FinishTrip={FinishTrip} QtdRetornos={QtdRetornos} InserirRetornos={InserirRetornos} VerificandoTxt={VerificandoTxt} setTRetornos={setTRetornos} TxtRetornos={TxtRetornos}/>
          </div>
          }
          {LoadingTime && <Loading />}
          <Footer />
          </>}
        <section id="pdf" className={MostraPdf === 1 ? 'grid' : 'inativo'}>
        {Data && <PdfTransferencia Data={Data}  />}
        </section>    
        </>
        )
    }
    

export default TRIP