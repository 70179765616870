import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'


import api from '../../services/api'
import BuscaToken from '../Components/Functions/BuscaToken.js'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import BuscaClientes from '../Components/BuscaClientes'
import Loading from '../Components/Loading'
import duplicity from '../Medias/duplicity.wav'
import GerandoPdf from './GerandoPdf.jsx'
import CheckStatusRms from '../Components/Functions/CheckStatusRms.js'

const Devolucao = () => {
  const [SelectedCliente, setSelectedCliente] = useState()
  const [txtArea, settxtArea] = useState()
  const [QtdBipada, setQtdBipada] = useState()
  const [User, setUser] = useState()
  const [LoadingTime, setLoadingTime] = useState()
  const [Type, setType] = useState()
  const [Pdf, setPdf] = useState()
  const [Hidden, setHidden] = useState()
  const [Data, setData] = useState()
  const [MostraPdf, setMostraPdf] = useState()
  const [SoundDuplicity, setSoundDuplicity] = useState()


  const history = useHistory()
  if(!SoundDuplicity){
    setSoundDuplicity(new Audio(duplicity))
  }

  const config = BuscaToken()

  useEffect(() => {
    if(MostraPdf === 1){
      window.print()
      history.push('/')
    } 
  }, [MostraPdf])


  useEffect(() => {
    api.get('/users/show', config)
    .then( r => {
      if(!r.data.message){
        setUser(r.data)
      }
    })
  }, [])


  function SetandoCliente (event) {
    setSelectedCliente(event.target.value)
  }

  function SetandoType (event) {
    setType(event.target.value)
  }
  

  function TxtArea(event) {
    settxtArea(event.target.value)
  }

  function textareaSave (event) {
    if(event.key === 'Enter'){
        let txt = txtArea.toUpperCase().split('\n')
        txt = txt.filter( item => item.length > 0)
        setQtdBipada(txt.length)
        txt.map(async (item,index) => {
          if(txt.indexOf(item) !== index) {
            const NewTxt = txt.filter((item,i) => {
              if(i !== index)
                return item
            })
            await SoundDuplicity.play()
            const stringNewTxt = NewTxt.toString()
            let NewTxtSerialized = stringNewTxt.replace(/,/g, '\n')
                NewTxtSerialized += '\n'
            settxtArea(NewTxtSerialized.toUpperCase())
            setQtdBipada(NewTxt.length)  
            
          } 
        })
    }
  }

  async function FinalizarDevolucao () {
    const rms = txtArea.toUpperCase().replace(/\$/g, '').split('\n').map( item => item.trim()).filter( item => item.length > 0)

    if(rms.length > 0){
      const data = {
        rms,
        client: SelectedCliente,
        autor: User.name,
        type: Type,
      }
      setLoadingTime(1)
      const ResponseServer = await api.post('/devolucao/create', data, config)

      if(ResponseServer.data.barradas.length > 0){
        let texto = `Remessas sem autorizacao para essa operacao: \n`
        ResponseServer.data.barradas.map( item => texto += `${item.remessa} : ${item.status} \n`)
        alert(texto)
      }

      if(ResponseServer.data.id){
        const Data = {
          data: ResponseServer.data.DataHora,
          remessas: ResponseServer.data.autorizadas,
          client: SelectedCliente,
          autor: User.name,
          type: Type,
          idDevolucao : ResponseServer.data.id
        }
        setLoadingTime()
        setData(Data)
        setPdf(1)
        setHidden(1)
        setMostraPdf(1)
        return 
      }
      setLoadingTime()
      alert('Erro ao processar devolucao! 0 remessas autorizadas.')
    }
  }


  return (
    <>
    {Hidden !== 1 &&
    <>
     <Menu /> 
      <div className="container contentCenter container-Devolucao grid" >
        <h2>Devolucao</h2>
        <BuscaClientes SetandoCliente={SetandoCliente} bgCoral="bg-Coral" />
        {SelectedCliente && <select className="bg-Coral" onChange={SetandoType}>
          <option value="">Tipo de remessas </option>
          <option value="Expressa">Expressa</option>
          <option value="Coleta">Coleta</option>
          </select>}
        {QtdBipada && <span className="span-M ativo">Quantidade bipada: {QtdBipada}</span>}
        {Type && <textarea onChange={TxtArea} onKeyPress={textareaSave} value={txtArea}></textarea>}
        {Type && <button className="btn-G" onClick={FinalizarDevolucao}> Finalizar Devolucao </button>}
      </div>
      
     <Footer />
    </>}
    <section id="pdf" className={MostraPdf === 1 ? 'ativo' : 'inativo'}>
        {Pdf && <GerandoPdf Data={Data}  />}
    </section>    
    {LoadingTime && <Loading />}
    </>
  )
}

export default Devolucao