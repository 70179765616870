import React from 'react';


import logo from '../Components/Menu/img/topLogo.png'



const GerandoPdf = ({Data}) => {

  return (
    <div className="Comprovante grid"> 
      <section className="headerComprovante grid">
        <img className="LogoComprovante BorderBox" src={logo} alt="Expressas Dourados"/>
        <h2 className="grid">Requisição de Combustivel</h2>
      </section>   
    <section className="bodyComprovante grid" style={{marginTop: "160px"}}>
      <h2  className="colorBlack">Eu {Data.user}, veiculo: {Data.placa} retirei requisição de R$ {Data.valor},00 , sob o número de controle: {Data.requisicao}.</h2>
      <br/>
      <p>Data: {Data.data.dia}/{Data.data.mes}/{Data.data.ano} <br/> Horas: {Data.data.hora} : {Data.data.minutos}</p>
      <br />
      <p>Assinatura: _____________________________</p>
      <br/>
      <p>RG: ____________</p>
      <br/>
    </section>  
    <section className="controleComprovante grid">  
      <p>Emitido por: {Data.autor_emissor}</p>
      <p>Sistema: Transação Autorizada!</p>
    </section>  
    </div>
  )
}

export default GerandoPdf