import React from 'react'

import loadingGif from './loading.gif'

const Loading = () => {




  return (
    <div className="modal mostrar">
      <img src={loadingGif} alt="loading" className="loading"/>
    </div>
  )
}

export default Loading