import React, { memo } from 'react'
import { Link } from 'react-router-dom'


const Menus = ({user}) => {

  if(user === 'A')
    return (
      <nav className="menuPrincipal grid">
        <ul className="grid">
          <li><Link to="/lastmile">LastMile</Link></li>
          <li><Link to="#">Painel de Gestão</Link>
            <ul className="grid">
              <li><Link to="/syncs">Sincronismo</Link></li>
              <li><Link to="/search/lote">Tracking em lote</Link></li>
              <li><Link to="/tripsIntegracao">Trips Integracao</Link></li>
              <li><Link to="/Producao">Producao</Link></li>
              <li><Link to="/Courier">Couriers</Link></li>
              <li><Link to="/CargoOverFlows">Transbordos</Link></li>
              <li><Link to="#">Controle Logins</Link>
                <ul className="grid">
                  <li><Link to="/ClientesLogins">Logins</Link></li>
                  <li><Link to="/ClientesLogins/create">Criar Login</Link></li>
                </ul>
              </li>
              <li><Link to="/CreateCourier">Cadastrar Courier </Link></li>
              <li><Link to="/ControleEntradas">Controle Entradas</Link></li>
              <li><Link to="#">Contas</Link>
                <ul className="grid">
                  <li><Link to="/Contas">Contas em Aberto</Link></li>
                  <li><Link to="/Contas/Emissao">Emissao</Link></li>
                  <li><Link to="/Contas/ContasPerUser">Extrato</Link></li>
                </ul>
              </li>  
            </ul>
          </li>
          <li><Link to="#">Requisicao</Link>
              <ul className="grid">
                <li><Link to="/Requisicao">Emitidas</Link></li>
                <li><Link to="/Requisicao/emissao">Emissao</Link></li>
                <li><Link to="/Requisicao/entrada">Entrada</Link></li>
                <li><Link to="/Requisicao/EmAberto">Em Aberto</Link></li>
              </ul>
          </li>   
          <li><Link to="#">Painel Remessas</Link>
            <ul className="grid">
              <li><Link to="/PainelRemessas">Remessas no piso</Link></li>
              <li><Link to="#">Pendencias</Link>
                <ul className="grid">
                  <li><Link to="/Pendencia">Remessas Bloqueadas</Link></li>
                  <li><Link to="/Pendencia/LiberarRemessas">Liberar Remessas</Link></li>
                  <li><Link to="/Pendencia/PendenciarRemessas">Pendenciar Remessas</Link></li>
                </ul>
              </li>
              <li><Link to="/Devolucao">Devolucao</Link></li>
              <li><Link to="#">Recebimento de Cargas</Link>
                <ul className="grid">
                  <li><Link to="/RecebimentoCarga">Entrada de Cargas</Link></li>
                  <li><Link to="/RecebimentoCarga/Comprov">Gerar Comprovante</Link></li>
                </ul>
              </li>
              <li><Link to="/InsercaoRecebedor/Insercao">Inserção de Recebedor</Link></li>
              <li><Link to="/addAlterTracking">Inserir Tracking Alternativo</Link></li>
              <li><Link to="/Avarias">Avarias</Link></li>
              <li><Link to="/DeleteRemessa">Excluir Remessa</Link></li>
              <li><Link to="/FindPackage">Find Package</Link></li>
              <li><Link to="/SearchByName">Search por Destinatário</Link></li>
              <li><Link to="/ChecandoPrazos">Checar Prazos</Link></li>
            </ul>
          </li>
          <li><Link to="#">Painel Admin</Link>
            <ul className="grid">
              <li><Link to="/CreateClient">Cadastrar Cliente </Link></li>
              <li><Link to="/CreateUser">Cadastrar User </Link></li>
              <li><Link to="/PainelAdmin/ChangePassAll">Mudar Senha de Usuarios </Link></li>
              <li><Link to="/PainelAdmin/ProducaoClientes">Producao por Clientes </Link></li>
              <li><Link to="/PainelAdmin/EditUsers">Alterar Usuarios</Link></li>
              <li><Link to="/PainelAdmin/EditCouriers/taxas">Alterar Taxas Courier</Link></li>
              <li><Link to="/PainelAdmin/EditTrips">Alterar Trips</Link></li>
              <li><Link to="/PainelAdmin/UpdateClientes">Atualizar Clientes</Link></li>
            </ul>
          </li>
        </ul>
      </nav>
    )
  else if (user === 'M')
      return (
        <nav className="menuPrincipal grid">
          <ul className="grid">
            <li><Link to="/lastmile">LastMile</Link></li>
            <li><Link to="#">Painel de Gestão</Link>
              <ul className="grid">
                <li><Link to="/syncs">Sincronismo</Link></li>
                <li><Link to="/search/lote">Tracking em lote</Link></li>
                <li><Link to="/tripsIntegracao">Trips Integracao</Link></li>
                <li><Link to="/Producao">Producao</Link></li>
                <li><Link to="/Courier">Couriers</Link></li>
                <li><Link to="/CargoOverFlows">Transbordos</Link></li>
                <li><Link to="#">Controle Logins</Link>
                  <ul className="grid">
                    <li><Link to="/ClientesLogins">Logins</Link></li>
                    <li><Link to="/ClientesLogins/create">Criar Login</Link></li>
                  </ul>
                </li>
                <li><Link to="/CreateCourier">Cadastrar Courier </Link></li>
              </ul>
            </li>
            <li><Link to="#">Requisicao</Link>
                <ul className="grid">
                  <li><Link to="/Requisicao">Emitidas</Link></li>
                  <li><Link to="/Requisicao/emissao">Emissao</Link></li>
                  <li><Link to="/Requisicao/entrada">Entrada</Link></li>
                  <li><Link to="/Requisicao/EmAberto">Em Aberto</Link></li>
                </ul>
            </li>   
            <li><Link to="#">Painel Remessas</Link>
              <ul className="grid">
                <li><Link to="/PainelRemessas">Remessas no piso</Link></li>
                <li><Link to="#">Pendencias</Link>
                  <ul className="grid">
                    <li><Link to="/Pendencia">Remessas Bloqueadas</Link></li>
                    <li><Link to="/Pendencia/LiberarRemessas">Liberar Remessas</Link></li>
                    <li><Link to="/Pendencia/PendenciarRemessas">Pendenciar Remessas</Link></li>
                  </ul>
                </li>
                <li><Link to="/Devolucao">Devolucao</Link></li>
                <li><Link to="#">Recebimento de Cargas</Link>
                <ul className="grid">
                  <li><Link to="/RecebimentoCarga">Entrada de Cargas</Link></li>
                  <li><Link to="/RecebimentoCarga/Comprov">Gerar Comprovante</Link></li>
                </ul>
              </li>
                <li><Link to="/InsercaoRecebedor/Insercao">Inserção de Recebedor</Link></li>
                <li><Link to="/addAlterTracking">Inserir Tracking Alternativo</Link></li>
                <li><Link to="/Avarias">Avarias</Link></li>
                <li><Link to="/DeleteRemessa">Excluir Remessa</Link></li>
                <li><Link to="/FindPackage">Find Package</Link></li>
                <li><Link to="/SearchByName">Search por Destinatário</Link></li>
                <li><Link to="/ChecandoPrazos">Checar Prazos</Link></li>
              </ul>
            </li>
          </ul>
        </nav>
      )
   else if (user === 'S')
        return (
          <nav className="menuPrincipal grid">
          <ul className="grid">
            <li><Link to="/lastmile">LastMile</Link></li>
            <li><Link to="#">Painel de Gestão</Link>
              <ul className="grid">
                <li><Link to="/search/lote">Tracking em lote</Link></li>
                <li><Link to="/tripsIntegracao">Trips Integracao</Link></li>
                <li><Link to="/Producao">Producao</Link></li>
                <li><Link to="/Courier">Couriers</Link></li>
                <li><Link to="/CargoOverFlows">Transbordos</Link></li>
                <li><Link to="#">Controle Logins</Link>
                  <ul className="grid">
                    <li><Link to="/ClientesLogins">Logins</Link></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li><Link to="#">Requisicao</Link>
                <ul className="grid">
                  <li><Link to="/Requisicao">Emitidas</Link></li>
                  <li><Link to="/Requisicao/emissao">Emissao</Link></li>
                  <li><Link to="/Requisicao/EmAberto">Em Aberto</Link></li>
                </ul>
            </li>   
            <li><Link to="#">Painel Remessas</Link>
              <ul className="grid">
                <li><Link to="/PainelRemessas">Remessas no piso</Link></li>
                <li><Link to="#">Pendencias</Link>
                  <ul className="grid">
                    <li><Link to="/Pendencia">Remessas Bloqueadas</Link></li>
                    <li><Link to="/Pendencia/LiberarRemessas">Liberar Remessas</Link></li>
                    <li><Link to="/Pendencia/PendenciarRemessas">Pendenciar Remessas</Link></li>
                  </ul>
                </li>
                <li><Link to="/Devolucao">Devolucao</Link></li>
                <li><Link to="#">Recebimento de Cargas</Link>
                <ul className="grid">
                  <li><Link to="/RecebimentoCarga">Entrada de Cargas</Link></li>
                  <li><Link to="/RecebimentoCarga/Comprov">Gerar Comprovante</Link></li>
                </ul>
              </li>
                <li><Link to="/InsercaoRecebedor/Insercao">Inserção de Recebedor</Link></li>
                <li><Link to="/addAlterTracking">Inserir Tracking Alternativo</Link></li>
                <li><Link to="/Avarias">Avarias</Link></li>
                <li><Link to="/FindPackage">Find Package</Link></li>
                <li><Link to="/SearchByName">Search por Destinatário</Link></li>
                <li><Link to="/ChecandoPrazos">Checar Prazos</Link></li>
              </ul>
            </li>
          </ul>
        </nav>
        )
    else if (user === 'C')
          return (
            <nav className="menuPrincipal grid">
              <ul className="grid">
                <li><Link to="/MinhasContas">Minhas Contas</Link></li>
                <li><Link to="/producao">Produção</Link></li>
                <li><Link to="/Baixa">Baixa de entrega</Link></li>
              </ul>
            </nav>
          )
    else
      return (
        ''
      )
  }

export default memo(Menus)