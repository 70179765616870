import React, { useState, useEffect } from 'react'
import api from '../../services/api'

import { useHistory } from 'react-router-dom'

import GerandoPdf from './GerandoPdf'

import BuscaToken from '../Components/Functions/BuscaToken.js'

const AvariasVia2 = () => {
  const [Data, setData] = useState()
  const [MostraPdf, setMostraPdf] = useState()
  const [Pdf, setPdf] = useState()

  const history = useHistory()
  const config = BuscaToken()

  useEffect(() => {
    const params = window.location.href.split('Via2/')
    api.get(`/avarias/Via2/${params[1]}`, config).then( r => {
      if(!r.data.message){
      setData({
        data: JSON.parse(r.data.created_at_json),
        remessas: JSON.parse(r.data.remessas),
        cliente: r.data.cliente,
        autor: r.data.user,
        relatorio : r.data.id
      })
      setPdf(1)
      setMostraPdf(1)
      return 
      }
      else {
        alert(r.data.message)
        history.push('/')
      }
    })
  }, [])

  useEffect(() => {
    
    if(MostraPdf === 1){
      window.print()
      //history.push('/')
    } 
    
  }, [MostraPdf])

  return (
    <div>
      <section id="pdf" className={MostraPdf === 1 ? 'ativo' : 'inativo'}>
          {Pdf && <GerandoPdf Data={Data}  />}
      </section>   
    </div>
  )
}


export default AvariasVia2