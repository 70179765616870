import { BrowserRouter, Switch, Route } from 'react-router-dom'
import React from 'react'

const RoutesBlank = () => {
  return (
    <BrowserRouter>
      <Switch>
      </Switch>
    </BrowserRouter>
  )
}

export default RoutesBlank

