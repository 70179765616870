import React , { useState } from 'react'
import { Link } from 'react-router-dom'

import api from '../../services/api'
import BuscaToken from '../Components/Functions/BuscaToken.js'

import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import Loading from '../Components/Loading'
import duplicity from '../Medias/duplicity.wav'


const SearchRemessasLote = () => {
  const [LoadingTime, setLoadingTime] = useState()
  const [txtArea, settxtArea] = useState()
  const [QtdBipada, setQtdBipada] = useState()
  const [Remessas, setRemessas] = useState()
  const [SoundDuplicity, setSoundDuplicity] = useState()

  if(!SoundDuplicity){
    setSoundDuplicity(new Audio(duplicity))
  }


  function TxtArea(event) {
    settxtArea(event.target.value)
  }

  function textareaSave (event) {
    if(event.key === 'Enter'){
        let txt = txtArea.split('\n')
        txt = txt.filter( item => item.length > 0)
        setQtdBipada(txt.length)
        txt.map(async (item,index) => {
          if(txt.indexOf(item) !== index) {
            const NewTxt = txt.filter((item,i) => {
              if(i !== index)
                return item
            })
            await SoundDuplicity.play()
            const stringNewTxt = NewTxt.toString()
            let NewTxtSerialized = stringNewTxt.replace(/,/g, '\n')
                NewTxtSerialized += '\n'
            settxtArea(NewTxtSerialized)
            setQtdBipada(NewTxt.length)  
            
          } 
        })
    }
  }

  async function Buscar () {
    let rms = txtArea.toUpperCase().split('\n')
        rms = rms.map( item => item.trim())
        rms = rms.filter( (item, index) => rms.indexOf(item) === index).filter( item => item.length > 0)
    setLoadingTime(1)
    const responseServer = await api.post('/search/lote', rms, BuscaToken())
    if(responseServer.data.length === 0){
      alert('Nenhuma remessa encontrada.')
      return false
    }

    setRemessas(responseServer.data)
    setLoadingTime()

  }


  return (
    <>
      <Menu />
      <div className="container grid container-SearchLote">
        <h2>Tracking em lote</h2>
        {!Remessas && 
          <>
            {QtdBipada && <span className="span-M">Quantidade bipada: <span>{QtdBipada}</span></span>}
            <textarea name="textarea" id="textarea" style={{width: "300px"}} onKeyPress={textareaSave} onChange={TxtArea} value={txtArea} placeholder="Limite: 100 remessas">
            </textarea>
            <button onClick={Buscar}>Buscar</button>
          </>
        }
        {
          Remessas && 
          <>
          <span className="span-M">{Remessas.length} Localizadas</span>
          <table className="tableBaixaCliente">
            <thead>
              <tr>
                <th>Remessa</th>
                <th>Cliente</th>
                <th>Recebedor</th>
                <th>Status</th>
                <th>Criada em</th>
                <th>Finalizada em</th>
              </tr>
            </thead>
            <tbody>
              {Remessas.map( remessa => 
                <tr key={remessa.rm}>
                  <td><Link to={`/search/remessa/${remessa.rm}`}>{remessa.rm}</Link></td>
                  <td>{remessa.cliente}</td>
                  <td className={remessa.recebedor_name ? 'liEntregue' : ''}>{remessa.recebedor_name ? 'Inserido' : 'Nao Inserido'}</td>
                  <td>{remessa.status}</td>
                  <td>{remessa.created_at_json.dia} / {remessa.created_at_json.mes} / {remessa.created_at_json.ano}</td>
                  <td>{remessa.finished_at_json ? `${remessa.finished_at_json.dia} / ${remessa.finished_at_json.mes} / ${remessa.finished_at_json.ano}` : 'Nao finalizada'}</td>
                </tr>
                )}
            </tbody>
          </table>
          </>
        }
      </div>
      {LoadingTime && <Loading />}
      <Footer />
    </>
  )
}

export default SearchRemessasLote