import React, { useState, useEffect } from 'react'

import { useHistory} from 'react-router-dom'

import api from '../../services/api'

import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import BuscaToken from '../Components/Functions/BuscaToken.js'
import Loading from '../Components/Loading'
import GerandoPdf from './GerandoPdf.jsx'


const RequisicaoEmissao = () => {
  const [Users, setUsers] = useState([])
  const [Requisicao, setRequisicao] = useState()
  const [Valor, setValor] = useState()
  const [User, setUser] = useState()
  const [Autor, setAutor] = useState()
  const [Placa, setPlaca] = useState()
  const [TimeLoading, setTimeLoading] = useState()
  const [Placas, setPlacas] = useState()
  const [InputPlaca, setInputPlaca] = useState()
  const [Pdf, setPdf] = useState()
  const [MostraPdf, setMostraPdf] = useState()
  const [Hidden, setHidden] = useState()

  const history = useHistory()

  const config = BuscaToken()

  useEffect(() => {
    api.get('/users/index', config).then(r => {
      setUsers(r.data)
    })

    api.get('/users/show', config)
    .then( r => {
      if(!r.data.message){
        setAutor(r.data)
      }
    })
  }, [])

  async function addUser (event) {
    if(event.target.value.length > 0){
      setUser(event.target.value)
      setTimeLoading(1)
      const placas = await api.get(`/requisicao/placas/${event.target.value}`, config)
      if(placas.data.length > 0){
        setPlacas(placas.data)
        setInputPlaca()
        setTimeLoading()
      }  
      else{
        setInputPlaca(1)  
        setTimeLoading()  
        setPlacas()
        setPlaca()
      }
    }
    else{
      setUser()
      setPlaca()
      setInputPlaca()
      setPlacas()
    } 
  }

  function checkPlacaInput (event) {
    if(event.key === 'Enter'){
      const placa = event.target.value.toUpperCase()
      setPlaca(placa)
    }
  }

  function checkPlacaSelect (event) {
      if(event.target.value === 'nova'){
        setInputPlaca(1)
        setPlacas()
        setPlaca()
        return false
      }
      const placa = event.target.value.toUpperCase()
      setPlaca(placa)
  }

  async function checkRequisicao (event) {
    if(event.key === 'Enter'){
      event.preventDefault()
      setTimeLoading(1)
      const requisicao = event.target.value
      const autorizacao = await api.get(`/requisicao/check/${requisicao}`, config)
      setTimeLoading()
      if(autorizacao.data.message){
        setRequisicao()
        return alert(JSON.stringify(autorizacao.data.message))
      }
      else if(autorizacao.data.Autorizacao === true){
        setRequisicao(requisicao)
        setValor(autorizacao.data.valor)
        return true
      }
      else{
        setRequisicao()
        alert("Requisicao ja emitida. Consultar o Stifler")
        
      }    
    }  
  }

  async function Finish () {
    const data = {
      requisicao: Requisicao,
      autor_emissor: Autor.name,
      placa: Placa,
      user: User,
      valorConta: Valor
    }
    setTimeLoading(1)
    const updated = await api.post('/requisicao/emissao', data, config)

    if(updated.data.message){
      alert(JSON.stringify(updated.data.message))
      return false
    }
    setTimeLoading()
    setPdf(updated.data)
    setHidden(1)
    setMostraPdf(1)
    window.print()
    history.push('/')
  }


  return (
    <>
      {Hidden !== 1 &&
        <>
        <Menu />
        <div className="container contentCenter EmissaoRequisicao grid">
          <h2>Requisicao</h2>
          <h3>Emissao de Requisicao</h3>
          <form className="grid">
            <select name="User" id="User" onChange={addUser}>
              <option value="">Selecionar Usuario</option>
              {Users.map(user => (
                <option value={user.id} key={user.id}>{user.name}</option>
              ))}
            </select>
            {User && <label>Placa</label>}
            {Placas && <select id="placas" onChange={checkPlacaSelect}>
              <option value="">Selecionar Placa</option>
              <option value="nova">Inserir Nova Placa</option>
              {Placas.map( placa => <option key={placa}> {placa}</option>)}
              </select>}
            {InputPlaca && <input className="ativo inputEntradaRequisicao" onKeyPress={checkPlacaInput} maxLength="7"></input>}
            {Placa && <label>Requisicao</label>}
            {Placa && <input type="text" id="requisicao" className="ativo inputEntradaRequisicao" onKeyPress={checkRequisicao}/>}
          </form>
          {Requisicao && <button className="btn-G" onClick={Finish} > Finalizar </button>}
        </div>
        <Footer />
        {TimeLoading && <Loading />}
      </>}
      <section id="pdf" className={MostraPdf === 1 ? 'ativo' : 'inativo'}>
        {Pdf && <GerandoPdf Data={Pdf}  />}
      </section>    
    </>
  )
}

export default RequisicaoEmissao