import React from 'react'

const ToFinish = (props) => {
  if (props.Finished === 0){
    return(
      <div className="FinishTrip grid"> 
      <button className={`${props.Retornos > 0 ? 'inativo' : 'ativo'} btn-M`} style={{width: '35%'}}  onClick={props.InserirRetornos}> Finalizar Viagem - Inserir Retornos</button>
      <span className={props.QtdRetornos > 0 ? 'ativo' : 'inativo'}>Quantidade retornos: {props.QtdRetornos}</span>
      <textarea  id="retornos" className={props.Retornos > 0 ? 'ativo' : 'inativo'} onKeyUp={props.VerificandoTxt} onChange={props.setTRetornos}value={props.TxtRetornos}></textarea>
      <button className={`${props.Retornos > 0 ? 'ativo' : 'inativo'} btn-M`} style={{width: ''}} onClick={props.FinishTrip}>Finalizar</button>
      </div>
    )
  }
  else 
    return (
    ''
    )


}

export default ToFinish