import React, { useState, useEffect} from 'react'
import { useHistory} from 'react-router-dom'


import api from '../../services/api'
import BuscaToken from '../Components/Functions/BuscaToken.js'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import BuscaClientes from '../Components/BuscaClientes'
import Loading from '../Components/Loading'
import duplicity from '../Medias/duplicity.wav'


const RecebimentoCarga = () => {
  const [SelectedCliente, setSelectedCliente] = useState()
  const [txtArea, settxtArea] = useState()
  const [QtdBipada, setQtdBipada] = useState()
  const [User, setUser] = useState()
  const [LoadingTime, setLoadingTime] = useState()
  const [Cenzao, setCenzao] = useState()
  const [Zerao, setZerao] = useState()
  const [Manifesto, setManifesto] = useState()
  const [SoundDuplicity, setSoundDuplicity] = useState()
  const [finishButton, setFinishButton] = useState(true)

  const history = useHistory()

  if(!SoundDuplicity){
    setSoundDuplicity(new Audio(duplicity))
  }

  const config = BuscaToken()


  useEffect(() => {

    api.get('/users/show', config)
    .then( r => {
      if(!r.data.message){
        setUser(r.data)
      }
    })

    if(localStorage.getItem('RecebimentoCarga')){
      settxtArea(localStorage.getItem('RecebimentoCarga'))
      const txt = localStorage.getItem('RecebimentoCarga').split('\n')
      const rms = txt.filter(item => item.length > 2)
      setQtdBipada(rms.length)
    }
  }, [])


  async function SetandoCliente (event) {
    setSelectedCliente(event.target.value)
    const cliente = await api.get(`/clientlista/${event.target.value}`, BuscaToken())

    if(cliente.data.Manifesto)
      setManifesto(1)
    else
      setManifesto()  
      
  }

  function TxtArea(event) {
    settxtArea(event.target.value)
  }

  async function textareaSave (event) {
    if(event.key === 'Enter'){
      localStorage.setItem('RecebimentoCarga', event.target.value)
      let txt = txtArea.toUpperCase().split('\n')
      txt = txt.filter( item => item.length > 0).map(item => item.trim())
      setQtdBipada(txt.length)
      const textserialized = txt.filter( (item, index) => txt.indexOf(item) === index)
      //let tester = textserialized.map( item => `"${item}"##`)
      ///tester = tester.toString().replace(/,/g, '\n')
      //tester += '\n'
      //settxtArea(tester)
      
      if(textserialized.length !== txt.length){
        await SoundDuplicity.play()
        let NewTxtSerialized = textserialized.toString().replace(/,/g, '\n')
            NewTxtSerialized += '\n'
        settxtArea(NewTxtSerialized)
        setQtdBipada(textserialized.length)   
        localStorage.setItem('RecebimentoCarga', NewTxtSerialized)  
      }
    }  
  }

  async function FinalizarRecebimento() {
    if(document.querySelector('#manifesto') && document.querySelector('#manifesto').value.length === 0)
      return alert('Favor inserir manifesto')

    let remessas = txtArea.toUpperCase().replace(/\$/g, '').split('\n').filter(item => item.length > 2).map(item => item.trim())
    remessas = remessas.filter( (item, index) => remessas.indexOf(item) === index)
    
      const data = {
        autor: User.name,
        client: SelectedCliente,
        rms: remessas,
        ManifestoCliente: document.querySelector('#manifesto') ? document.querySelector('#manifesto').value : ''
      }
      setLoadingTime(1)
      const ResponseServer = await api.post('/recebimentocarga', data, config)
      localStorage.removeItem('RecebimentoCarga')
      setLoadingTime()
      alert( JSON.stringify(ResponseServer.data.message) )
      if(ResponseServer.data.barradas){
        let text = `Remessas sem autorizacao para essa operacao: \n`
        ResponseServer.data.barradas.map( item => text += `${item.rm}: ${item.status}\n`)
        alert(text)
      }
      history.push('/')
      setFinishButton(true)
    }

  function add001 () {
    if(!Cenzao){
      let txtarea = txtArea.split('\n')
      txtarea = txtarea.filter(remessa => remessa.length > 1) 
      const txtarea001 = txtarea.map(remessa => `${remessa}001`)
      const txtareaSerialized = txtarea001.toString().replace(/,/g, '\n')
      settxtArea(txtareaSerialized)
      setCenzao(1)
    }
  }

  function add0 () {
    if(!Zerao){
      let txtarea = txtArea.split('\n')
      txtarea = txtarea.filter(remessa => remessa.length > 1) 
      const txtarea001 = txtarea.map(remessa => `0${remessa}`)
      const txtareaSerialized = txtarea001.toString().replace(/,/g, '\n')
      settxtArea(txtareaSerialized)
      setZerao(1)  
    }
  }


  return(
    <>
      <Menu />
      <div className="container contentCenter container-RecebimentoCarga grid">
        <h2>Recebimento de Carga</h2>
          <BuscaClientes SetandoCliente={SetandoCliente} bgCoral='bg-Coral'/>
          {(SelectedCliente && !Cenzao) && <button className="btn-P" onClick={add001}> Add 001 ao final das remessas</button>}
          {(SelectedCliente && !Zerao) && <button className="btn-P" onClick={add0}> Add 0 ao inicio das remessas</button>}
          {QtdBipada && SelectedCliente && <span className="span-M ativo">Quantidade bipada: {QtdBipada}</span>}
          {SelectedCliente && <textarea style={{width: '100%'}} id="textarea" onKeyPress={textareaSave} onChange={TxtArea} value={txtArea}></textarea>}
          {Manifesto && <span style={{display:"block", marginTop: '10px'}}>Manifesto: <input type="text" id="manifesto"/></span>}
          {SelectedCliente &&
            <button 
            disabled={!finishButton}
            onClick={() => {
              setFinishButton(false)
              FinalizarRecebimento()
            }}
            className="btn-G"
          >Finalizar Recebimento</button>}
          </div>
      
      {LoadingTime && <Loading />}
      <Footer />
    </>
  )
}

export default RecebimentoCarga