import React, { useState} from 'react'

import { Link } from 'react-router-dom'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'

import api from '../../services/api'
import BuscaToken from '../Components/Functions/BuscaToken.js'



const SearchByName = () => {
  const [Remessas, setRemessas] = useState()
  const [AllRemessas, setAllRemessas] = useState()
  const [Clientes, setClientes] = useState()


  async function Search(value) {
    if(value.length >= 3){
      const Searched = await api.get(`/search/name/${value}`, BuscaToken())
      if(Searched.data.remessas.length === 0)
        return alert('Nenhuma remessa localizada')
      setClientes(Searched.data.clientes)
      setRemessas(Searched.data.remessas)  
      setAllRemessas(Searched.data.remessas)  
    }
  }

  const filtro = (event) => {
    if(event.target.value.length > 0){
      const RemessasFiltradas = AllRemessas.filter( remessa =>  remessa.client === event.target.value)
      setRemessas(RemessasFiltradas)
    }
    else {
      setRemessas(AllRemessas)
    }
  }

  return(
    <>
      <Menu />
      <div className="grid container">
        <h2>Buscar remessa por nome do destinatário</h2>
        <div style={{marginBottom: '30px'}}>
          <label htmlFor="search">Entre com um nome para Destinatário</label>
          <br/>
          <input type="text" maxLength="20" placeholder="Nome + Enter (minimo 3 caracteres)"
           onKeyPress={event => {
             if(event.key === 'Enter'){
               Search(event.target.value)
              }
           }}/>
           <h4>O sistema irá encontrar os 100 primeiros registros que contenham a palavra-chave.</h4>
        </div>
        {Remessas && <>
          <section>
          <label htmlFor="filtro" style={{display: "inline"}}>Filtro por Clientes </label>
          <select name="clientes" id="clientes" style={{display: 'inline', fontSize: "15pt", backgroundColor: "coral"}} onChange={(event) => filtro(event)}>
            <option value="">Todos</option>
            {Clientes && Clientes.map( cliente => <option value={cliente} key={cliente}>{cliente}</option>)}
          </select>
          <h3>Total em exibição: {Remessas.length}</h3>
        </section>
        {Remessas &&
          <table className="tableBaixaCliente">
            <thead>
              <tr>
                <th>Data/Hora Entrega</th>
                <th>Cliente</th>
                <th>Remessa</th>
                <th>Destinatario</th>
                <th>Recebedor</th>
                <th>Documento</th>
                <th>Parentesco</th>
                <th>Confirmação de baixa</th>
              </tr>
            </thead>
            <tbody>
              {Remessas.map( item => 
                <tr key={item.rm}>
                  <td>{item.recebedor_finished_at_json && `${item.recebedor_finished_at_json.dia}/${item.recebedor_finished_at_json.mes}/${item.recebedor_finished_at_json.ano}  ${item.recebedor_finished_at_json.hora}:${item.recebedor_finished_at_json.minutos}`}</td>
                  <td>{item.client}</td>
                  <td><Link to={`/search/remessa/${item.rm}`}>{item.rm}</Link></td>
                  <td>{item.recebedor_destinatario}</td>
                  <td>{item.recebedor_name}</td>
                  <td>{item.recebedor_document}</td>
                  <td>{item.recebedor_grau}</td>
                  <td style={{color: item.baixaCliente !== 1 ? 'green' : 'red'}}>{item.baixaCliente !== 1 ? 'Baixa Confirmada' : 'Baixa não Confirmada'}</td>
                </tr>
                )}
            </tbody>
          </table>
        }</>}
      </div>
      <Footer />
    </>
  )
}

export default SearchByName