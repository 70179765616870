import { Route, BrowserRouter, Switch} from 'react-router-dom'
import React from 'react'

import Production from '../../Production'
import ChangePass from '../../ChangePass'
import MinhasContas from '../../MinhasContas'
import BaixaClienteCourier from '../../BaixaCliente/courier'
import HomeCourier from '../../Home/courier.jsx'

const RoutesCourier = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={HomeCourier} path='/home' exact />
        <Route component={HomeCourier} path='/' exact/>
        <Route component={Production} path='/producao' exact/>
        <Route component={ChangePass} path='/ChangePass' exact/>
        <Route component={MinhasContas} path='/MinhasContas' exact/>
        <Route component={BaixaClienteCourier} path='/Baixa'/>
      </Switch>
    </BrowserRouter>
  )
}

export default RoutesCourier

