import React from 'react'


const TableAll = ({Data}) => {


  return(
    <table className="boxProduction">
          <thead>
            <tr>
              <th>Tipo de Conta</th>
              <th>Criado em</th>
              <th>Desconto em</th>
              <th>Descricao</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            {Data && Data.ContasGeral.map((item) => {
              const created = JSON.parse(item.created_at_json)
              const discount = JSON.parse(item.discount_at_json)
              const value = `R$ ${item.valor}`.replace('.', ',')


              return (
                <tr key={Math.random(10)}>
                  <td>{item.tipo}</td>
                  <td>{created.dia}/{created.mes}/{created.ano}</td>
                  <td>{discount.dia}/{discount.mes}/{discount.ano}</td>
                  <td>{item.descricao}</td>
                  <td>{value}</td>
                </tr>
              )  
            })}
          </tbody>
          <tfoot style={{fontWeight: 'bold'}}>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td>Valor Total:</td>
              <td>R$ {Data.valorTotal}</td>
            </tr>
          </tfoot>
        </table>
  )
}


export default TableAll