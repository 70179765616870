import React, { useState } from 'react'
import { useHistory} from 'react-router-dom'
import api from '../../services/api'

import BuscaToken from '../Components/Functions/BuscaToken.js'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'


const CreateClient = () => {
  const config = BuscaToken()
  const [useApp, setuseApp] = useState()

  const history = useHistory()

  async function submitClient () {
    const name = document.getElementById('nomeCliente').value
    const lista = document.getElementById('listaCliente').value
    if(name.length < 4 || lista.length < 1 )
      return alert('Campos Invalidos!')
    
      const data = {
        name,
        lista
      }
      
      const responseNewClient = await api.post('/clients/create', data, config)
      alert(JSON.stringify(responseNewClient.data.message))
      return history.push('/home')
      
  }



  return (
    <>
      <Menu />
      <div className="container grid">
        <h3>Cadastro de Cliente </h3>
        <form action="submit" className="grid">
          <label htmlFor="nomeCliente">Nome do Cliente:</label>
          <input type="text" name="nomeCliente" id="nomeCliente"/>
          <br/>
          <select name="listaCliente" id="listaCliente">
            <option value="">Controle de lista?</option>
            <option value="1">Sim</option>
            <option value="0">Nao</option>
          </select>
          <select name="useapp" id="useapp" onChange={(event) => {event.target.value.length > 0  && setuseApp(event.target.value)}}>
            <option value="">Cliente usa app?</option>
            <option value={1}>Sim</option>
            <option value={0}>Nao</option>
          </select>
          {(useApp === "1") && <div>
          <label htmlFor="App">App</label>
          <input type="text" id="app"/>
          </div>
          }
          <select name="recebedor" id="recebedor">
            <option value="">Recebedor requerido?</option>
            <option value="1">Sim</option>
            <option value="0">Nao</option>
          </select>
          <select name="Manifesto" id="Manifesto">
            <option value="">Manifesto?</option>
            <option value="1">Sim</option>
            <option value="0">Nao</option>
          </select>
          <select name="Lista" id="Lista">
            <option value="">Lista requerida?</option>
            <option value="1">Sim</option>
            <option value="0">Nao</option>
          </select>
          <label htmlFor="TaxaP">Taxa Pequenas</label>
          <input type="text" id="TaxaP"/>
          <label htmlFor="TaxaG">Taxa Grandes</label>
          <input type="text" id="TaxaG"/>
          <br/>
          <select name="baixa" id="baixa">
            <option value="">Baixa na base?</option>
            <option value={1}>Sim</option>
            <option value={0}>Não</option>
          </select>
        </form>
        <button className="btncreateCourier" onClick={submitClient}>Criar Cliente</button>

      </div>
        <Footer />
    </>
  )
}

export default CreateClient