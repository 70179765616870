import React, { useEffect, useState} from 'react'

import api from '../../services/api'

import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import BuscaToken from '../Components/Functions/BuscaToken.js'


const Contas = () => {
  const [ContasEmAberto, setContasEmAberto] = useState()

  const config = BuscaToken()

  useEffect(() => {
    api.get('/contas/extratoEmAberto', config).then(r => setContasEmAberto(r.data))
  }, [])


  return (
    <>
      <Menu />
      <div className="container grid">
        <h2>Contas</h2>
        <span className="span-M">Contas - Em Aberto</span>
        <table className="tableProduction tableProductionContas" border="1px">
            <thead>
              <tr>
                <th>Usuario</th>
                <th>Tipo de Conta</th>
                <th>Autor</th>
                <th>Criada em:</th>
                <th>Desconto em:</th>
                <th>Descricao</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
            {ContasEmAberto && ContasEmAberto.map( item => { 
              const created = JSON.parse(item.created_at_json)
              const discount = JSON.parse(item.discount_at_json)

              return (
                <tr key={Math.random(10)}>
                  <td>{item.user}</td>
                  <td>{item.tipo}</td>
                  <td>{item.autor}</td>
                  <td>{created.dia}/{created.mes}/{created.ano}</td>
                  <td>{discount.dia}/{discount.mes}/{discount.ano}</td>
                  <td>{item.descricao}</td>
                  <td>{item.valor}</td>
                </tr>)
              })}
            </tbody>
          </table>
        
      </div>
      <Footer />
    </>
  )
}

export default Contas