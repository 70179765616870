import React, { useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom'


import api from '../../services/api'
import BuscaToken from '../Components/Functions/BuscaToken.js'
import Loading from '../Components/Loading'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'


const Pendenciar = () => {
  const [Remessa, setRemessa] = useState()
  const [LoadingTime, setLoadingTime] = useState()
  const [Obs, setObs] = useState()
  const [User, setUser] = useState()

  const config = BuscaToken()
  const history = useHistory()

  useEffect(() => {
    api.get('/users/show', config)
    .then( r => {
      if(!r.data.message){
        setUser(r.data.name)
      }
    })
  }, [])

  async function Finish () {
      if(!Remessa || !Obs){
        alert('Dados invalidos')
        return
      }

      const data = {
        remessa: Remessa.rm,
        obs: Obs,
        autor: User
      }

      setLoadingTime(1)
      const serverResponse = await api.post('/pendencia/pendenciar', data, config)
      setLoadingTime()
      alert(JSON.stringify(serverResponse.data.message))
      history.push('/')
      return
  }

  async function BuscaRm (remessa) {
    const ResponseServer = await api.get(`/search/rm/${remessa.trim()}`, config)
    if(ResponseServer.data.message){
      alert('Remessa inexistente.')
      document.querySelector('#remessa').value = ''
      document.querySelector('#remessa').focus()
      setRemessa()
      return
    }
    setRemessa(ResponseServer.data)
  }


  return(
    <>
      <Menu />
      <div className="container contentCenter grid">
        <h2>Pendenciar Remessa</h2>
        
        {!Remessa && <input type="text" id="remessa" style={{width: "100%"}} placeholder="Entre com a remessa"
         onKeyPress={(event) => event.key === "Enter" && event.target.value.length > 0 && BuscaRm(event.target.value)}/>}
        {Remessa && <>
          <span className="span-M"> Remessa: {Remessa.rm}</span>
          <span className="span-M"> Cliente: {Remessa.client}</span>
          <span className="span-M"> Status: {Remessa.status}</span>
          <label htmlFor="Obs" style={{color: "crimson"}}>Motivo pendencia</label>
          <input type="text" id="Obs" style={{width: "100%", marginTop: "10px"}} maxLength="15" placeholder="Maximo 15 caracteres"
          onChange={(event) => setObs(event.target.value)}/>
        </>}
        {Remessa && Obs && <button className="btn-G" onClick={Finish}> Pendenciar </button>}
      </div>
      
      <Footer />
      {LoadingTime && <Loading />}
    </>
  )
}

export default Pendenciar