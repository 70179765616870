import React, { useState, useEffect} from 'react'

import Loading from '../../Components/Loading'
import api from '../../../services/api'
import BuscaToken from '../../Components/Functions/BuscaToken.js'
import ExportCsv from '../../Components/Functions/ExportCsv.js'


const Table = ({Data}) => {
  const [TotalPs, setTotalPs] = useState()
  const [TotalGs, setTotalGs] = useState()
  const [TotalGeral, setTotalGeral] = useState()
  const [DataTable, setDataTable] = useState()
  const [LoadingTime, setLoadingTime] = useState()  

  useEffect(() => {
    let Ps = null
    let Gs = null
    Data.clientes.forEach((cliente) =>{
      Ps += cliente.RemessasP
      Gs += cliente.RemessasG
    })

    setTotalPs(Ps)
    setTotalGs(Gs)
    setTotalGeral(Ps + Gs)

  }, [])





  async function gerarCsv (event, cliente) {
    setLoadingTime(1)
    const data = {
      cliente: cliente.cliente.name,
      periodo: cliente.periodo
    }

    const ResponseServer = await api.post('/production/client/rms', data, BuscaToken())
    setLoadingTime()
    setDataTable(ResponseServer.data)
    const html = document.querySelector('#tableProductionCliente')
    ExportCsv("#tableProductionCliente", `Relatorio${cliente.cliente.name}.csv`)
  }




  return (
    <>
      <h3>{Data.ShowPeriodo}</h3>
      <table className="tableProduction">
        <thead>
          <tr>
            <th> Cliente</th>
            <th> Total Pequenas</th>
            <th> Total Grandes</th>
            <th> Total Geral</th>
            <th> Exportar CSV</th>
          </tr>
        </thead>
        <tbody>
          {Data.clientes.map((cliente) => (
            <tr key={cliente.cliente.name}>
              <td>{cliente.cliente.name}</td>
              <td>{cliente.RemessasP}</td>
              <td>{cliente.RemessasG}</td>
              <td>{cliente.RemessasG + cliente.RemessasP}</td>
              <td><button onClick={(event) => gerarCsv(event, cliente)}> Exportar </button></td>
            </tr>
          ))}
          <tr>
            <td>Total: </td>
            <td>{TotalPs}</td>
            <td>{TotalGs}</td>
            <td>{TotalGeral}</td>
          </tr>
        </tbody>
      </table>




      {DataTable &&
      <> 
      <table className="tableProduction inativo" id="tableProductionCliente">
        <thead>
          <tr>
            <th> Remessa P</th>
            <th> Entrada </th>
            <th> Finalizacao </th>
            <th> Login</th>
            <th> </th>
            <th> Remessa G</th>
            <th> Entrada </th>
            <th> Finalizacao </th>
            <th> Login</th>
          </tr>
        </thead>
        
        <tbody>
          {DataTable.RemessasP.map((cliente, index) => {
            return (
            <tr key={cliente.rm}>
              <td>{cliente.rm}</td>
              <td>{cliente.created_at_json.dia}/{cliente.created_at_json.mes}/{cliente.created_at_json.ano}</td>
              <td>{cliente.finished_at_json.dia}/{cliente.finished_at_json.mes}/{cliente.finished_at_json.ano}</td>
              {cliente.login ? <td>{cliente.login[0].nome}</td> : <td> </td>}
              <td> </td>
              {DataTable.RemessasG[index] && <td>{DataTable.RemessasG[index].rm}</td>}
              {DataTable.RemessasG[index] && <td>{DataTable.RemessasG[index].created_at_json.dia}/{DataTable.RemessasG[index].created_at_json.mes}/{DataTable.RemessasG[index].created_at_json.ano}</td>}
              {DataTable.RemessasG[index] && <td>{DataTable.RemessasG[index].finished_at_json.dia}/{DataTable.RemessasG[index].finished_at_json.mes}/{DataTable.RemessasG[index].finished_at_json.ano}</td>}
              {DataTable.RemessasG[index] && DataTable.RemessasG[index].login && <td>{DataTable.RemessasG[index].login[0].nome}</td>}
            </tr>
          )})}
          <tr>
            <td>Total: </td>
            <td>Pequenas: {DataTable.RemessasP.length}</td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td>Grandes : {DataTable.RemessasG.length}</td>
          </tr>
        </tbody>
      </table>
      </>
      }
      {LoadingTime && <Loading />}
    </>
  )
}

export default Table