import React, { useState, useEffect} from 'react'

import { useHistory} from 'react-router-dom'
import api from '../../services/api'

import BuscaToken from '../Components/Functions/BuscaToken.js'
import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'

const ShowCourier = () => {
  const [Courier, setCourier] = useState()
  const history = useHistory()


  useEffect(() => {
    const params = window.location.href.split('Courier/Show/')
    api.get(`/courier/${params[1]}`, BuscaToken())
    .then( r => {
      if(r.data.message){
        alert(JSON.stringify(r.data.message))
        return history.push('/')
      }
      setCourier(r.data)
    })
  }, [])

  return(
    <>
    <Menu />
    {Courier && 
      <div className="container grid">
        <h2>Courier: {Courier.name}</h2>
        <span className="span-M">Status: <span style={{color: 'crimson'}}>{Courier.status ? 'Ativo' : 'Inativo'}</span></span>
        <div className="containerShowCourier contentCenter grid" >    
          
          <section className="CourierDadosPessoais grid BorderBox">
            <h3>Dados pessoais</h3>
            <span>Nome completo: <span>{Courier.name}</span></span>
            <span>Data de nascimento: <span>{Courier.nascimento ? Courier.nascimento : 'Nao inserido'}</span></span>
            <span>CPF: <span>{Courier.documento}</span></span>
            <span>RG: <span>{Courier.rg ? Courier.rg : 'Nao inserido'}</span></span>
            <span>Telefone: <span>{Courier.telefone}</span></span>
          </section>
          {Courier.endereco && 
            <section className="CourierEndereco grid BorderBox">
              <h3>Endereço</h3>
              <span>Rua: <span>{Courier.endereco.rua}</span></span>
              <span>Numero: <span>{Courier.endereco.numero}</span></span>
              {Courier.endereco.complemento ? <span>Complemento: <span>{Courier.endereco.complemento}</span></span> : ''}
              <span>Bairro: <span>{Courier.endereco.bairro}</span></span>
              <span>Cidade: <span>{Courier.endereco.cidade}</span></span>
            </section>
          }
          {Courier.financeiro && 
            <section className="CourierEndereco grid BorderBox">
              <h3>Dados Financeiros</h3>
              <span>Banco: <span>{Courier.financeiro.banco}</span></span>
              <span>Agencia: <span>{Courier.financeiro.agencia}</span></span>
              <span>Conta: <span>{Courier.financeiro.conta}</span></span>
              <span>Tipo: <span>{Courier.financeiro.tipo}</span></span>
              <span>PIX: <span>{Courier.financeiro.pix}</span></span>
              <span>Destinatario: <span>{Courier.financeiro.destinatario}</span></span>
              {Courier.financeiro.obs ? <span>Observacao: <span>{Courier.financeiro.obs}</span></span> : ''}
            </section>
          }
          {Courier.modelo &&
            <section className="CourierVeiculo grid BorderBox">
              <h3>Dados do Veiculo</h3>
              <span>Placa: <span>{Courier.placa}</span></span>
              <span>Marca: <span>{Courier.marca}</span></span>
              <span>Modelo: <span>{Courier.modelo}</span></span>
              <span>Cor: <span>{Courier.cor}</span></span>
              <span>Renavam: <span>{Courier.renavam}</span></span>
            </section>
          }
          {Courier.taxas &&
            <section className="CourierTaxas grid BorderBox">
              <h3>Taxas Adicionais</h3>
              {Courier.taxas.map( item =>
                <>
                  <span>Cliente: <span>{item.cliente}</span></span>
                  <span>Pequenas: <span>+ R${(+item.taxas.P).toFixed(2).replace('.',',')}</span></span>
                  <span>Grandes: <span>+ R${(+item.taxas.G).toFixed(2).replace('.',',')}</span></span>
                  <br/>
                </>
                )}
                
            </section>
          }
        </div>  
      </div>
    }
    <Footer />
    </>
  )
}

export default ShowCourier