import { Route, BrowserRouter, Switch} from 'react-router-dom'
import React from 'react'


import Home from '../../Home'
import LastMile from '../../LastMile'
import TRIP from '../../Trip'
import TripsIntegracao from '../../TripsIntegracao'
import Production from '../../Production'
import EditTrip from '../../EditTrip'
import SearchRemessa from '../../SearchRemessa'
import SearchRemessasLote from '../../SearchRemessa/SearchLote.jsx'

import CreateCourier from '../../CreateCourier'
import ShowCourier from '../../Courier/ShowCourier.jsx'
import EditCourier from '../../Courier/EditCourier.jsx'
import IndexCourier from '../../Courier'

import ChangePass from '../../ChangePass'
import Comprov from '../../RecebimentoCarga/Comprov.jsx'
import RecebimentoCarga from '../../RecebimentoCarga'
import Devolucao from '../../Devolucao'
import Via2 from '../../Devolucao/Via2'
import EntradaRequisicao from '../../Requisicao/entrada.jsx'
import RequisicaoIndex from '../../Requisicao'
import RequisicaoEmissao from '../../Requisicao/emissao.jsx'
import RequisicaoEmAberto from '../../Requisicao/EmAberto.jsx'
import MinhasContas from '../../MinhasContas'
import PainelRemesass from '../../PainelRemessas' 
import Pendencia from '../../Pendencia'
import Pendenciar from '../../Pendencia/pendenciar'
import Liberacao from '../../Pendencia/liberacao' 

import DeleteRemessa from '../../DeleteRemessa'

import ClientesLogins from '../../ClientesLogins'
import CreateClientesLogins from '../../ClientesLogins/create' 


import InsercaoDadosRecebedor from '../../InsercaoRecebedor/Insercao.jsx'

import AddAlterTracking from '../../InserirAlterTracking'

import Avarias from '../../Avarias'
import AvariasVia2 from '../../Avarias/Via2.jsx'

import FindPackage from '../../Public/FindPackage.jsx'

import IndexBaixaCliente from '../../BaixaCliente'

import IndexOverflows from '../../CargoOverflows'

import SearchByName from '../../SearchRemessa/SearchByName.jsx'

import Syncs from '../../Syncs'
import ShowSync from '../../Syncs/show.jsx'

import PrazosCheck from '../../PainelRemessas/PrazosCheck'

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={Home} path='/home' exact/>
        <Route component={Home} path='/' exact/>
        <Route component={LastMile} path='/lastmile' exact/>
        <Route component={TRIP} path='/trip/' />
        <Route component={Production} path='/Producao' exact />
        <Route component={EditTrip} path='/EditTrip' />
        <Route component={SearchRemessa} path='/search/remessa/' />
        <Route component={SearchRemessasLote} path='/search/lote' />
        <Route component={DeleteRemessa} path='/DeleteRemessa' />
        <Route component={TripsIntegracao} path='/tripsIntegracao' exact />
        <Route component={CreateCourier} path='/CreateCourier' exact/>
        <Route component={ShowCourier} path='/Courier/Show' />
        <Route component={IndexCourier} path='/Courier' exact/>
        <Route component={EditCourier} path='/Courier/Edit/'/>
        <Route component={ChangePass} path='/ChangePass' exact/>
        <Route component={RecebimentoCarga} path='/RecebimentoCarga' exact/>
        <Route component={Comprov} path='/RecebimentoCarga/Comprov' exact/>
        <Route component={Devolucao} path='/Devolucao' exact/>
        <Route component={Via2} path='/Devolucao/Via2' />
        <Route component={EntradaRequisicao} path='/Requisicao/entrada' exact />
        <Route component={RequisicaoIndex} path='/Requisicao' exact/>
        <Route component={RequisicaoEmissao} path='/Requisicao/emissao' exact/>
        <Route component={RequisicaoEmAberto} path='/Requisicao/EmAberto' exact/>
        <Route component={MinhasContas} path='/MinhasContas' exact/>
        <Route component={PainelRemesass} path="/PainelRemessas" exact/>
        <Route component={Pendencia} path="/Pendencia" exact/>        
        <Route component={Liberacao} path="/Pendencia/LiberarRemessas" exact/>        
        <Route component={Pendenciar} path="/Pendencia/PendenciarRemessas" exact/>    
        <Route component={ClientesLogins} path="/ClientesLogins" exact/>        
        <Route component={CreateClientesLogins} path="/ClientesLogins/create" exact/>
       
        <Route component={SearchByName} path="/SearchByName" exact/>

        <Route component={InsercaoDadosRecebedor} path="/InsercaoRecebedor/Insercao" exact/>  
        <Route component={AddAlterTracking} path="/addAlterTracking" exact/> 
        <Route component={Avarias} path="/Avarias" exact/> 
        <Route component={AvariasVia2} path="/Avarias/Via2" /> 
        <Route component={FindPackage} path='/FindPackage' exact/>
        <Route component={IndexBaixaCliente} path='/BaixaCliente' exact/>
        <Route component={IndexOverflows} path='/CargoOverFlows' exact/>

        <Route component={Syncs} path='/syncs' exact/>
        <Route component={ShowSync} path='/syncs/show/'/>

        <Route component={PrazosCheck} path='/ChecandoPrazos' exact/>

      </Switch>
    </BrowserRouter>
  )
}

export default Routes

