import React, { useState, useEffect} from 'react'

import { AiFillCopy } from 'react-icons/ai'

const ModalError = ({message, setShowModalError}) => {
  const [Mostrar, setMostrar] = useState(1)

  function fecharModal (event) {
    setMostrar(0)
    setShowModalError()
  }

  function CopyToClipboard() {
    let txt = ''
    message.map( item => txt += `${item.rm}\n`)
    navigator.clipboard.writeText(txt)
  }

  return (
    <div className={`modal ${Mostrar && 'mostrar'}`}>
      <div className="modal-interno">
        <button onClick={fecharModal} className="btn-M btn-closeModal">Fechar</button>
        <div style={{alignItems: 'top'}}>
          <section className="section-modal BorderBox" style={{alignContent: 'baseline'}}>
            <p style={{marginBottom: '30px', height: '30px'}}> {message.length} remessas nao autorizadas. </p> 
            <div id="remessas" className="grid">
            {message && message.map( item => 
                 <p id={item.rm}>{item.rm} : {item.status} <br/></p>
                  )}
            <button className="btn-Copy grid" onClick={CopyToClipboard}><AiFillCopy/></button>       
            </div>
          </section>
          
        </div>
      </div>
    </div>
  )
}

export default ModalError