import React from 'react'
import { Link } from 'react-router-dom'


import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'


const PainelAdmin = () => {
  

  return (
    <>
      <Menu />
      <div className="container grid" >
        <div className="menuPainelAdmin">
          <ul>
            <li><Link to="/CreateClient">Cadastrar Cliente </Link></li>
            <li><Link to="/CreateCourier">Cadastrar Courier </Link></li>
            <li><Link to="/CreateUser">Cadastrar User </Link></li>
            <li><Link to="/PainelAdmin/ChangePassAll">Mudar Senha de Usuarios </Link></li>
            <li><Link to="/PainelAdmin/ProducaoClientes">Producao por Clientes </Link></li>
            <li><Link to="/PainelAdmin/EditUsers">Alterar Usuarios</Link></li>
            <li><Link to="/PainelAdmin/EditCouriers">Alterar Couriers</Link></li>
            <li><Link to="/PainelAdmin/EditCouriers/taxas">Alterar Taxas Courier</Link></li>
            <li><Link to="/PainelAdmin/EditTrips">Alterar Trips</Link></li>
            <li><Link to="/PainelAdmin/UpdateClientes">Update Clientes</Link></li>
            <li><Link to="/Pendencia">Pendencias</Link></li>
            <li><Link to="/ControleEntradas">Controle Entradas</Link></li>
          </ul>


          </div>
      </div>
        <Footer />
    </>
  )
}

export default PainelAdmin