import React, { useState} from 'react'
import EditTrip from './EditTrip.jsx'

import Menu from '../../Components/Menu/Menu.jsx'
import Footer from '../../Components/Footer'

const EditTrips = () => {
  const [BuscaTrip, setBuscaTrip] = useState()


  function buscandoTrip (event){
    if(event.key === 'Enter'){
      setBuscaTrip()
    event.preventDefault()
    const Trip = event.target.value
    setBuscaTrip(Trip)
    return 
    }
  }


  return (
    <>
      <Menu />
      <div className="container contentCenter grid">
        <h3> Painel Admin - Editar Trips</h3>
        {!BuscaTrip && <form >
          <label htmlFor="trip">Entre com o numero da Trip a baixo</label>
          <input type="text" id="trip" onKeyPress={buscandoTrip}/>
        </form>}
        {BuscaTrip && <EditTrip trip={BuscaTrip}/>}
      </div>
      <Footer />
    </>
  )
}

export default EditTrips