import React from 'react'

import RmtoArray from '../Functions/RmtoArray.js'

const Producao = ({Trip})  => {
  if(Trip.remessas_retornadas || Trip.remessas_finalizadas){


  const retornadas = RmtoArray(Trip.remessas_retornadas).length
  const entregues = RmtoArray(Trip.remessas_finalizadas).length

  return (
      <div>
          <p className='producao_entregues'>Entregues: {entregues}</p>
          <p className='producao_retornos'>Retornadas: {retornadas} </p>
      </div>
    )
  }
  else {
    return (
      ''
    )
  }
 }

 export default Producao