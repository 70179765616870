import React, { useState } from 'react'
import api from '../../services/api'
import ReCAPTCHA from 'react-google-recaptcha'
import BuscaToken from '../Components/Functions/BuscaToken.js'

import Footer from '../Components/Footer'
import topLogo from '../Components/Menu/img/topLogo.png'
import Loading from '../Components/Loading'

const LogIn = () => {
  const [LoadingTime, setLoadingTime] = useState()

  const config = BuscaToken()

  function formEnter (event) {
    if(event.key === 'Enter'){
      event.preventDefault()
      Logando()
    }  
  }
  

  async function Logando (event) {
      if(event)
        event.preventDefault()
    
      const login = document.getElementById('login').value
      const pass = document.getElementById('password').value
      if(login.length < 4 || pass.length < 4){
        document.getElementById('login').value = ''
        document.getElementById('password').value = ''
        document.getElementById('login').focus()
        return alert('Dados para login invalidos!')
      }

      const data = {
        login, 
        pass
      }

      setLoadingTime(1)
      const logado = await api.post('/users/login', data, config)
      setLoadingTime()
      if(logado.data.token){
        localStorage.setItem('token', logado.data.token)
        window.location.reload()
      }
      else {
        document.getElementById('login').value = ''
        document.getElementById('password').value = ''
        document.getElementById('login').focus()
        alert(logado.data.message)
      }
  }


  return (
    <>
      <div className="headerLogIn grid BorderBox">
        <img className="topLogoLogIn ativo" src={topLogo} alt="LogoExpressas"/>
        <span className="grid">Sistema de Gerenciamento Operacional <br/>Expressas Dourados </span>
      </div>  
        <form className="formLogIn grid" onKeyPress={formEnter} autoComplete="off">
          <label htmlFor="login">Login</label>
          <input type="text" name="login" id="login" autoComplete="off"/>
          <label htmlFor="password">Password</label>
          <input type="password" name="password" id="password" autoComplete="off"/>
          <button className="btn-G" onClick={(event) => Logando(event)}>Logar</button>
        </form>
      {LoadingTime && <Loading />}
      <Footer footerLogin="footerLogIn"/>
    </>
  )

}

export default LogIn