import React, { useEffect, useState} from 'react'

import api from '../../services/api'

import Menu from '../Components/Menu/Menu'
import Footer from '../Components/Footer'
import BuscaToken from '../Components/Functions/BuscaToken.js'


const MinhasContas = () => {
  const [User, setUser] = useState()
  const [Contas, setContas] = useState()
  const [ValorTotal, setValorTotal] = useState()
  const config = BuscaToken()


  const months = ["janeiro", "fevereiro", "março", "abril", "maio", "junho","julho", "agosto", "setembro", "outubro", "novembro", "dezembro"]
  const Quinzenas = [{Quinzena: '1°', values: {i: '01', f: '15'}},{Quinzena: '2°', values: {i: '16', f: '31'}}]

  useEffect(() => {
    api.get('/users/show', config)
    .then( r => {
      if(!r.data.message){
        setUser(r.data)
      }
    })
  }, [])

  useEffect(() => {
    if(User){
      api.get('/HoraServer/timestamp', config).then( r => {
        const data = r.data.split('')
        const DataAtual = {
          ano: `${data[0]}${data[1]}${data[2]}${data[3]}`,
          mes: `${data[4]}${data[5]}`,
          dia: `${data[6]}${data[7]}`
        }
        let Quinzena = null
        if(Number(DataAtual.dia) <= 15)
          Quinzena = (Quinzenas[0].values)
        else if(Number(DataAtual.dia) > 15)
          Quinzena = (Quinzenas[1].values)         
        const periodo = {
          year: Number(DataAtual.ano),
          month: DataAtual.mes,
          Quinzena
        }
        const dataPost = {
          periodo,
          user: User.id
        }

         api.post('/contas/extratoPerUser', dataPost, config).then( r => {
           setContas(r.data.ContasGeral)
           setValorTotal((r.data.valorTotal).toFixed(2).replace('.', ',')) 
         })
      })
    } 
  }, [User])


  return (
    <>
      <Menu />
      <div className="container grid">
          <h2>Minhas Contas</h2>
          <table className="boxProduction tableMinhasContas">
            <thead>
              <tr>
                <th>Tipo</th>
                <th>Data</th>
                <th>Descricao</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              {Contas && Contas.map((item) => {
                const discount = JSON.parse(item.discount_at_json)
                const value = `R$ ${item.valor}`.replace('.', ',')


                return (
                  <tr key={Math.random(10)}>
                    <td>{item.tipo}</td>
                    <td>{discount.dia}/{(months.indexOf(discount.mes)+1) > 9 ? (months.indexOf(discount.mes)+1) : `0${(months.indexOf(discount.mes)+1)}`}/{discount.ano}</td>
                    <td>{item.descricao}</td>
                    <td>{value}</td>
                  </tr>
                )  
              })}
            </tbody>
            <tfoot style={{fontWeight: 'bold'}}>
              <tr>
                <td></td>
                <td></td>
                <td>Valor Total:</td>
                <td>R$ {ValorTotal}</td>
              </tr>
            </tfoot>
          </table>
      </div>
      <Footer />
    </>
  )
}


export default MinhasContas